import { Grid } from "@mui/material";
import Charts from "../../../../common/ECharts/Charts";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomSpinner from "../../../../common/CustomSpinner";
import { useEffect, useMemo, useState } from "react";
import ButtonGroup from "../../../../common/ButtonGroup";
import { getRegistrationsGraph } from "../../../../scripts/apis/events";
import _ from "lodash";
import { EventRegistrationRange } from "../../enum";

const RegistrationsGraph: React.FC<{eventId: string | number}> = ({ eventId }): React.JSX.Element =>
{
    const [selectedFilter, setSelectedFilter] = useState<'all'|'one-month'|'one-week'|'one-day'|''>('one-day');
    const [dateRange, setDateRange] = useState<EventRegistrationRange>(EventRegistrationRange.TWENTY_FOUR_HOURS);
    const [graphReady, setGraphReady] = useState<boolean>(false);
    const [registrationGraphData, setRegistrationGraphData] = useState<any>(undefined);
    const [registrationCount, setRegistrationCount] = useState<number>(0);

    const generateXAxisData = () =>
    {
        if (!registrationGraphData || registrationGraphData[0]?.length === 0) {
            return [];
        }

        const formatXAxisDate = (date: string): string => {
            if (dateRange === EventRegistrationRange.ALL) 
            {
                if (date?.includes('W'))
                {
                    return moment(date, "YYYY-[W]WW").format("W");
                }
                else
                {
                    return moment(date, "YYYY-MM").format("MMMM");
                }
            } 
            else if (dateRange !== EventRegistrationRange.TWENTY_FOUR_HOURS) 
            {
                return moment(date, "DD-MM-YYYY").format("DD MMM");
            } 
            else 
            {
                const timezoneOffset = moment().utcOffset() / 60;
                return moment.utc(date, "DD-MM-YYYY HH:mm").add(timezoneOffset, 'hours').format("MMM DD HH:mm");
            }
        };

        return registrationGraphData[0]?.map(formatXAxisDate);
    };
    
    const registrationsGraphOption = {
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: generateXAxisData(),
        },
        yAxis: {
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
        },
        series: [
            {
                data: registrationGraphData && registrationGraphData[1]?.length > 0 && (
                    dateRange === EventRegistrationRange.TWENTY_FOUR_HOURS ? 
                    registrationGraphData[1].slice().reverse() : 
                    registrationGraphData[1]
                ),
                type: 'line',
                smooth: true,
                itemStyle: {
                    color: '#1570EF'
                },
            }
        ]
    };
    
    const registrantDetails = [
        {
            name: 'Registrations',
            value: registrationCount > 0 ? registrationCount : '-'
        },
        // {
        //     name: 'In Person',
        //     value: '-'
        // },
        // {
        //     name: 'Online',
        //     value: '-'
        // }
    ];

    const registrationFilters = [
        {
            tabName: '30 days',
            selectedTab: selectedFilter === 'one-month',
            navigation: (): void => 
            {
                setDateRange(EventRegistrationRange.THIRTY_DAYS);
                setSelectedFilter('one-month');
            }
        },
        {
            tabName: '7 days',
            selectedTab: selectedFilter === 'one-week',
            navigation: (): void => 
            {
                setDateRange(EventRegistrationRange.SEVEN_DAYS);
                setSelectedFilter('one-week');
            }
        },
        {
            tabName: '24 hours',
            selectedTab: selectedFilter === 'one-day',
            navigation: (): void => 
            {
                setDateRange(EventRegistrationRange.TWENTY_FOUR_HOURS);
                setSelectedFilter('one-day');
            }
        },
        {
            tabName: 'All',
            selectedTab: selectedFilter === 'all',
            navigation: (): void => 
            {
                setDateRange(EventRegistrationRange.ALL);
                setSelectedFilter('all');
            }
        }
    ]

    const fetchRegistrationGraphData = async (): Promise<void> =>
    {
        // const currentTimezone = moment?.tz?.guess();

        try 
        {
            const registrations = await getRegistrationsGraph(eventId, dateRange);
            
            if(registrations)
            {
                const registrationSum = _.sum(_.values(registrations));
                setRegistrationCount(registrationSum);

                const xAxisValues = Object.keys(registrations);
                const yAxisValues = Object.values(registrations);

                setRegistrationGraphData([xAxisValues, yAxisValues]);
            }
        } 
        catch (error) 
        {
            
        }
        finally
        {
            setGraphReady(true);
        }
    };

    useEffect(() => {
        fetchRegistrationGraphData();
    }, []);

    useMemo(() => {
        fetchRegistrationGraphData();
    }, [dateRange]);

    return (
        <div className="registrations-graph">
            <div className="header-container">
                <h3 className="header">{'Registration movements'}</h3>
                <div className="registrations-graph-filter">
                    <ButtonGroup tabs={registrationFilters} />
                </div>
            </div>

            <Grid container spacing={2}>
                <Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
                    <div className="chart-div">
                        {graphReady ? 
                            (registrationGraphData && registrationGraphData[1]?.length > 0 && registrationGraphData[1]?.some((item: number) => item !== 0)) ? 
                            <Charts chartOption={registrationsGraphOption} height="240px" /> : 
                            <EmptyChartDiv height="100%" /> : 
                            <CustomSpinner height={'100%'} />
                        }
                    </div>
                </Grid>

                <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
                    <div className="registration-details">
                        {
                            registrantDetails?.map((item, index) => {
                                return (
                                    <div key={index} className="details-div">
                                        <h3 className="name">{item.name}</h3>
                                        <p className="value">{item.value}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
    )
};

export default RegistrationsGraph;

const EmptyChartDiv: React.FC<{height?: string}> = ({ height }): React.JSX.Element =>
{
    return (
        <div className="empty-chart-div" style={{ height: height || '100%' }}>
            <FontAwesomeIcon icon={['fal', 'chart-line']} />
            <p>{'No data available'}</p>
        </div>
    )
}