import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { getAllBudgetData, getAllBudgetDataCount, getBudgetGraphData } from '../../scripts/apis/budget';
import { getEventsCategoriesCount } from '../../scripts/apis/events';
// import { NumberFormatter } from '../../scripts/helpers';
import BudgetsCharts from './BudgetsCharts';
import BudgetAnalysisTable from './BudgetAnalysisTable';
// import TrendsComponent from '../../common/TrendsComponent';
import { useTablePagination } from '../../contexts/TablePaginationContext';
import CreateGlobalBudget from './CreateGlobalBudget';
import _ from 'lodash';
import { CustomButton } from '../../common/FormComponents/Buttons';
import HeaderBar from '../../common/Headerbar';
import toast from 'react-hot-toast';
import { UserContext } from '../../contexts/UserContext';
import { UserViewType } from '../Settings/enum/users.enum';
import CustomSpinner from '../../common/CustomSpinner';
import { getEncodedParams } from '../../scripts/helpers';

import './styles.scss';
import EventsHeaderBar from '../../common/EventsHeaderBar';

export enum BudgetGraphTypes {
    BUDGET_SUMMARY = 1,
    BUDGET_EXPENDITURE = 2,
    BUDGET_VS_ACTUAL = 3,
}

const BudgetsPage = (): React.JSX.Element => 
{

    const {
        userDetails
    } = useContext(UserContext);

    const [budgetTotalCounts, setBudgetTotalCounts] = useState(0);
    const [budgetCounts, setBudgetCounts] = useState({
        event: 0,
        budget: 0,
        remaining: 0,
        actualSpent: 0,
    });
    const [countryExpenditure, setCountryExpenditure] = useState([]);
    const [actualSpendPerEvent, setActualSpendPerEvent] = useState([]);
    const [budgetRows, setBudgetRows] = useState([]);
    const [chartReady, setChartReady] = useState({
        budgetSummary: false,
        budgetExpenditure: false,
        budgetVsActual: false,
        isBudgetsTableReady: false
    });
    const [showSpinner, setShowSpinner] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [chartVisibility, setChartVisibility] = useState(false);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const fetchEventsCategoriesCount = async (): Promise<void> => 
    {
        setShowSpinner(true);
        try 
        {
            const events = await getEventsCategoriesCount(1);
            if (events) 
            {
                setBudgetCounts((prevCounts): {
                    event: number;
                    budget: number;
                    remaining: number;
                    actualSpent: number;
                } => 
                {
                    return {
                        ...prevCounts,
                        event: events,
                    };
                });
            }

        }
        catch (error) 
        {
            console.log(error);
            toast.error(_.startCase((error as Error).message.toLowerCase()));
        }
        finally
        {
            setShowSpinner(false);
        }

    };

    const fetchBudgetGraphData = async (): Promise<void> => 
    {
        try 
        {

            const budgetGraphData = await getBudgetGraphData();
            if (budgetGraphData) 
            {
                if (budgetGraphData.trend) 
                {
                    setBudgetCounts((prevItems): {
                        budget: number;
                        actualSpent: number;
                        remaining: number;
                        event: number;
                    } => 
                    {
                        return {
                            ...prevItems,
                            budget: Number(budgetGraphData.trend.spent + budgetGraphData.trend.remaining),
                            actualSpent: Number(budgetGraphData.trend.spent),
                            remaining: Number(budgetGraphData.trend.remaining),
                        };
                    });

                }
                if (budgetGraphData.expenditure) 
                {
                    setCountryExpenditure(budgetGraphData.expenditure);
                }

                if (budgetGraphData.budgetVsActual) 
                {
                    setActualSpendPerEvent(budgetGraphData.budgetVsActual);
                }

                setChartReady((prevItems): {
                    budgetSummary: boolean;
                    budgetExpenditure: boolean;
                    budgetVsActual: boolean;
                    isBudgetsTableReady: boolean;
                } => 
                {
                    return {
                        ...prevItems,
                        budgetSummary: true,
                        budgetExpenditure: true,
                        budgetVsActual: true
                    };
                });

            }

        }
        catch (error) 
        {
            console.log(error);
            toast.error(_.startCase((error as Error).message.toLowerCase()));
            setChartReady((prevItems): {
                budgetSummary: boolean;
                budgetExpenditure: boolean;
                budgetVsActual: boolean;
                isBudgetsTableReady: boolean;
            } => 
            {
                return {
                    ...prevItems,
                    budgetSummary: true,
                    budgetExpenditure: true,
                    budgetVsActual: true
                };
            });
        }

    };

    const fetchBudgetRowDataTotalCunt = async (): Promise<void> => 
    {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const count = await getAllBudgetDataCount(encodedParams);
            if (count) 
            {
                setBudgetTotalCounts(count);
                setIsEmpty(count == 0 && encodedParams.toString().length === 0);
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error(_.startCase((error as Error).message.toLowerCase()));
        }
    };

    const fetchBudgetRowData = async (pageSize: number, currentPage: number): Promise<void> => 
    {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const BudgetRows = await getAllBudgetData(pageSize, currentPage, encodedParams);
            if (BudgetRows) 
            {
                setChartReady((prevItems): {
                    budgetSummary: boolean;
                    budgetExpenditure: boolean;
                    budgetVsActual: boolean;
                    isBudgetsTableReady: boolean;
                } => 
                {
                    return {
                        ...prevItems,
                        isBudgetsTableReady: true
                    };
                });
                setBudgetRows(BudgetRows);
                setShowSpinner(false);
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error(_.startCase((error as Error).message.toLowerCase()));
            setChartReady((prevItems): {
                budgetSummary: boolean;
                budgetExpenditure: boolean;
                budgetVsActual: boolean;
                isBudgetsTableReady: boolean;
            } => 
            {
                return {
                    ...prevItems,
                    isBudgetsTableReady: true
                };
            });
        }
    };

    const openDrawer = (props: {
        createComp: boolean;
        updateComp?: boolean;
    }): void => 
    {
        if (props.createComp) 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
                heading: 'Add Budget',
                component: (
                    <CreateGlobalBudget />
                ),
            });
        }
        if (props.updateComp) 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
                heading: 'Add Budget',
                component: (
                    <CreateGlobalBudget />
                ),
            });
        }
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    useEffect((): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.UPDATE_NAME_EVENT, {
            heading: 'Budget',
            buttonName: 'Add Budget',
            closedChartsButton: true,
            closedChartClickEventName: 'close-budget-page-charts'
        });

        eventBus.on(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.BUTTON_CLICK_EVENT, (): void => 
        {
            openDrawer({
                createComp: true
            });
        });

        eventBus.on('close-budget-page-charts', (data): void => 
        {
            if (data.closeChart) 
            {
                setChartVisibility(false);
            }
            else 
            {
                setChartVisibility(true);
            }
        });

        fetchEventsCategoriesCount();
        fetchBudgetGraphData();
        fetchBudgetRowDataTotalCunt();
        // fetchBudgetRowData();
    }, []);

    useEffect((): void => 
    {
        if (currentPage > 0 && pageSize) 
        {
            fetchBudgetRowData(pageSize, currentPage - 1);
        }
    }, [pageSize, currentPage, currentUrl]);

    useEffect((): void => 
    {
        fetchBudgetRowDataTotalCunt();
    }, [currentUrl]); 

    const renderBudgetsPage = (): React.JSX.Element => { 
        return (
            <Box id="budgetsPage">
                <EventsHeaderBar 
                    title='Budget'
                    buttons={[
                        <CustomButton name={chartVisibility ? 'Close Charts' : 'Open Charts'} btnType='secondary' onClick={() => setChartVisibility(!chartVisibility)} />,
                        <CustomButton name='Add Budget' btnType='primary' onClick={() => openDrawer({
                            createComp: true
                        })} />
                    ]}
                />
                {chartVisibility && <BudgetsCharts expenditure={countryExpenditure} chartReady={chartReady.budgetSummary && chartReady.budgetExpenditure && chartReady.budgetVsActual} actualSpendPerEvent={actualSpendPerEvent} budgetCounts={budgetCounts} ></BudgetsCharts>}
                {/* <Box className="event-budget-title-container">
                    <Typography>Event Categories Budget {budgetCounts && budgetCounts.budget && "$" + NumberFormatter(budgetCounts.budget ? budgetCounts.budget : 0)}</Typography>
                </Box> */}
                <Box
                    key={chartVisibility ? 'budget-page-chart-table' : 'budget-page-table'}
                    sx={chartVisibility ? {
                        flex: 1,
                        height: '56% !important',
                    } : {
                        height: 'calc(100vh - 60px)',
                        maxHeight: 'calc(100vh - 60px)',
                    }}
                >
                    <BudgetAnalysisTable 
                        chartVisibility={chartVisibility} 
                        budgetCount={budgetTotalCounts} 
                        tableReady={chartReady.isBudgetsTableReady} 
                        rows={budgetRows} 
                        pageSize={pageSize}
                        currentPage={currentPage} 
                        updateCurrentPage={updateCurrentPage} 
                        updatePageSize={updatePageSize} 
                        showSpinner={showSpinner} 
                        isEmpty={isEmpty} 
                        totalBudgetCount={budgetTotalCounts}
                        setCurrentUrl={setCurrentUrl} 
                        setChartVisibility={setChartVisibility}
                        budgetCounts={budgetCounts}
                    />

                </Box>
            </Box>
        )
    };

    const renderMinimalViewForBudgetsPage = (): React.JSX.Element =>
    {
        return (
            <>
                {
                    showSpinner ? <CustomSpinner height="100vh" /> : <BudgetAnalysisTable 
                        chartVisibility={chartVisibility} 
                        budgetCount={budgetTotalCounts} 
                        tableReady={chartReady.isBudgetsTableReady} 
                        rows={budgetRows} 
                        pageSize={pageSize}
                        currentPage={currentPage} 
                        updateCurrentPage={updateCurrentPage} 
                        updatePageSize={updatePageSize} 
                        showSpinner={showSpinner} 
                        isEmpty={isEmpty} 
                        totalBudgetCount={budgetTotalCounts}
                        setCurrentUrl={setCurrentUrl} 
                        setChartVisibility={setChartVisibility}
                        budgetCounts={budgetCounts}
                    />
                }
            </>
        )
    };

    return (
        <>
            {
                userDetails?.viewType === UserViewType.NORMAL ? renderBudgetsPage() : renderMinimalViewForBudgetsPage()
            }
        </>
    );
};

export default BudgetsPage;



// export const BudgetOverView = (props):React.JSX.Element => 
// {
//     const budgetCounts = props?.budgetCounts;
//     return (
//         <TrendsComponent
//             Trends={
//                 [
//                     {
//                         title: 'Event Categories',
//                         value: `${budgetCounts.event ? budgetCounts.event : '-'}`
//                     },
//                     {
//                         title: 'Budget',
//                         value: `${budgetCounts.budget ? '$' + NumberFormatter(budgetCounts.budget ? budgetCounts.budget : 0) : '-'}`
//                     },
//                     {
//                         title: 'Spent',
//                         value: `${budgetCounts.actualSpent ? '$' + NumberFormatter(budgetCounts.actualSpent ? budgetCounts.actualSpent : 0) : '-'}`
//                     },
//                     {
//                         title: 'Remaining',
//                         value: `${budgetCounts.remaining ? '$' + NumberFormatter(budgetCounts.remaining ? budgetCounts.remaining : 0) : '-'}`
//                     }
//                 ]
//             }
//         />
//     );
// };