import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Audience } from '../../../../Audience/interfaces';
import { LocalStorage } from '../../../../../scripts/LocalStorage';
import { User } from '../../../../../interfaces/settings/user_interface';
import { useTablePagination } from '../../../../../contexts/TablePaginationContext';
import { generateAudienceColumnsForTanstackTable } from '../../../../Audience/audienceColumn.helper';
import APP_CONSTANTS from '../../../../../scripts/constants';
import eventBus from '../../../../../scripts/event-bus';
import ViewAudienceInfo from '../../../../Audience/ViewAudienceInfo';
import { count, getAllAudience, getAudienceById } from '../../../../../scripts/apis/audience';
import { Box } from '@mui/material';
import TanstackTable from '../../../../../common/TanstackTable/TanstackTable';
import './styles.scss';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import { addTargetList } from '../../../../../redux/events/eventTargetList/targetList';
import { useDispatch, useSelector } from 'react-redux';
import { IEventTargetListDispatch, IEventTargetListReduxData } from '../../../interfaces/event-target-list-interface';
import _ from 'lodash';
import TanstackToolbarWithServerFilter from '../../../../../common/TanstackTable/TanstackToolbarWithServerFilter';
import AudienceFilterAccordion from '../Components/ByAudience/AudienceFilterAccordion';
import { audienceFilterColumns } from '../helpers/TargetListContants';
import { EventTargetListContext } from '../../../../../contexts/EventTargetList/EventTargetListContext';
import DeletePopup from '../../../../../common/DeletePopup';
import { UserContext } from '../../../../../contexts/UserContext';
import { UserViewType } from '../../../../Settings/enum/users.enum';
import { getEncodedParams } from '../../../../../scripts/helpers';

const TargetListByAudiencePage: React.FC = (): JSX.Element => 
{
    const navigate = useNavigate();
    const [savedTab, setSavedTab] = useState<boolean>(false);

    const [rows, setRows] = useState<Audience[]>([]);
    const [audienceInfo, setAudienceInfo] = useState<Audience[]>([]);
    const [audCount, setAudCount] = useState(0);
    const [isEmpty, setIsEmpty] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [tableSearch, setTableSearch] = useState<string>('');
    const [userData, setUserData] = useState<User | undefined>(LocalStorage.get('@UserMe') || undefined);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const { audienceId } = useParams();
    const { pageSize, currentPage, updatePageSize, updateCurrentPage } = useTablePagination();
    const { eventId, listId, existingTargetList } = useContext(EventTargetListContext);

    const {
        userDetails
    } = useContext(UserContext);
    const minimalView = userDetails?.viewType === UserViewType.MINIMAL;

    let audienceColumns = [...generateAudienceColumnsForTanstackTable()];
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams();
    const currentpath = useLocation().pathname;

    // Data related to SavedAudience
    const [savedAudienceIds, setSavedAudienceIds] = useState<string[]>([]);
    const [savedRows, setSavedRows] = useState<Audience[]>([]);
    const [savedPageSize, setSavedPageSize] = useState<number>(25);
    const [savedCurrentPage, setSavedCurrentPage] = useState<number>(1);
    const [savedSelectedRowIds, setSavedSelectedRowIds] = useState<string[]>([]);

    const targetListAudiencePageToolBar = [
        {
            tabName: 'Total',
            count: audCount,
            navigation: () => {
                setSavedTab(false);
            },
            selectedTab: !savedTab,
        },
        {
            tabName: 'Saved',
            count: savedAudienceIds.length,
            navigation: () => {
                if(savedAudienceIds.length > 0)
                {
                    setSavedTab(true);
                }
            },
            selectedTab: savedTab,
        }
    ];

    const eventTargetListReduxData = useSelector((state: IEventTargetListDispatch): IEventTargetListReduxData => 
    {
        return state.eventTargetList.value;
    });
    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });

    if (userData?.role === 3)
    {
        audienceColumns = [...audienceColumns];
    }
    const [allColumns, setAllColumns] = useState(audienceColumns);

    const handleAutocompleteChange = (selectedColumn: string, values: string[]) => {
        const currentSearchParams = new URLSearchParams(currentUrl.split('?')[1]);
        if (values?.length > 0) 
        {
            const csvValue = values.join(',');
            currentSearchParams.delete(selectedColumn);
            currentSearchParams.set(selectedColumn, csvValue);
        }
        else 
        {
            currentSearchParams.delete(selectedColumn);
        }
        setCurrentUrl(`${currentpath}?${currentSearchParams.toString()}`);
        navigate(`${currentpath}?${currentSearchParams.toString()}`);
    };

    const handleAddTargetListClick = async () =>
    {
        try
        {
            setSavedAudienceIds(Array.from(new Set([...selectedRowIds, ...savedAudienceIds])));
            setSelectedRowIds([]);
        }
        catch (error) 
        {
            throw error;
        }
    };

    const handleRemoveTargetListClick = async () =>
    {
        try
        {
            const updatedSavedAudienceIds = savedAudienceIds.filter(id => !savedSelectedRowIds.includes(id));
            setSavedAudienceIds(updatedSavedAudienceIds);
            if(updatedSavedAudienceIds.length == 0)
            {
                setSavedTab(false);
            }
            setSavedSelectedRowIds([]);
            setShowDeletePopup(false);
        }
        catch (error) 
        {
            throw error;
        }
    };

    const handleCancelClick = () =>
    {
        setSelectedRowIds([]);
    };

    const handleSavedCancelClick = () =>
    {
        setSavedSelectedRowIds([]);
    };


    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const openDrawer = (data, routeFromId?: string): void => 
    {
        const clearbitFName = data?.clearbitData?.givenName;
        const clearbitLName = data?.clearbitData?.familyName;

        const name = clearbitFName && clearbitLName ? clearbitFName + ' ' + clearbitLName : data?.firstName;

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: name,
            hideCloseButton: true,
            component: <ViewAudienceInfo audienceData={ data } routeFromId={ routeFromId } name={ name } />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.REQUIRED_SHOW, {
            hideRequiredField: true,
        });
    };

    const fetchTotalAudienceCount = async (): Promise<void> => 
    {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const audienceCount = await count(encodedParams);
            if (audienceCount) 
            {
                setAudCount(audienceCount);
                setIsEmpty(audienceCount == 0 && encodedParams.toString().length === 0);
            }
        }
        catch (error) 
        {
            console.error(error);
        }
    };

    const fetchDataFromRoute = async (): Promise<void> => 
    {
        if (audienceInfo && audienceInfo?.length && audienceId) 
        {
            try 
            {
                const audienceDataFromId = await getAudienceById(Number(audienceId));
                if (audienceDataFromId) 
                {
                    openDrawer(audienceDataFromId, 'true');
                }
                else if (!audienceDataFromId) 
                {
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                        open: true,
                        message: 'The audience you\'re trying to access doesn\'t exist',
                        severity: 'error',
                        positionVertical: 'top',
                        positionHorizontal: 'center',
                    });
                    navigate('/audiences');
                }
            }
            catch (error) 
            {
                console.error(error);
            }
        }
    };

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            setShowSpinner(true);
            if (audCount) 
            {
                try 
                {
                    const encodedParams = getEncodedParams(currentUrl);
                    const audienceData = await getAllAudience(pageSize, currentPage - 1, encodedParams);

                    if (audienceData) 
                    {
                        setAudienceInfo([...audienceData]);
                        setRows([...audienceData]);
                        setShowSpinner(false);
                    }
                    
                }
                catch (error) 
                {
                    console.error(error);
                }
            }
        }
        catch (error) 
        {
            console.error(error);
        }

    };

    const fetchSavedData = async (): Promise<void> => 
    {
        try 
        {
            setShowSpinner(true);

            const encodedParams = getEncodedParams(currentUrl);
            const audienceIdsStr = savedAudienceIds.join(',');
            encodedParams.push(['audienceIdStr', audienceIdsStr]);
            const savedData = await getAllAudience(savedPageSize, savedCurrentPage - 1, encodedParams);
            if (savedData) 
            {
                setSavedRows(savedData);
                setShowSpinner(false);
            }
        }
        catch (error) 
        {
            console.error(error);
        }
    };

    useEffect((): void => 
    {
        fetchTotalAudienceCount();
    }, [currentUrl]);

    useEffect((): void => 
    {
        fetchDataFromRoute();
    }, [audienceInfo]);

    useEffect((): void => 
    {
        if(!savedTab)
        {
            fetchData();
        }
    }, [pageSize, currentPage, audCount, currentUrl]);

    useMemo(() =>
    {
        if (refresh)
        {
            fetchData();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        if (savedTab && savedAudienceIds.length > 0) {
            fetchSavedData();
        }
        dispatch(addTargetList({
            audienceIds: savedAudienceIds
        }));
    }, [savedTab, savedPageSize, savedCurrentPage, currentUrl, savedAudienceIds]);

    useEffect(() => {
        if(listId && existingTargetList?.audienceIds) 
        {
            setSavedAudienceIds(Array.from(new Set([...existingTargetList?.audienceIds, ...savedAudienceIds])));
        }
    }, [listId])

    const eventTargetListByAudiencePageView = () => 
    {
        return (
            <Box id="targetListAudiencePage" style={{ width: "98%%" }}>
                <Box
                    key={"audience-page-table"}
                    sx={{
                        height: "calc(100vh - 60px)",
                        maxHeight: "calc(100vh - 60px)",
                    }}
                >
                    {selectedRowIds.length > 0 ? (
                        <TanstackToolbarWithServerFilter
                            columns={audienceColumns}
                            setColumns={setAllColumns}
                            setCurrentUrl={setCurrentUrl}
                            tabs={targetListAudiencePageToolBar}
                            handleApplyFilters={(filters) => {
                                handleApplyFilters(filters);
                            }}
                            filterColumns={audienceFilterColumns}
                            leftActionButtons={[<CustomButton
                                btnType="primary"
                                name="Add To List"
                                onClick={handleAddTargetListClick}
                            />,
                            <CustomButton
                                btnType="secondary"
                                name="Cancel"
                                onClick={handleCancelClick}
                            />,
                            <div className="table-header-text">
                                {" "}
                                {selectedRowIds.length} records selected{" "}
                            </div>]}
                        />
                    ) : savedSelectedRowIds.length > 0 ? (
                            <TanstackToolbarWithServerFilter
                                columns={audienceColumns}
                                setColumns={setAllColumns}
                                setCurrentUrl={setCurrentUrl}
                                tabs={targetListAudiencePageToolBar}
                                handleApplyFilters={(filters) => {
                                    handleApplyFilters(filters);
                                }}
                                filterColumns={audienceFilterColumns}
                                leftActionButtons={[ <CustomButton
                                    btnType="secondary"
                                    name="Cancel"
                                    onClick={handleSavedCancelClick}
                                />,
                                <CustomButton
                                    btnType="danger"
                                    name="Delete from list"
                                    onClick={() => setShowDeletePopup(true)}
                                />,
                                <div className="table-header-text">
                                    {" "}
                                    {savedSelectedRowIds.length} records selected{" "}
                                </div>]}
                            />
                    ) : (
                        <TanstackToolbarWithServerFilter
                            columns={audienceColumns}
                            setColumns={setAllColumns}
                            setCurrentUrl={setCurrentUrl}
                            tabs={targetListAudiencePageToolBar}
                            handleApplyFilters={(filters) => {
                                handleApplyFilters(filters);
                            }}
                            filterColumns={audienceFilterColumns}
                        />
                    )}
    
                    <div className="table-with-side-bar-filter">
                        <AudienceFilterAccordion onAutocompleteChange={handleAutocompleteChange} />
                        {!savedTab ? (
                            <div style={{ width: "calc(100vw - 296px)" }}>
                                <TanstackTable
                                    checkboxSelection={true}
                                    data={rows}
                                    initialColumns={allColumns}
                                    rowCount={audCount}
                                    showNoRowsOverlay={audCount==0}
                                    pageSize={pageSize}
                                    currentPage={currentPage - 1}
                                    updateCurrentPage={updateCurrentPage as any}
                                    updatePageSize={updatePageSize}
                                    showSpinner={showSpinner}
                                    hidePagination={tableSearch?.length >= 2}
                                    // height={`calc(100% - 57px)`}
                                    rightPinnedColumns={["actions"]}
                                    onRowClick={(row) => openDrawer(row)}
                                    selectedRows={selectedRowIds}
                                    setSelectedRows={setSelectedRowIds}
                                />
                            </div>
                        ) : (
                            <div style={{ width: "calc(100vw - 296px)" }}>
                                <TanstackTable
                                    checkboxSelection={true}
                                    data={savedRows}
                                    initialColumns={allColumns}
                                    rowCount={savedAudienceIds.length}
                                    showNoRowsOverlay={savedAudienceIds.length==0}
                                    pageSize={savedPageSize}
                                    currentPage={savedCurrentPage - 1}
                                    updateCurrentPage={setSavedCurrentPage as any}
                                    updatePageSize={setSavedPageSize}
                                    showSpinner={showSpinner}
                                    hidePagination={tableSearch?.length >= 2}
                                    // height={`calc(100% - 57px)`}
                                    rightPinnedColumns={["actions"]}
                                    onRowClick={(row) => openDrawer(row)}
                                    selectedRows={savedSelectedRowIds}
                                    setSelectedRows={setSavedSelectedRowIds}
                                />
                            </div>
                        )}
                    </div>
                    {showDeletePopup && (
                            <DeletePopup
                                acceptBtn='Delete'
                                acceptClick={handleRemoveTargetListClick}
                                cancelClick={() => {
                                    setShowDeletePopup(false);
                                }}
                                modalContent={`Are you sure you want to delete the selected Target List?`}
                                modalTitle='Delete Target List Audience'
                                show={showDeletePopup}
                                rejectBtn='Cancel'
                                modalHeaderIcon='trash'
                            />
                        )}
                </Box>
            </Box>
        );
    }

    const eventTargetListByAudienceMinimalView = () =>
    {
        return (
            <Box id="targetListAudienceMinimalView" style={{ width: "98%%" }}>
                <Box
                    key={"audience-page-table"}
                    sx={{
                        height: "calc(100vh - 60px)",
                        maxHeight: "calc(100vh - 60px)",
                    }}
                >
                    {selectedRowIds.length > 0 ? (
                        <TanstackToolbarWithServerFilter
                            columns={audienceColumns}
                            setColumns={setAllColumns}
                            setCurrentUrl={setCurrentUrl}
                            tabs={targetListAudiencePageToolBar}
                            handleApplyFilters={(filters) => {
                                handleApplyFilters(filters);
                            }}
                            filterColumns={audienceFilterColumns}
                            leftActionButtons={[<CustomButton
                                btnType="primary"
                                name="Add To List"
                                onClick={handleAddTargetListClick}
                            />,
                            <CustomButton
                                btnType="secondary"
                                name="Cancel"
                                onClick={handleCancelClick}
                            />,
                            <div className="table-header-text">
                                {" "}
                                {selectedRowIds.length} records selected{" "}
                            </div>]}
                        />
                    ) : savedSelectedRowIds.length > 0 ? (
                            <TanstackToolbarWithServerFilter
                                columns={audienceColumns}
                                setColumns={setAllColumns}
                                setCurrentUrl={setCurrentUrl}
                                tabs={targetListAudiencePageToolBar}
                                handleApplyFilters={(filters) => {
                                    handleApplyFilters(filters);
                                }}
                                filterColumns={audienceFilterColumns}
                                leftActionButtons={[ <CustomButton
                                    btnType="secondary"
                                    name="Cancel"
                                    onClick={handleSavedCancelClick}
                                />,
                                <CustomButton
                                    btnType="danger"
                                    name="Delete from list"
                                    onClick={() => setShowDeletePopup(true)}
                                />,
                                <div className="table-header-text">
                                    {" "}
                                    {savedSelectedRowIds.length} records selected{" "}
                                </div>]}
                            />
                    ) : (
                        <TanstackToolbarWithServerFilter
                            columns={audienceColumns}
                            setColumns={setAllColumns}
                            setCurrentUrl={setCurrentUrl}
                            tabs={targetListAudiencePageToolBar}
                            handleApplyFilters={(filters) => {
                                handleApplyFilters(filters);
                            }}
                            filterColumns={audienceFilterColumns}
                        />
                    )}
    
                    <div className="table-with-side-bar-filter">
                        <AudienceFilterAccordion onAutocompleteChange={handleAutocompleteChange} />
                        {!savedTab ? (
                            <div style={{ width: "calc(100vw - 297px)" }}>
                                <TanstackTable
                                    checkboxSelection={true}
                                    data={rows}
                                    initialColumns={allColumns}
                                    rowCount={audCount}
                                    showNoRowsOverlay={audCount==0}
                                    pageSize={pageSize}
                                    currentPage={currentPage - 1}
                                    updateCurrentPage={updateCurrentPage as any}
                                    updatePageSize={updatePageSize}
                                    showSpinner={showSpinner}
                                    hidePagination={tableSearch?.length >= 2}
                                    // height={`calc(100% - 57px)`}
                                    rightPinnedColumns={["actions"]}
                                    onRowClick={(row) => openDrawer(row)}
                                    selectedRows={selectedRowIds}
                                    
                                    setSelectedRows={setSelectedRowIds}
                                />
                            </div>
                        ) : (
                            <div style={{ width: "calc(100vw - 297px)" }}>
                                <TanstackTable
                                    checkboxSelection={true}
                                    data={savedRows}
                                    initialColumns={allColumns}
                                    rowCount={savedAudienceIds.length}
                                    showNoRowsOverlay={savedAudienceIds.length==0}
                                    pageSize={savedPageSize}
                                    currentPage={savedCurrentPage - 1}
                                    updateCurrentPage={setSavedCurrentPage as any}
                                    updatePageSize={setSavedPageSize}
                                    showSpinner={showSpinner}
                                    hidePagination={tableSearch?.length >= 2}
                                    // height={`calc(100% - 57px)`}
                                    rightPinnedColumns={["actions"]}
                                    onRowClick={(row) => openDrawer(row)}
                                    selectedRows={savedSelectedRowIds}
                                    setSelectedRows={setSavedSelectedRowIds}
                                />
                            </div>
                        )}
                    </div>
                    {showDeletePopup && (
                            <DeletePopup
                                acceptBtn='Delete'
                                acceptClick={handleRemoveTargetListClick}
                                cancelClick={() => {
                                    setShowDeletePopup(false);
                                }}
                                modalContent={`Are you sure you want to delete the selected Target List?`}
                                modalTitle='Delete Target List Audience'
                                show={showDeletePopup}
                                rejectBtn='Cancel'
                                modalHeaderIcon='trash'
                            />
                        )}
                </Box>
            </Box>
        );
    }

    return minimalView ? eventTargetListByAudienceMinimalView() : eventTargetListByAudiencePageView();


};

export default TargetListByAudiencePage;