import React, { useContext, useEffect, useState } from "react";
import { RegistrationForm } from "../interfaces/registration-form_interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import eventBus from "../../../scripts/event-bus";
import { FormComponentsContext, IFormComponentsContext } from "../../../contexts/FormComponentsContext";
import { FormControlComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { searchCustomField } from "../../../scripts/apis/customField";
import _, { camelCase } from "lodash";
import { CustomFieldLocations } from "../enum/custom-field.enum";
import { useLocation } from "react-router-dom";
import { PortalFormsContext } from "../../../contexts/Portal/PortalFormsContext";
import CustomTooltip from "../../../common/Tooltip";
import { IconName } from "@fortawesome/fontawesome-svg-core";

interface IExistingPropertiesSidedrawerProps { 
    formId: string | number;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    RegistrationFormData: RegistrationForm;
}

interface IFormComponentTypeNameProps {
    icon: [any, any];
    name: string;
    onClick: () => void;
}

const ComponentIcon: { [key: number]: IconName } = {
    1: 'minus',
    2: 'bars',
    3: 'circle-check',
    4: 'circle-chevron-down',
    5: 'calendar',
    6: 'input-numeric',
    7: 'heading',
    8: 'paragraph',
    9: 'circle-dot',
    14: 'smile',
}

const ComponentType: { [key: number]: string } = {
    1: 'Single-Line Text Box',
    2: 'Multi-Line Text Box',
    3: 'Checkbox',
    4: 'Dropdown',
    5: 'Date',
    6: 'Number Field',
    7: 'Section Heading',
    8: 'Paragraph Text',
    9: 'Radio Button',
    14: 'Rating'
}

const ExistingPropertiesSidedrawer: React.FC<IExistingPropertiesSidedrawerProps> = (props): React.JSX.Element =>
{
    const currentpath = useLocation().pathname;

    const { customFields, setCustomFields } = useContext<IFormComponentsContext>(currentpath?.includes('portal') ? PortalFormsContext : FormComponentsContext);

    const sortedFields = _.sortBy(customFields, ['type']);

    const groupedFields: { [key: string]: any[] } = 
    sortedFields?.reduce((acc, field) => {
        const type = ComponentType[field?.type];
        if(!acc[type])
        {
            acc[type] = [];
        }
        acc[type].push(field);
        return acc;
    }, {});

    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => 
    {
        const delayDebounceFn = setTimeout(async (): Promise<void> => 
        { 
            if(searchText?.length > 2)
            {
                try 
                {
                    const searchTextCamelCased = camelCase(searchText);
                    const fields = await searchCustomField(searchTextCamelCased, CustomFieldLocations.REGISTRATION_FORM);
                    if(fields)
                    {
                        const filteredFields = fields?.filter((field) => field?.propertyKey !== 'rateYourExperience1' && field?.propertyKey !== 'doYouHaveAnyFeedback')
                        if(filteredFields?.length > 0)
                        {
                            setCustomFields(fields);
                        }
                    }
                } 
                catch (error)
                {
                    
                }
            }
            else if (searchText?.length === 0)
            {
                props?.setRefresh(true);
            }
        }, 300);
        
        return (): void => 
        {
            return clearTimeout(delayDebounceFn);
        };
    }, [searchText]);
    
    return(
        <div id="existingPropertiesDrawer">
            <FormControlComponent 
                clearable
                type="text"
                value={searchText}
                onChange={(event) => { setSearchText(event.target.value) }}
                startIcon="search"
                placeholder="Search for a field"
            />
            {
                Object.entries(groupedFields)
                .map(([type, fields]) => {
                    return(
                        <div key={type} className="field-block">
                            <h3 className="type-name">{type}</h3>
                            {
                                fields
                                ?.filter((field) => field?.propertyKey !== 'rateYourExperience' && field?.propertyKey !== 'doYouHaveAnyFeedback')
                                ?.map((field) => {
                                    return(
                                        <FormComponentTypeNameBlock icon={['fal', ComponentIcon[field.type]]} name={field?.label ?? ''} onClick={() => {
                                            eventBus.dispatch('event-open-form-Component-drawer', {
                                                componentType: field.type,
                                                data: field
                                            });
                                        }} />
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
};

const FormComponentTypeNameBlock = ({ icon, name, onClick }: IFormComponentTypeNameProps): React.JSX.Element => 
{
    return (
            <>
                <div className="form-component-type-name-block" onClick={onClick}>
                    <div className="type-icon">
                        <FontAwesomeIcon icon={icon} className="navigation-icon" />
                    </div>
                    <CustomTooltip title={<div dangerouslySetInnerHTML={{ __html: name }} />}>
                        <div className="type-name"
                            dangerouslySetInnerHTML={{ __html: name }}
                        />
                    </CustomTooltip>
                </div>
            </>
    );
};

export default ExistingPropertiesSidedrawer;