import React, { ReactElement, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { CloseIconComponent, FormLabelComponent, RadioGroupComponent } from '../../../../common/FormComponents/ReusableFormComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import { Box, Slider, Stack, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { isImageBuffer } from '../../../../scripts/helpers';
import { AddPhotoDesignProps, UpdateWatermarkSettings, WatermarkSettings } from '../../interfaces/event-photo-sharing_interface';
import { useFormik } from 'formik';
import { WatermarkPosition } from '../../enum/event-photo-sharing.enum';
import { getWatermarkSettings, updateWatermarkSettings, uploadWatermarkImage } from '../../../../scripts/apis/eventPhotoSharing';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import templateImage from '../../../../assets/watermark-template.png';

const AddPhotoDesign = (props: AddPhotoDesignProps): React.JSX.Element => 
{
    const navigate = useNavigate();

    const [spinner, setSpinner] = useState(false);
    const [imageFile, setImageFile] = useState<Blob | null>(null);
    const [files, setFiles] = useState('');
    const [watermarkImage, setWatermarImage] = useState('');
    const [watermarkOpacity, setWatermarkOpacity] = useState(100);
    const [watermarkSettings, setWatermarkSettings] = useState<WatermarkSettings>();

    const eventId = props.eventId;
    const photoFolderId =  props?.existingFolderData?.id;

    const watermarkPositionOptions = 
    [
        { value: WatermarkPosition.NORTH_WEST, name: 'Top Left' },
        { value: WatermarkPosition.NORTH, name: 'Top' },
        { value: WatermarkPosition.NORTH_EAST, name: 'Top Right' },
        { value: WatermarkPosition.WEST, name: 'Left' },
        { value: WatermarkPosition.CENTER, name: 'Center' },
        { value: WatermarkPosition.EAST, name: 'Right' },
        { value: WatermarkPosition.SOUTH_WEST, name: 'Bottom Left' },
        { value: WatermarkPosition.SOUTH, name: 'Bottom' },
        { value: WatermarkPosition.SOUTH_EAST, name: 'Bottom Right' },
    ];

    const fetchWatermarkSettings = async () => 
    {
        try 
        {
            const watermarkSettingsData: WatermarkSettings = await getWatermarkSettings(eventId);
            setWatermarkSettings(watermarkSettingsData);
            if (watermarkSettingsData.watermarkSourceImage) 
            {
                setWatermarImage(watermarkSettingsData.watermarkSourceImage);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const csrfTokenData = useSelector((state: any): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const validationSchema = Yup.object().shape
    ({
        watermarkPosition: Yup.string().required('Watermark Position is required'),
        watermarkSize: Yup.number()
            .min(1, 'Watermark Size must be at least 1')
            .max(100, 'Watermark Size must be at most 100')
            .required('Watermark Size is required'),
        watermarkOpacity: Yup.number()
            .min(1, 'Watermark Opacity must be at least 1')
            .max(100, 'Watermark Opacity must be at most 100')
            .required('Watermark Opacity is required'),
    });

    const formik = useFormik
    ({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: 
        {
            watermarkPosition: watermarkSettings?.watermarkPosition
                ? watermarkSettings.watermarkPosition
                : WatermarkPosition.SOUTH,
            watermarkSize: watermarkSettings?.watermarkSize ? watermarkSettings.watermarkSize : 25,
            watermarkOpacity: watermarkSettings?.watermarkOpacity
                ? watermarkSettings.watermarkOpacity
                : 70,
        },

        onSubmit: async (): Promise<void> => 
        {
            setSpinner(true);
            if (watermarkSettings) 
            {
                const watermarkSettingsInfo: UpdateWatermarkSettings = 
                {
                    watermarkPosition: formik.values.watermarkPosition,
                    watermarkSize: Number(formik.values.watermarkSize),
                    watermarkOpacity: Number(formik.values.watermarkOpacity),
                };

                const watermarkSettingsCreated = await updateWatermarkSettings
                (
                    eventId,
                    watermarkSettingsInfo
                );

                try {
                    if (watermarkSettingsCreated) 
                    {
                        const image = new FormData();
                        if (imageFile) 
                        {
                            image.append('file', imageFile);
                            try 
                            {
                                const imageUploaded = await uploadWatermarkImage(
                                    eventId,
                                    watermarkOpacity,
                                    image,
                                    csrfTokenData
                                );

                                if (imageUploaded) 
                                {
                                    toast.success('Image uploaded successfully.');
                                }
                            } 
                            catch (error) 
                            {
                                toast.error('Image upload failed.');
                            }
                        }
                        props.setRefresh(true);
                        handleDrawerClose();
                    }
                    else
                    {
                        toast.error('Unable to upload and update the Watermark settings');
                    }
                }
                catch (error) 
                {
                    console.log(error);
                }
                finally 
                {
                    setSpinner(false);
                }
            }
            else 
            {
                toast.error('Watermark settings are not available.');
                setSpinner(false);
            }
        },
    });

    const handleWatermarkPostionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLLIElement>) => 
    {
        formik.setFieldValue('watermarkPosition', event.target.value);
    };

    const handleWatermarkSizeChange = (event: any) => 
    {
        formik.setFieldValue('watermarkSize', event.target.value);
    };

    const handleWatermarkOpacityChange = (event: any) =>
    {
        formik.setFieldValue('watermarkOpacity', event.target.value);
        setWatermarkOpacity(event.target.value);
    };

    const { getRootProps, getInputProps } = useDropzone
    ({
        accept:
        {
            'image/png': [],
            'image/jpeg': [],
        },
        multiple: false,
        onDrop: (acceptedFiles): void => 
        {
            const file = acceptedFiles[0];

            const fileSize = file.size;

            const fileSizeInMB = fileSize / (1024 * 1024);

            const reader = new FileReader();
            reader.onloadend = (): void => 
            {
                const buffer = new Uint8Array(reader.result as ArrayBufferLike);
                const isImage = isImageBuffer(buffer);

                if (isImage && fileSizeInMB < 10) 
                {
                    setImageFile(file);
                    setFiles(URL.createObjectURL(file));
                }
                else 
                {
                    setFiles('');
                    toast.error
                    (
                        fileSizeInMB > 10
                            ? 'Image size should be less than 10 MB'
                            : 'Please provide a valid image'
                    );
                    return;
                }
            };

            reader.readAsArrayBuffer(file);
        },
    });

    const getWatermarkPositionStyle = (position: WatermarkPosition) => 
    {
        switch (position) 
        {
            case WatermarkPosition.NORTH_WEST:
                return { top: '0', left: '0' };
            case WatermarkPosition.NORTH:
                return { top: '0', left: '50%', transform: 'translateX(-50%)' };
            case WatermarkPosition.NORTH_EAST:
                return { top: '0', right: '0' };
            case WatermarkPosition.WEST:
                return { top: '50%', left: '0', transform: 'translateY(-50%)' };
            case WatermarkPosition.CENTER:
                return { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' };
            case WatermarkPosition.EAST:
                return { top: '50%', right: '0', transform: 'translateY(-50%)' };
            case WatermarkPosition.SOUTH_WEST:
                return { bottom: '0', left: '0' };
            case WatermarkPosition.SOUTH:
                return { bottom: '0', left: '50%', transform: 'translateX(-50%)' };
            case WatermarkPosition.SOUTH_EAST:
                return { bottom: '0', right: '0' };
            default:
                return {};
        }
    };

    const Preview = (): ReactElement => 
    {
        return (
            <Box className='preview-container'>
                <img src={(watermarkImage ? watermarkImage : '') || (files ? files : '')} />
            </Box>
        );
    };

    const handleDrawerClose: () => void = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, 
        {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);
        // navigate(`/events/${eventId}/photo-sharing`);
    };

    useEffect(() => 
    {
        fetchWatermarkSettings();
    }, []);

    return (
        <div id='addPhotoDesign'>
            <CloseIconComponent onClick={handleDrawerClose} />

            <Form
                noValidate
                onSubmit={(values: React.FormEvent<HTMLFormElement>): void => 
                {
                    return formik.handleSubmit(values);
                }}
                autoComplete='off'
            >
                {/* Watermark Preview */}
                <Box className='preview-container'>
                    <div className='watermark-bg'>
                        <img src={templateImage} alt='Source' />
                    </div>
                    {(watermarkImage || files) && (
                        <Box
                            className='watermark-overlay'
                            style={{
                                ...getWatermarkPositionStyle(formik.values.watermarkPosition),
                                width: `${formik.values.watermarkSize}%`,
                                opacity: formik.values.watermarkOpacity / 100,
                            }}
                        >
                            <img
                                src={(watermarkImage ? watermarkImage : '') || (files ? files : '')}
                                alt='Watermark'
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                        </Box>
                    )}
                </Box>

                {/* Image */}
                <Box className='sidebar-container-spacing'>
                {files || watermarkImage ? '' : <FormLabelComponent label='Upload Watermark' required={true} />}
                    {files !== '' || watermarkImage !== '' ? (
                        <Box className='preview-upload-box'>
                            <Preview />
                            {
                                <React.Fragment>
                                    <Box className='icon-box'>
                                        <FontAwesomeIcon
                                            icon={['fal', 'xmark']}
                                            className='remove-icon'
                                            onClick={(): void => {
                                                setFiles('');
                                                setWatermarImage('');
                                            }}
                                        />
                                    </Box>
                                </React.Fragment>
                            }
                        </Box>
                    ) : (
                        <Box {...getRootProps()} className='empty-upload-box'>
                            <input {...getInputProps()} hidden accept='image/*' type='file' />
                            <FontAwesomeIcon
                                className='upload-icon'
                                icon={['fal', 'cloud-arrow-up']}
                            />
                            <Typography>{'Browse File'}</Typography>
                        </Box>
                    )}
                </Box>

                {files || watermarkImage ? (
                    <>
                        {/* Watermark Position */}
                        <div className='sidebar-container-spacing '>
                            <FormLabelComponent label={'Watermark Position'} required={true} />
                            <RadioGroupComponent
                                row
                                options={watermarkPositionOptions}
                                value={String(formik.values.watermarkPosition)}
                                onChange={handleWatermarkPostionChange}
                            />
                        </div>

                        {/* Watermark Size */}
                        <div className='sidebar-container-spacing '>
                            <FormLabelComponent label={'Watermark Size'} />
                            <div className='slider-with-values'>
                                <Slider
                                    value={formik.values.watermarkSize}
                                    onChange={handleWatermarkSizeChange}
                                    aria-labelledby='watermark-size'
                                    min={1}
                                    max={100}
                                />
                                <div>{formik.values.watermarkSize}%</div>
                            </div>
                            <Typography className='form-helper-text'>
                                Watermark size will be proportional to the photo size
                            </Typography>
                        </div>

                        {/* Watermark Opacity */}
                        <div className='sidebar-container-spacing '>
                            <FormLabelComponent label={'Watermark Opacity'} />
                            <div className='slider-with-values'>
                                <Slider
                                    value={formik.values.watermarkOpacity}
                                    onChange={handleWatermarkOpacityChange}
                                    aria-labelledby='watermark-opacity'
                                    min={1}
                                    max={100}
                                />
                                <div>{formik.values.watermarkOpacity}%</div>
                            </div>
                        </div>
                    </>
                ) : null}

                <div className='submit-btn-container'>
                    <Stack
                        direction={'row'}
                        spacing={2}
                        display={'flex'}
                        justifyContent={'space-between'}
                        width={'100%'}
                    >
                        <Stack direction={'row'} spacing={1} display={'flex'}>
                            <CustomButton
                                btnType='secondary'
                                onClick={handleDrawerClose}
                                name={'Cancel'}
                            />
                            <CustomButton
                                btnType='primary'
                                loading={spinner}
                                name={'Save'}
                                type='submit'
                            />
                        </Stack>
                    </Stack>
                </div>
            </Form>
        </div>
    );
};

export default AddPhotoDesign;