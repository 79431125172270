import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import RegistrationEmailsSection from './RegistrationEmailSection';
import useEventRegisreationDetailsData from '../../../../hooks/eventRegistrationDetails';
import CustomSpinner from '../../../../common/CustomSpinner';
import Ticket, { RegistrationTypes } from './Tickets/Ticket';
import RegistrationDetailsSection from './RegistrationDetailsSection';
import { UserViewType } from '../../../Settings/enum/users.enum';
import TablePopupTabs from '../../../../common/TablePopup/TablePopupTabs';
import { UserContext } from '../../../../contexts/UserContext';
import MuiChip from '../../../../common/FormComponents/MuiChip';
import CouponsProvider from '../../../../contexts/EventCoupons/EventCouponsContext';
import TicketsPageProvider from '../../../../contexts/EventDetailsRegistration/TicketsPageContext';
import { getCouponCount } from '../../../../scripts/apis/eventTicketCoupons';
import { ticketsCount } from '../../../../scripts/apis/eventTickets';
import CouponsPage from '../EventTicketCoupons/CouponsPage';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import CreateRegistrationDetails from '../../../../components/Events/EventRegistrations/CreateRegistrationDetails';


const EventRegistrationDetails: React.FC<{eventId: number}> = (props: {eventId: number}): React.JSX.Element => 
{

    const {
        userDetails
    } = useContext(UserContext);

    const [ticketCount, setTicketCount] = useState<number>(0);
    const [couponCount, setCouponCount] = useState<number>(0);

    const eventId = props.eventId;
    const [eventDetails, spinner, isEventTicketPresent, isTicketApprovalOn, isTicketPaid] = useEventRegisreationDetailsData({
        eventId: Number(eventId) 
    });

    const fetchCountData = async (): Promise<void> => {
        try 
        {
            const ticketCount  = await ticketsCount(Number(eventId));
            setTicketCount(ticketCount);

            const couponCount = await getCouponCount(Number(eventId));
            setCouponCount(couponCount);
        } 
        catch (error) 
        {
            console.error('Error while fetching count data', error);
        }
    }


    useEffect(() => {
        fetchCountData();
    }, []);

    const handleEditRegistrationDetails = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Registration Details',
            hideCloseButton: true,
            component: <CreateRegistrationDetails eventDetails={eventDetails} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const renderHeader = () => {
        return (
            <div className="registrations-header-container">
                <h3>{'Registrations'}</h3>
                 <CustomButton
                    name={'Edit Registration'}
                    onClick={handleEditRegistrationDetails}
                    btnType='secondary'
                    style={{ margin: userDetails?.viewType === UserViewType.MINIMAL ? '0' : '0 20px 0' }}
                />
            </div>
        )
    };


    const renderRegistrationsContent = (): React.JSX.Element => {
        return (
            <Box id="eventRegDetails">
                {/* <Box id='inside-event-module-name-section'>{'Forms'}</Box> */}
                {spinner ? <CustomSpinner height={'100%'} /> : <>
                    <Box className="details-block">
                        <div className="registrations-content">
                            {renderHeader()}
                            <RegistrationDetailsSection eventDetails={eventDetails} spinner={spinner} />
                         </div>
                        <TicketsPageProvider eventId={eventId}><Ticket eventId={eventId} /></TicketsPageProvider>
                        <CouponsProvider tableView={false} eventId={eventId}><CouponsPage /></CouponsProvider>
                        <RegistrationEmailsSection eventId={Number(eventId)} eventName={eventDetails.title || ''} registrationType={eventDetails?.registrationType || RegistrationTypes.INVITE_ONLY} isEventTicketPresent = {isEventTicketPresent} isTicketApprovalOn={isTicketApprovalOn} isTicketPaid = {isTicketPaid} />
                        {/* <RegistrationFormsSection registrationFormId={String(eventDetails?.registrationFormId)} additionalForms={eventDetails?.additionalForms} eventId={eventId}/> */}
                    </Box>
                </>}
            </Box>
        );
    }

    const renderMinimalViewForRegistrations = (): React.JSX.Element => { 
        return (
            <div id="basicRegistrations">
                <div className="registrations-container-minimal">
                    {renderRegistrationsContent()}
                </div>
            </div>
        )
    };

    const renderNormalViewForRegistrations = (): React.JSX.Element => { 

        const tabData = [
            {
                title:
                    <div className="count-container">
                        <h2 className="heading">{'Tickets'}</h2>
                        {ticketCount && ticketCount > 0 && <MuiChip chipColor="grey" label={ticketCount || '0'} />}
                    </div>,
                data: <TicketsPageProvider eventId={Number(eventId)} tableView={true}><Ticket eventId={Number(eventId)} /></TicketsPageProvider>
            },
            {
                title: 
                    <div className="count-container">
                        <h2 className="heading">{'Coupons'}</h2>
                        {couponCount && couponCount > 0 && <MuiChip chipColor="grey" label={couponCount || '0'} />}
                    </div>,
                data: <CouponsProvider eventId={Number(eventId)} tableView={true}><CouponsPage /></CouponsProvider>
            }
        ];

        return (
            <div id="basicRegistrations">
                   <div className="popup-details-tabs-wrapper">
                        <TablePopupTabs tabData={tabData} />
                    </div>
                {/* <div className="registrations-container">
                    {renderRegistrationsContent()}
                </div> */}
            </div>
        )
    };

     return (
        <>
            {
                userDetails?.viewType === UserViewType.MINIMAL ? renderMinimalViewForRegistrations() : renderNormalViewForRegistrations()
            }
        </>
    )

};

export default EventRegistrationDetails;
