import { CustomFieldMandatory, CustomFieldRatingType } from "../../pages/Registration/enum/custom-field.enum";

interface RatingProps {
    type: CustomFieldRatingType;
    label?: string;
    helpText?: string;
    fieldMandatory?: CustomFieldMandatory;
    value: number;
    onChange?: (value: number) => void;
    disabled?: boolean;
}

const Rating: React.FC<RatingProps> = (props): React.JSX.Element =>
{
    const { type, label, helpText, fieldMandatory, value } = props;

    const renderEmojiRating = (): React.JSX.Element =>
    {
        return (
            <div className="rating-emoji">
                {['😡', '😞', '😐', '🙂', '🤩'].map((emoji, index) => {
                    const ratingValue = index + 1;
                    return (
                        <button
                            key={ratingValue}
                            className={`emoji-icon ${value == ratingValue ? 'selected' : ''}`}
                            onClick={(): void => {
                                if(props?.disabled)
                                {
                                    return;
                                }
                                props?.onChange ? props?.onChange(ratingValue) : undefined
                            }}
                        >
                            {emoji}
                        </button>
                    );
                })}
            </div>
        )
    }

    const renderNumericRating = (): React.JSX.Element =>
    {
        return (
            <div className="rating-numeric">
                {
                    Array.from({ length: 10 }, (_, index) => index + 1).map((num) => {
                        return (
                            <button 
                                key={num}
                                className={`numeric-button ${num == value ? 'selected' : ''}`}
                                onClick={(): void => {
                                    if(props?.disabled)
                                    {
                                        return;
                                    }
                                    props?.onChange ? props?.onChange(num): undefined
                                }}
                            >
                                {num}
                            </button>
                        )
                    })
                }
            </div>
        )
    }

    const renderStarRating = (): React.JSX.Element =>
    {
        return (
            <div className="rating-star">
                {Array.from({ length: 5 }, (_, index) => index + 1).map((num) => {
                    return (
                        <button
                            key={num}
                            className={"star-icon"}
                            onClick={(): void => props?.onChange ? props?.onChange(num) : undefined}
                            disabled={props?.disabled}
                        >
                            <span className={`${num <= value ? 'selected' : ''}`}>{num <= value ? '★' : '☆'}</span>
                        </button>
                    );
                })}
            </div>
        )
    }

    const renderRating = (): React.JSX.Element =>
    {
        switch(type)
        {
            case CustomFieldRatingType.EMOJI:
                return renderEmojiRating();
            case CustomFieldRatingType.NUMBER:
                return renderNumericRating();
            case CustomFieldRatingType.STAR:
                return renderStarRating();
            default:
                return <></>;
        }
    }

    return (
        <div className="rating">
            {renderRating()}
        </div>
    )
}

export default Rating;