import { Box, Typography } from '@mui/material';
import React from 'react';
import { CONTENT } from '../../../scripts/i18n';
// eslint-disable-next-line import/named
import CustomSpinner from '../../../common/CustomSpinner';
import { topRoiEventsTableProps } from '../dashboard_interface';
import MuiChip from '../../../common/FormComponents/MuiChip';
import TanstackTable from '../../../common/TanstackTable/TanstackTable';
import { createColumnHelper } from '@tanstack/react-table';

import './styles.scss';

const TopRoiEventsTable: React.FC<topRoiEventsTableProps> = (props: topRoiEventsTableProps): React.JSX.Element => 
{
    const columnHelper = createColumnHelper();

    const columns = [
        columnHelper.accessor('title', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Name',
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('type', {
            cell: (row) => {
                return (<p className="cellContent">{CONTENT.DASHBOARD_PAGE.EVENT_TYPES[row.getValue()]}</p>)
            },
            header: 'Type',
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('locationType', {
            cell: (row) => {
                return (<p className="cellContent">{CONTENT.DASHBOARD_PAGE.EVENT_LOCATION_TYPES[row.getValue()]}</p>)
            },
            header: 'Location',
            size: 80,
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('roi', {
            cell: (row) => {
                const roiChipColor = row?.row?.original?.roi?.roi && row?.row?.original?.roi?.roi >= 1 ? 'green' : row?.row?.original?.roi?.roi && row?.row?.original?.roi?.roi < 1 ? 'red' : 'null';
                return (
                    <Box className="dashboard-roi-container">
                        <MuiChip circleIcon chipColor={roiChipColor} label={row?.row?.original?.roi?.roi && row?.row?.original?.roi?.roi !== null ? row?.row?.original?.roi?.roi : '-'} />
                    </Box>
                );
            },
            header: 'RoI',
            size: 50,
            maxSize: 50,
            sortingFn: 'alphanumericCaseSensitive'
        })
    ]

    return (
        <Box className={'top-events-container'}>
            {props.isTableReady ?
                <>
                    <Box className="top-events-title-container">
                        <Typography className="top-events-title">{'Events with Top RoI'}</Typography>
                    </Box>
                    <Box className="top-events-table-container">
                        <TanstackTable 
                            data={props?.data}
                            initialColumns={columns}
                            rowCount={props?.data?.length}
                            hidePagination
                            showSpinner={false}
                            height='195px'
                            fullWidth={props?.data?.length === 0}
                            showNoRowsOverlay={props?.data?.length === 0}
                            showBottomPadding={false}
                        />
                    </Box>
                </>
                // : <TableEmptyComponent height={`${135}px`} heading={'Events with Top RoI'} />
                :
                <Box className="top-events-container">
                    <Box className="top-events-title-container">
                        <Typography className="top-events-title">{'Events with Top RoI'}</Typography>
                    </Box>
                    <CustomSpinner height={'169px'} />
                </Box>
            }
        </Box>
    );
};

export default TopRoiEventsTable;