import { User } from '../../interfaces/settings/user_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';
import { userOnline } from '../main';

// export const inviteUsers = async (USERS, csrfToken: string):  => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/users/bulk';
//     return triggerApi(API_URL, 'POST', USERS, csrfToken);
// };

export const updateGeneralSettings = async (DATA: User): Promise<User> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/users';
    return triggerApi(API_URL, 'PUT', DATA);
};

export const uploadUserImage = async (IMAGE_DATA: FormData, csrfToken: string): Promise<string> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/image/upload';
    return triggerApi(API_URL, 'POST', IMAGE_DATA, csrfToken, false);
};

export const userCount = async (queryParams: [string, string][] = []): Promise<number> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/users/count' + '?' + query;;
    return triggerApi(API_URL, 'GET', '');
};

export const getUsers = async (pageSize: number, offset: number, queryParams: [string, any][] = []): Promise<User[]> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        ...filteredParams,
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/users?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const userMe = async (): Promise<User> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/users/me';
    const me = triggerApi(API_URL, 'GET', '');
    userOnline(me);
    return me;
};

export const createUser = async (userData: User, csrfToken: string): Promise<User> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/users';
    return triggerApi(API_URL, 'POST', userData, csrfToken);
};

export const removeUser = async (USER_ID: string | number): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/users/' + USER_ID;
    return triggerApi(API_URL, 'DELETE', '');
};

export const getUserById = async (USER_ID: string | number): Promise<User> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/users/' + USER_ID;
    return triggerApi(API_URL, 'GET', '');
};

export const editUser = async (USER_DATA: User): Promise<User> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/users/bulk';
    return triggerApi(API_URL, 'PUT', USER_DATA);
};

export const retriggerInviteForUser = async (userId: string | number, csrfToken: string): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/users/trigger-invite/' + userId;
    return triggerApi(API_URL, 'POST', '', csrfToken);
}

