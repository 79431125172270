import React, { useContext, useEffect, useState } from 'react';
import { EventAccessControlContext } from '../../../../../../contexts/EventAccessControl/EventAccessControlContext';
import TableEmptyComponent from '../../../../../../common/TableEmptyComponent';
import TanstackToolbarWithServerFilter from '../../../../../../common/TanstackTable/TanstackToolbarWithServerFilter';
import TanstackTable from '../../../../../../common/TanstackTable/TanstackTable';
import { createColumnHelper } from '@tanstack/react-table';
import { EventAccessControl } from '../../../../interfaces/event-access-control_interface';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventAccessControlStatus } from '../../../../enum/event-access-control.enum';
import _ from 'lodash';
import { CustomButton } from '../../../../../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APP_CONSTANTS from '../../../../../../scripts/constants';
import eventBus from '../../../../../../scripts/event-bus';
import AddEventAccessControl from '../../../../../../components/Events/AccessControl/AddEventAccessControl';
import { UserContext } from '../../../../../../contexts/UserContext';
import { UserViewType } from '../../../../../Settings/enum/users.enum';
import { MinimalViewComponent } from '../../../../../../common/MinimalView/MinimalViewComponent';
import AddEventBadgeSettings from '../../../../../../components/Events/AccessControl/AddEventBadgeSettings';
import AddCardSettings from '../../../../../../components/Events/CheckinApp/AddCardSettings';
import { useSelector } from 'react-redux';
import ColumnVisibilityDropdown from '../../../../../../common/TanstackTable/ColumnVisibilityDropdown';
import { IEventsDispatch, IEventReduxValues } from '../../../../../../components/Events/interfaces/create-events_interface';
import HeaderBar from '../../../../../../common/Headerbar';
import './styles.scss';
import AccessPinActionsComponent from './AccessPinActionsComponent';
import { TableActions } from '../../../../../../common/TableActions';
import DeletePopup from '../../../../../../common/DeletePopup';
import CustomTooltip from '../../../../../../common/Tooltip';
import EventsHeaderBar from '../../../../../../common/EventsHeaderBar';

const ViewAllDevicesComponent = () => 
  {
    const {
        isEmpty,
        eventAccessControlData,
        setRefresh,
        showSpinner,
        eventId,
        pageSize,
        currentPage,
        updatePageSize,
        updateCurrentPage,
        eventAccessControlCount,
        eventStages,
        event,
        setCurrentUrl,
        deleteDeviceFromAccessControl,
    } = useContext(EventAccessControlContext);

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const { userDetails } = useContext(UserContext);
    const minimalView = userDetails?.viewType === UserViewType.MINIMAL;



    const navigate = useNavigate();
    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();
    const [selectedRow, setSelectedRow] = useState<EventAccessControl | null>(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const handleEdit = (row: EventAccessControl): void => 
    {
        openEventAccessControlSidedrawer(row);
    };

    const handleDeleteClick = async (): Promise<void> => 
    {
        deleteDeviceFromAccessControl(Number(selectedRow?.id));
        setSelectedRow(null);
        setShowDeletePopup(false);
        setRefresh(true);
    };

    const columnHelper = createColumnHelper<EventAccessControl>();

    const columns = [
        columnHelper.accessor('deviceName', {
            cell: (row) => {
                return accessControlName(row.row.original);
            },
            header: 'Device Name',
            size: 300,
            id: 'deviceName',
        }),
        columnHelper.accessor('viewPin' as any, {
            cell: (row) => {
                return viewPin(row.row.original);
            },
            header: '',
            size: 250,
            id: 'viewPin',
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                 return (
                    <TableActions
                        actions={ [
                            {
                                title: 'Edit',
                                onClick: (): void => 
                                {
                                    handleEdit(row.row.original);
                                }
                            },
                            {
                                title: 'Delete',
                                onClick: (): void => 
                                {
                                    setShowDeletePopup(true);
                                    setSelectedRow(row.row.original);
                                }
                            }] }
                    />
                );
            },
            header: '',
            size: 40,
            id: 'actions',
        }),
    ];

    const [allColumns, setAllColumns] = useState([...columns]);

    const checkInDevicesFilterColoumns = [
        {
            header: 'Device Name',
            id: 'deviceName',
            meta: {
                type: 'string',
            },
        },
    ];

    const accessControlName = (row: EventAccessControl): React.ReactElement => 
    {
        return (
            <div>
                <Typography className='bolded-cellContent'>{row?.deviceName}</Typography>
            </div>
        );
    };
    
    const viewPin = (row: EventAccessControl): React.ReactElement => 
        {
            const viewPinHeaderClass: React.CSSProperties = {
                padding: '12px',
                borderRadius: 'var(--radius-lg, 10px)',
                border: '1px solid var(--Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border, #EAECF0)',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                height: '48px',
                width: '48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            };
            
            return (
                <div>
                {row?.status === EventAccessControlStatus.ACTIVE && (
                    <CustomButton
                    btnType='infoBlue'
                    name='View PIN'
                    type='button'
                    onClick={(event) => {
                        event.stopPropagation();
                        setTimeout((): void => {
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                                heading: (
                                    <Box sx={viewPinHeaderClass}>
                                            <FontAwesomeIcon icon={['fal', 'lock']} />
                                        </Box>
                                    ),
                                    component: (
                                        <AccessPinActionsComponent
                                            eventId={Number(eventId)}
                                            data={row}
                                            componentType={'viewPin'}
                                        />
                                    ),
                                    componentWidth: '512px',
                                    componentHeight: '262px',
                                });
                                
                                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                                    open: true,
                                });
                            });
                        }}
                        />
                    )}
            </div>
        );
    };
    
    const handleApplyFilters = (
        filters: { selectedColumn: string; inputValue: string }[]
    ): void => 
        {
        filters.forEach((filter) => {
            if (filter.selectedColumn === 'source') {
                searchParams.append('sourcesArr', filter.inputValue);
            } else {
                searchParams.append(filter.selectedColumn, filter.inputValue);
            }
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const openEventAccessControlSidedrawer = (accessControlData?: EventAccessControl) =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: accessControlData ? 'Update Device' : 'Create Device',
            hideCloseButton: true,
            component: <AddEventAccessControl eventId={Number(eventId)} setRefresh={setRefresh} accessControlData={accessControlData} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };
  
    const openEventBadgeSettingsSidedrawer = () =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Badge Settings',
            hideCloseButton: true,
            component: <AddEventBadgeSettings eventId={eventId} setRefresh={setRefresh} eventData={event} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };
  
    const openEventCardSettings = () => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'VCF Card Settings',
            hideCloseButton: true,
            component: <AddCardSettings eventId={eventId} setRefresh={setRefresh} formId={eventReduxData?.registrationFormId as string} existingVCardSettings={eventReduxData?.vcardSettings} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            // width: '50vw !important'
        });
    };



    const handleBackClick = () => 
    {
        navigate(`/events/${eventId}/check-in`);
    };

    useEffect(() => 
    {
        setAllColumns([...columns]);
    }, [eventStages]);

    const buttomComponent = (
        <CustomButton
            key={1} 
            name={'Add Device'} 
            btnType='secondary' 
            onClick={() => openEventAccessControlSidedrawer()} 
        />
    );

    const viewAllDevicesView = () =>
    {
        return (
            <div id='viewAllDevicesView'>
                <EventsHeaderBar
                    title={'Devices'}
                    buttons={[
                        <CustomTooltip title='VCF Card Settings' key={0}>
                            <div>
                                <CustomButton
                                    key={0}
                                    btnType='tertiary'
                                    name=''
                                    startIcon={ <FontAwesomeIcon icon={ ['fal', 'id-badge'] } /> }
                                    onClick={() => openEventCardSettings()}
                                />
                            </div>
                        </CustomTooltip>,
                        <CustomTooltip title='Badge Settings' key={1}>
                            <div>
                                <CustomButton
                                    key={1}
                                    btnType='tertiary'
                                    name=''
                                    startIcon={ <FontAwesomeIcon icon={ ['fal', 'qrcode'] } /> }
                                    onClick={(): void => {
                                        openEventBadgeSettingsSidedrawer();
                                    }}
                                />
                            </div>
                        </CustomTooltip>,
                    ]}
                />
                {isEmpty ? (
                    <TableEmptyComponent
                        emptyImg={''}
                        infoText={'No CheckIn Devices Yet'}
                        subInfoText={'Create your first device here'}
                        additionalComponent={buttomComponent}
                    />
                ) : (
                    <div style={{ height: 'calc(100% - 128px)' }}>
                        <TanstackToolbarWithServerFilter
                            columns={columns}
                            setColumns={setAllColumns}
                            tabs={[
                                {
                                    tabName: 'All',
                                    count: eventAccessControlCount,
                                    navigation: () => {},
                                    selectedTab: true,
                                },
                            ]}
                            setCurrentUrl={setCurrentUrl}
                            filterColumns={checkInDevicesFilterColoumns}
                            handleApplyFilters={(filters) => handleApplyFilters(filters)}
                            rightCornerButton={
                                <CustomButton
                                    key={1}
                                    name={<FontAwesomeIcon icon={['fal', 'plus']} />}
                                    btnType='primary'
                                    onClick={() => openEventAccessControlSidedrawer()}
                                />
                            }
                        />
                        <TanstackTable
                            data={eventAccessControlData}
                            rowCount={eventAccessControlCount}
                            showSpinner={showSpinner}
                            pageSize={pageSize}
                            updatePageSize={updatePageSize}
                            updateCurrentPage={updateCurrentPage as any}
                            currentPage={currentPage - 1}
                            initialColumns={allColumns}
                            rightPinnedColumns={['viewPin', 'actions']}
                            // height={'calc(100% - 48px)'}
                            showBottomPadding={false}
                        />
                    </div>
                )}
                {showDeletePopup && (
                    <DeletePopup
                        acceptBtn='Delete'
                        acceptClick={handleDeleteClick}
                        cancelClick={() => {
                            setShowDeletePopup(false);
                        }}
                        modalContent={`Are you sure you want to delete the device "${selectedRow?.deviceName}"?`}
                        modalTitle='Delete CheckIn Devices'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='trash'
                    />
                )}
            </div>
        );
    };

    const renderBreadCrumbs = () => {
        return (
            <div id="inside-event-module-name-section">
                <div onClick={handleBackClick} className="back-arrow">
                    <FontAwesomeIcon icon={['fal', 'arrow-left']} />
                    <div>CheckIn App</div>
                    {" / "}
                </div>
                
                <div className='header-text'>Devices</div>
            </div>
        )
    };

    const allDevicesMinimalView = () =>
      {
          return (
              <div id='viewAllDevicesMinimalView'>
                  <MinimalViewComponent
                      title={'Devices'}
                      description={''}
                      showCharts={false}
                      componentType='table'
                      titleBarActions={[
                        <CustomButton
                            key={0}
                            btnType='tertiary'
                            name=''
                            startIcon={ <FontAwesomeIcon icon={ ['fal', 'id-badge'] } /> }
                            onClick={() => openEventCardSettings()}
                        />,
                        <CustomButton
                            key={1}
                            btnType='tertiary'
                            name=''
                            type='button'
                            startIcon={ <FontAwesomeIcon icon={ ['fal', 'qrcode'] } /> }
                            onClick={(): void => {
                                openEventBadgeSettingsSidedrawer();
                            }}
                        />,
                    ]}
                      tabData={[
                          {
                              tabName: 'All',
                              count: eventAccessControlCount,
                              navigation: () => {},
                              selectedTab: true,
                          },
                      ]}
                      actions={[
                          !isEmpty && (
                              <TanstackToolbarWithServerFilter
                                  key={1}
                                  columns={columns}
                                  filterColumns={checkInDevicesFilterColoumns}
                                  handleApplyFilters={handleApplyFilters}
                                  setCurrentUrl={setCurrentUrl}
                                  minimalView={true}
                              />
                          ),
                          !isEmpty && (
                              <ColumnVisibilityDropdown
                                  key={2}
                                  columns={columns}
                                  setColumns={setAllColumns}
                                  showIconButton={true}
                              />
                          ),
                          <CustomButton
                              key={2}
                              btnType='primary'
                              name=''
                              startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                              type='button'
                              onClick={(): void => {
                                  openEventAccessControlSidedrawer();
                              }}
                          />,
                      ]}
                      component={
                          <div className='minimalView-table-container'>
                              {isEmpty ? (
                                  <div style={{ height: 'calc(100vh - 280px)' }}>
                                      <TableEmptyComponent infoText={'No Users Yet'} />
                                  </div>
                              ) : (
                                  <div style={{ height: 'calc(100%)' }}>
                                      <TanstackTable
                                          data={eventAccessControlData}
                                          initialColumns={allColumns}
                                          rowCount={eventAccessControlCount as number}
                                          pageSize={pageSize}
                                          currentPage={currentPage - 1}
                                          updatePageSize={updatePageSize}
                                          updateCurrentPage={updateCurrentPage as any}
                                        //   height='calc(100vh - 310px)'
                                          showBottomPadding={false}
                                          showSpinner={showSpinner}
                                          rightPinnedColumns={['viewPin', 'actions']}
                                          onRowClick={(row) =>
                                              openEventAccessControlSidedrawer(row)
                                          }
                                      />
                                  </div>
                              )}
                          </div>
                      }
                  />
                  {showDeletePopup && (
                      <DeletePopup
                          acceptBtn='Delete'
                          acceptClick={handleDeleteClick}
                          cancelClick={() => {
                              setShowDeletePopup(false);
                          }}
                          modalContent={`Are you sure you want to delete the device "${selectedRow?.deviceName}"?`}
                          modalTitle='Delete CheckIn Devices'
                          show={showDeletePopup}
                          rejectBtn='Cancel'
                          modalHeaderIcon='trash'
                      />
                  )}
              </div>
          );
      };

    return minimalView ? allDevicesMinimalView() : viewAllDevicesView();
};

export default ViewAllDevicesComponent;
