// import { motion } from 'framer-motion';
// import TanstackTable from '../TanstackTable/TanstackTable';

// import './styles.scss';

// interface TablePopupProps {
//     popupContent: React.ReactNode;
//     rows: any[];
//     columns: any[];
//     count: number;
//     pageSize: number;
//     currentPage: number;
//     updateCurrentPage: (page: number) => void;
//     updatePageSize: (size: number) => void;
//     height: string;
//     drawerOpen: boolean;
//     onRowClick: (row: any) => void;
// }

// const TablePopup: React.FC<TablePopupProps> = (props) => {

//     const {
//         popupContent,
//         columns,
//         count,
//         currentPage,
//         height,
//         pageSize,
//         rows,
//         updateCurrentPage,
//         updatePageSize,
//         drawerOpen,
//         onRowClick
//     } = props;

//     return (
//         <div id="tablePopup">
//             <div className="popup-table-container">
//                 {/* Table Wrapper */}
//                 <motion.div
//                     className="table-wrapper"
//                     style={{ height }}
//                     animate={{ width: drawerOpen ? '50%' : '100%' }}
//                     transition={{ type: 'spring', stiffness: 300, damping: 30 }}
//                 >
//                     <TanstackTable
//                         data={rows}
//                         initialColumns={columns}
//                         rowCount={count}
//                         showNoRowsOverlay={count == 0}
//                         pageSize={pageSize}
//                         currentPage={currentPage - 1}
//                         updateCurrentPage={updateCurrentPage as any}
//                         updatePageSize={updatePageSize}
//                         showSpinner={false}
//                         onRowClick={(row) => onRowClick(row)}
//                         showPageSizeOptions={false}
//                     />
//                 </motion.div>

//                 {/* Drawer */}
//                 {drawerOpen && (
//                     <motion.div
//                         className="popup"
//                         animate={{ width: drawerOpen ? '50%' : '0%' }}
//                         transition={{ type: 'spring', stiffness: 300, damping: 30 }}
//                         style={{ height }}
//                     >
//                        {popupContent}
//                     </motion.div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default TablePopup;

import TanstackTable from '../TanstackTable/TanstackTable';
import './styles.scss';

interface TablePopupProps {
    popupContent: React.ReactNode;
    rows: any[];
    columns: any[];
    count: number;
    pageSize: number;
    currentPage: number;
    updateCurrentPage: (page: number) => void;
    updatePageSize: (size: number) => void;
    height?: string;
    drawerOpen: boolean;
    onRowClick: (row: any) => void;
    leftPinnedColumns?: string[];
    rightPinnedColumns?: string[];
    selectedRow?: any;
}

const TablePopup: React.FC<TablePopupProps> = (props) => {
    const {
        popupContent,
        columns,
        count,
        currentPage,
        height,
        pageSize,
        rows,
        updateCurrentPage,
        updatePageSize,
        drawerOpen,
        onRowClick,
        leftPinnedColumns,
        rightPinnedColumns,
        selectedRow
    } = props;

    return (
        <div id="tablePopup">
            <div className={`popup-table-container ${drawerOpen ? 'drawer-open' : ''}`} style={{ height: height ? height : '' }}>
                {/* Table Wrapper */}
                <div className="table-wrapper">
                    <TanstackTable
                        data={rows}
                        initialColumns={columns}
                        rowCount={count}
                        showNoRowsOverlay={count == 0}
                        pageSize={pageSize}
                        currentPage={currentPage - 1}
                        updateCurrentPage={updateCurrentPage as any}
                        updatePageSize={updatePageSize}
                        showSpinner={false}
                        onRowClick={(row) => onRowClick(row)}
                        showPageSizeOptions={!drawerOpen}
                        leftPinnedColumns={leftPinnedColumns}
                        rightPinnedColumns={rightPinnedColumns}
                        selectedRow={selectedRow}
                        showBottomPadding={false}
                    />
                </div>

                {/* Drawer */}
                {drawerOpen && (
                    <div className="popup">
                       {popupContent}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TablePopup;
