import React, { useEffect, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Avatar } from '@mui/material';
import { Stack } from 'react-bootstrap';
import './styles.scss';
import { getSponsorPortalNotificationsUsers, getTeamMembers } from '../../../../scripts/apis/sponsorPortal/sponsorPortal';
import { buildInitialsForPicture } from '../../../../scripts/helpers';
import { FormLabelComponent } from '../../../../common/FormComponents/ReusableFormComponents';
import TableEmptyComponent from '../../../../common/TableEmptyComponent';
import TanstackTable from '../../../../common/TanstackTable/TanstackTable';
import { User } from '../../../../interfaces/settings/user_interface';
import { EventCheckInNotifications } from '../../../Events/interfaces/event-check-in-notifications.interface';
import { EventRegistrant } from '../../../Events/interfaces/event-registrant_interface';
import { AudienceUser } from '../../../Audience/interfaces';

interface DeliveryNotificationProps 
{
    checkInNotifications: EventCheckInNotifications | undefined;
    userIds: string[];
    sponsorPortalRegistrants: EventRegistrant[] | AudienceUser[];
    setSponsorPortalRegistrants: React.Dispatch<React.SetStateAction<EventRegistrant[] |  AudienceUser[]>>;
    setUserIds: React.Dispatch<React.SetStateAction<string[]>>;
    viewMode?: 'tabs' | 'stepper';
}

const PortalDeliveryNotifications: React.FC<DeliveryNotificationProps> = ({
    checkInNotifications,
    sponsorPortalRegistrants,
    setSponsorPortalRegistrants,
    userIds,
    setUserIds,
    viewMode
}) => 
{
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);

    const columnHelper = createColumnHelper<User>();

    const userFilterColumns = [
        {
            header: 'Name',
            id: 'firstName',
            meta: {
                type: 'string',
            },
        },
        {
            header: 'Last Name',
            id: 'lastName',
            meta: {
                type: 'string',
            },
        },
        {
            header: 'Email',
            id: 'email',
            meta: {
                type: 'string',
            },
        },
    ];
    const userNotificationColoumns = [
        columnHelper.accessor((row) => row?.additionalInfo?.image, {
            cell: ({ getValue, row }) => {
                const profilePicture = row.original?.additionalInfo && row.original?.additionalInfo.image ? row.original?.additionalInfo.image : '';
                return (profilePicture !== '' ? <Avatar sx={{
                    width: '32px', height: '32px', borderRadius: '20px' 
                }} src={profilePicture}></Avatar> : <Avatar className="empty-user-avatar">
                    <p>{buildInitialsForPicture(row.original.email, row.original?.firstName, row.original?.lastName)}</p>
                </Avatar>)
            },
            header: '',
            id: 'photo',
            size: 100,
            enableSorting: false,
        }),
        columnHelper.accessor('firstName', {
            cell: ({ row, getValue }) => (
                <Stack>
                    <p className="bolded-cellContent">{row.original.firstName ? row.original.firstName : '-'} {row.original.lastName ? row.original.lastName : ''}</p>
                    {<p className='cellContent'>{row.original.email}</p>}
                </Stack>
            ),
            header: 'Name',
            size: 380,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'firstName',
        }),

    ];

    const [allColumns, setAllColumns] = useState(userNotificationColoumns);


    const fetchData = async () => 
    {
        try 
        {
            setShowSpinner(true);
            if(checkInNotifications && viewMode === 'tabs')
            {
                if(checkInNotifications && checkInNotifications.id)
                {
                    const data = await getSponsorPortalNotificationsUsers(checkInNotifications?.id?.toString());
                    setSponsorPortalRegistrants(data);
                }
            }
            else
            {
                const data = await getTeamMembers();
                setSponsorPortalRegistrants(data);
            }

        }
        catch (error) 
        {
            console.error('Fetching data failed:', error);
        }
        finally 
        {
            setShowSpinner(false);
            setRefresh(false);
        } 
    };

    useEffect(() => 
    {
        fetchData();
    }, []);

     useEffect((): void => 
    {
        if(refresh) 
        {
            fetchData();
        }
    }, [refresh]);

    useEffect(() => 
    {
        setUserIds(checkInNotifications?.userIds ? checkInNotifications.userIds : []);
    }, [checkInNotifications]);


    return (
        <div id='PortalDeliveryNotifications'>

            {isEmpty ? (
                <TableEmptyComponent
                    emptyImg={''}
                    infoText={'No User Members Found'}
                    subInfoText={'Add some users to your organisation'}
                />
            ) : (
                <>
                    <FormLabelComponent label={'Notification To'} required={true} />
                    <div className={checkInNotifications && viewMode == 'tabs' ? 'update-table-container-wrapper' : 'table-container-wrapper'}>
                      <TanstackTable
                          checkboxSelection={true}
                          selectedRows={userIds}
                          setSelectedRows={setUserIds}
                          initialColumns={allColumns}
                          data={sponsorPortalRegistrants}
                          rowCount={sponsorPortalRegistrants.length}
                          showSpinner={showSpinner}
                          hidePagination
                          showNoRowsOverlay={sponsorPortalRegistrants.length == 0}
                          rightPinnedColumns={['actions']}
                          showBottomPadding={false}
                        //   height='calc(100vh - 360px)'
                      />
                    </div>
                </>
            )}
        </div>
    );
};

export default PortalDeliveryNotifications;