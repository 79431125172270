import { CustomButton } from "../../../common/FormComponents/Buttons";
import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";
import CalendarDescriptionEditor from "./CalendarDescriptionEditor";
import { FormControlLabel } from "@mui/material";
import { CalendarInvitation } from "../../../pages/Events/enum";
import { CustomSwitch } from "../../../common/StyledComponents/Switch.styled";
import useEventOverviewData from "../../../hooks/eventOverviewData";
import { useParams } from "react-router-dom";

import './styles.scss';

const EventCalendarDescription: React.FC = (): React.ReactElement =>
{
    const {eventId} = useParams();

    const [eventDetails, , , , , , setRefreshEventData] = useEventOverviewData({
        eventId: Number(eventId)
    });

    const openDrawer = () =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Calendar Invitation',
            component: <CalendarDescriptionEditor setRefreshEventData={setRefreshEventData} eventDetails={eventDetails} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    }

    return (
        <div id="eventCalendarDescription">
            {/* calendar description */}
            <div className="calender-description-container">
                <div className="title-description">
                    <h3 className="title">{'Calendar Invitation'}</h3>
                    <p className="description">{'This will enable Google calendar invites to the confirmed registrants.'}</p>
                </div>

                <div className="approval-toggle-container">
                    <FormControlLabel
                        checked={eventDetails?.calendarInvitation === CalendarInvitation.ENABLED}
                        control={<CustomSwitch onChange={openDrawer} />}
                        label={""}
                    />
                </div>
            </div>
        </div>
    )
}

export default EventCalendarDescription;