import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getRegistrationForms, registrationFormsCount } from '../../../../scripts/apis/registrationForm';
import _ from 'lodash';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import SelectRegistrationForm from '../../../../components/Events/EventRegistrations/SelectRegistrationForm';
import './styles.scss';
import CardComponent from '../../../../components/Events/Registrations/CardComponent';
import { getFormEmbedCodeSnippet } from '../../../../scripts/apis/events';
import { RegistrationFormType } from '../../../Registration/enum/custom-field.enum';
import { EventAdditionalForms } from '../../interfaces';
import { RegistrationForm } from '../../../Registration/interfaces/registration-form_interface';


const RegistrationFormsSection: React.FC<{eventId: number; registrationFormId: string; additionalForms?: EventAdditionalForms}> = ({eventId, registrationFormId, additionalForms}): React.JSX.Element => 
{
    const [formName, setFormName] = useState({
        registrationForm: '',
        npsForm: ''
    });
    const [codeSnippet, setCodeSnippet] = useState('');
    const [registrationForms, setRegistrationForms] = useState<RegistrationForm[]>([]);

    useEffect((): void => 
    {
        const fetchData = async (): Promise<void> => 
        {
            const count = await registrationFormsCount();
            const forms = await getRegistrationForms(count, 0, undefined, `${RegistrationFormType.REGISTRATION_FORM},${RegistrationFormType.NPS}`);
            const code = await getFormEmbedCodeSnippet(eventId);
            setCodeSnippet(code);
            setRegistrationForms([...forms]);
            if (forms) 
            {
                const defaultRegistrationForm = _.find(forms, function (elem): boolean 
                {
                    if (registrationFormId !== '') 
                    {
                        return elem?.id.toString() === registrationFormId;
                    }
                    else 
                    {
                        return false;
                    }
                });

                const defaultNPSForm = _.find(forms, function (elem): boolean
                {
                    if (additionalForms?.npsFormId !== '') 
                    {
                        return elem?.id.toString() === additionalForms?.npsFormId;
                    }
                    else 
                    {
                        return false;
                    }
                });

                setFormName({
                    registrationForm: defaultRegistrationForm && defaultRegistrationForm.name ? defaultRegistrationForm.name : '',
                    npsForm: defaultNPSForm && defaultNPSForm?.name ? defaultNPSForm?.name : ''
                });
                // setFormDescription({
                //     registrationForm: defaultRegistrationForm?.description || '',
                //     npsForm: defaultNPSForm?.description || ''
                // });
            }
        };
        fetchData();
    }, []);

    const handleEditRegistrationFormDetail = (type?: RegistrationFormType): void => 
    {
        const forms = () =>
        {
            switch(type)
            {
                case RegistrationFormType.REGISTRATION_FORM:
                    return registrationForms?.filter((form) => form?.type === RegistrationFormType.REGISTRATION_FORM);
                case RegistrationFormType.NPS:
                    return registrationForms?.filter((form) => form?.type === RegistrationFormType.NPS);
                default:
                    return [];
            }
        }
        const selectedFormType = () =>
        {
            switch(type)
            {
                case RegistrationFormType.REGISTRATION_FORM:
                    return <SelectRegistrationForm formName={formName?.registrationForm} regForms={forms()} eventId={String(eventId)} registrationFormId={registrationFormId} codeSnippet={codeSnippet} />;
                case RegistrationFormType.NPS:
                    return <SelectRegistrationForm formName={formName?.npsForm} regForms={forms()} eventId={String(eventId)} npsFormId={additionalForms?.npsFormId} additionalForms={additionalForms} codeSnippet={codeSnippet} />;
                default:
                    return '';
            }
        }

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Registration Forms',
            hideCloseButton: true,
            component: selectedFormType(),
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '630px !important'
        });
    };

    return (
        <Box id="registrationFormsSection">
            <Grid container spacing={2} className="reg-forms-section-container">
                <Grid item xl={4} lg={4} md={4} sm={4} className="selected-form-container">
                    <Typography className="registration-forms-label">
                        {'Registration Form'}
                    </Typography>
                    <CardComponent header={formName?.registrationForm || 'Select Form'} onClick={() => handleEditRegistrationFormDetail(RegistrationFormType.REGISTRATION_FORM)} cardMinHeight='90px' />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} className="selected-form-container">
                    <Typography className="registration-forms-label">
                        {'NPS Form'}
                    </Typography>
                    <CardComponent header={formName?.npsForm || 'Select Form'} onClick={() => handleEditRegistrationFormDetail(RegistrationFormType.NPS)} cardMinHeight='90px' />
                </Grid>
            </Grid>
        </Box>
    );
};

export default RegistrationFormsSection;