import React, { useEffect, useState } from "react";
import { EventRegistrant } from "../../../../pages/Events/interfaces/event-registrant_interface";
import { EventGraphObject, EventTicket } from "../../../../pages/Events/interfaces";
import { getAlleventTickets, ticketsCount } from "../../../../scripts/apis/eventTickets";
import { EventRegistrantAttendedStatus, EventRegistrantNotify, EventRegistrantPaymentStatus, EventRegistrantStatus } from "../../../../pages/Events/enum/event-registrant.enum";
import { debounce, isEmpty } from "lodash";
import { EnableApprovalStatus, EnableTicketWaitlistStatus, TicketType } from "../../../../pages/Events/enum";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../../interfaces/create-events_interface";
import { FormControlComponent, FormLabelComponent, SelectComponent } from "../../../../common/FormComponents/ReusableFormComponents";
import { updateEventRegistrant } from "../../../../scripts/apis/eventRegistrants";
import toast from "react-hot-toast";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { CONTENT } from "../../../../scripts/i18n";
import CustomTooltip from "../../../../common/Tooltip";
import { BpCheckbox } from "../../../../common/CheckboxWrapper";
import DeletePopup from "../../../../common/DeletePopup";

import './styles.scss';

interface RegistrantStatusProps {
    registrantData: EventRegistrant;
    eventId: string | number;
    statusCount: EventGraphObject;
    setRegistrants: React.Dispatch<React.SetStateAction<EventRegistrant[]>>;
    setRefreshCounts: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedRegistrant: React.Dispatch<React.SetStateAction<EventRegistrant | null>>;
    handlePopupClose: () => void;
}

const RegistrantStatus: React.FC<RegistrantStatusProps> = (props): React.JSX.Element => {

    const { registrantData, eventId, statusCount, setRegistrants, setRefreshCounts, setSelectedRegistrant, handlePopupClose } = props;

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => {
        return state.events.value;
    });

    const confirmationText = `${registrantData?.firstName} has registered using a paid ticket. Changing the status to Confirmed will override the default payment status.\nDo you want to proceed?`;
    const formattedConfirmationText = confirmationText.split('\n').map((item, i): React.JSX.Element => {
        return <p className="content" key={i}>{item}<br /></p>;
    });

    const [approvalStatus, setApprovalStatus] = useState<EventRegistrantStatus>();
    const [spinner, setSpinner] = useState<boolean>(false);
    const [isAttendeeEnabled, setIsAttendeeEnabled] = useState<boolean>(false);
    const [approvalRequired, isApprovalRequired] = useState<boolean>(false);
    const [ticketData, setTicketData] = useState<EventTicket>();
    const [eventTickets, setEventTickets] = useState<EventTicket[]>([]);
    const [selectedTicket, setSelectedTicket] = useState<string | number>();
    const [resendEmailActive, setResendEmailActive] = useState<boolean>(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(false);

    const statusOptions = () => {
        const baseOptions = [
            {
                name: 'In Review',
                id: 1,
                optionChipColor: 'grey'
            },
            {
                name: 'Rejected',
                id: 3,
                optionChipColor: 'red'
            },
            {
                name: 'Confirmed',
                id: 6,
                optionChipColor: 'blue'
            }
        ];

        if (registrantData?.ticket && registrantData?.ticket?.type === TicketType.PAID) {
            baseOptions.splice(1, 0, {
                name: 'Approved',
                id: 2,
                optionChipColor: 'green'
            });
        }

        if (registrantData?.ticket && registrantData?.ticket?.enableWaitlistStatus === EnableTicketWaitlistStatus.ENABLE) {
            baseOptions.splice(3, 0, {
                name: 'Waitlist',
                id: 4,
                optionChipColor: 'yellow'
            });
        }

        return baseOptions;
    };

    const attendedStatusOptions =
        [
            {
                id: EventRegistrantAttendedStatus.YES,
                name: 'Yes',
                optionChipColor: 'green'
            },
            {
                id: EventRegistrantAttendedStatus.NO,
                name: 'No',
                optionChipColor: 'red'
            },
        ];

    const attndedStatusOptionLabels =
    {
        [EventRegistrantAttendedStatus.YES]: 'Yes',
        [EventRegistrantAttendedStatus.NO]: 'No'
    };

    const eventTicketsOptionLabel = eventTickets?.length > 0 ? eventTickets?.reduce((obj, ticket) => {
        return {
            ...obj,
            [String(ticket?.id)]: ticket.name,
        };
    }, {}) : [];

    const handleResendEmail = async (): Promise<void> => {
        try {
            setResendEmailActive(true);
            const resendEmail = await updateEventRegistrant(eventId, registrantData.id, {
                status: approvalStatus,
            });
            if (resendEmail) {
                toast.success('Email sent successfully');
                setResendEmailActive(false);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const debouncedHandleResendEmail = debounce(handleResendEmail, 150);

    const handleFormSubmit = async (values: any): Promise<void> => {
        setSpinner(true);
        try {
            let data: { status: any; notifyGuest: any; notes?: string, attendeeStatus?: EventRegistrantAttendedStatus } = {
                status: values.status,
                notifyGuest: values.notify,
            };

            if (values.notes !== '') {
                data.notes = values.notes;
            }

            if(values.attendedStatus !== registrantData?.attendeeStatus) {
                data.attendeeStatus = values.attendedStatus;
            }

            const statusUpdated = await updateEventRegistrant(registrantData?.eventId, registrantData?.id, data);
            if (statusUpdated) {
                toast.success('Registrant status updated successfully');
                setSelectedRegistrant(statusUpdated);

                if (window.location.search?.includes('status')) {
                    setRegistrants((prevRegistrants) => {
                        const updatedRegistrants = prevRegistrants.filter((registrant) => registrant.id != registrantData.id);
                        return updatedRegistrants;
                    });
                    setRefreshCounts(true);
                }
                else {
                    setRefreshCounts(true);
                    setRegistrants((prevRegistrants) => {
                        const updatedRegistrants = prevRegistrants.map((registrant) => {
                            if (registrant.id == registrantData.id) {
                                return {
                                    ...registrant,
                                    status: values.status,
                                };
                            }
                            return registrant;
                        });
                        return updatedRegistrants;
                    });
                }
            }
        }
        catch (error) {
            console.log('Error updating registrant status', error);
            toast.error((error as Error)?.message || 'Error updating registrant status');
        }
        finally {
            setSpinner(false);
        }
    };

    const fetchTickets = async () => {
        try {
            const count = await ticketsCount(eventId);
            if (count) {
                const tickets = await getAlleventTickets(count, 0, eventId);
                if (tickets && tickets.length > 0) {
                    setEventTickets(tickets);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect((): void => {
        if (registrantData && registrantData.status) {
            setApprovalStatus(registrantData.status);
        }

        fetchTickets();

        if (registrantData && registrantData?.ticket && (!(isEmpty(registrantData?.ticket)))) {

            setSelectedTicket(registrantData?.ticket && registrantData?.ticket?.id || '');
            const eventTicketData = registrantData?.ticket;
            if (eventTicketData) {
                setTicketData(eventTicketData);
            }

            if ((eventTicketData && eventTicketData.enableApproval === EnableApprovalStatus.ENABLE) || (statusCount && statusCount?.APPROVED && Number(statusCount?.APPROVED) > 0)) {
                isApprovalRequired(true);
            }

            // if (eventTicketData && eventTicketData.type === TicketType.PAID) {
            //     isPaidTicket(true);
            // }
        }
    }, [registrantData]);

    useEffect((): void => {
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), 0);

        const eventStartDate = new Date(Number(eventReduxData?.eventStartDateTime) * 1000);
        eventStartDate.setHours(eventStartDate.getHours(), eventStartDate.getMinutes(), eventStartDate.getSeconds(), 0);

        const seventyTwoHoursBeforeEvent = new Date(eventStartDate);
        seventyTwoHoursBeforeEvent.setHours(eventStartDate.getHours() - 72);

        if (currentDate.getTime() >= seventyTwoHoursBeforeEvent.getTime()) {
            setIsAttendeeEnabled(true);
        }
    }, [eventReduxData]);

    return (
        <div id="registrantStatusComponent">
            <div className="registrant-status-component">
                <Formik
                    initialValues={{
                        status: registrantData?.status,
                        attendedStatus: registrantData?.attendeeStatus,
                        eventTicketId: selectedTicket,
                        addNotes: false,
                        notes: '',
                        notify: EventRegistrantNotify.YES,
                    }}
                    enableReinitialize={true}
                    onSubmit={async (values) => {
                        handleFormSubmit(values);
                    }}
                >
                    {({ handleSubmit, handleChange, values, setFieldValue }): React.ReactElement => {
                        return (
                            <Form onSubmit={handleSubmit} noValidate>
                                <div className="registrant-status-form-container">
                                    {approvalRequired &&
                                        <div className="status-dropdown-container">
                                            <div className="status-label-container">
                                                <FormLabelComponent label='Approval status' />
                                                <CustomTooltip title="Resend Transactional Email">
                                                    <div>
                                                        <CustomButton
                                                            startIcon={<FontAwesomeIcon onClick={!resendEmailActive ? debouncedHandleResendEmail : undefined} icon={['fal', 'paper-plane']} />}
                                                            btnType="tertiary"
                                                            name=""
                                                            style={{ padding: 'unset' }}
                                                        />
                                                    </div>
                                                </CustomTooltip>
                                            </div>
                                            <div className="dropdown-notes-container">
                                                <SelectComponent
                                                    options={statusOptions()}
                                                    value={values.status}
                                                    onChange={(event) => {
                                                        if ((event.target.value as any) === EventRegistrantStatus.CONFIRMED && registrantData?.ticket?.type === TicketType.PAID) {
                                                            setShowConfirmationPopup(true);
                                                        }
                                                        else {
                                                            setFieldValue('status', event.target.value);
                                                            setApprovalStatus((event.target.value as any));
                                                        }
                                                    }}
                                                    defaultPlaceholder="Choose new status"
                                                    optionLabels={CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS}
                                                    showOptionChip
                                                />
                                                <div className="confirmation-notes-container">
                                                    <div className="registrant-status-checkbox-component">
                                                        <BpCheckbox
                                                            checked={values.notify === EventRegistrantNotify.NO || values.status === EventRegistrantStatus.APPROVED}
                                                            readOnly={values.status === EventRegistrantStatus.APPROVED}
                                                            onChange={(event) => {
                                                                setFieldValue('notify', event.target.checked ? EventRegistrantNotify.NO : EventRegistrantNotify.YES);
                                                            }}
                                                        />
                                                        <span className="label">{'Do not send guest a confirmation email'}</span>
                                                    </div>

                                                    <div className="registrant-status-checkbox-component">
                                                        <BpCheckbox
                                                            checked={values.addNotes}
                                                            onChange={() => setFieldValue('addNotes', !values.addNotes)}
                                                        />
                                                        <span className="label">{'Add Note'}</span>
                                                    </div>

                                                    {
                                                        values.addNotes &&
                                                        <div className="registrant-notes-container">
                                                            <FormControlComponent
                                                                type="textarea"
                                                                as="textarea"
                                                                rows={3}
                                                                name="notes"
                                                                placeholder="Write a brief note about the guest..."
                                                                value={values.notes}
                                                                onChange={handleChange}
                                                            />
                                                            <p className="notes-helpText">{'Any message you specified in the registration email will always be included.'}</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {isAttendeeEnabled && <div className="attended-status-dropdown-container">
                                        <FormLabelComponent label="Attended Status" />
                                        <div>
                                            <SelectComponent
                                                options={attendedStatusOptions}
                                                value={values.attendedStatus || ''}
                                                onChange={(event) => {
                                                    setFieldValue('attendedStatus', event.target.value);
                                                }}
                                                defaultPlaceholder="Choose new status"
                                                optionLabels={attndedStatusOptionLabels}
                                                showOptionChip
                                            />
                                        </div>
                                    </div>}

                                    {ticketData && (!(isEmpty(ticketData))) ?
                                        <>
                                            {
                                                (approvalStatus !== EventRegistrantStatus.CONFIRMED && (registrantData?.paymentStatus && registrantData?.paymentStatus !== EventRegistrantPaymentStatus.YET_TO_PAY)) &&
                                                <>
                                                    <div className="registrant-ticket-container">
                                                        <FormLabelComponent label='Ticket' />
                                                        <SelectComponent
                                                            options={eventTickets}
                                                            value={String(selectedTicket)}
                                                            onChange={(event) => setSelectedTicket(event.target.value)}
                                                            defaultPlaceholder='Select a ticket'
                                                            optionLabels={eventTicketsOptionLabel}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </> :
                                        null
                                    }

                                    <div className="submission-container">
                                        <CustomButton
                                            btnType="secondary"
                                            name="Cancel"
                                            onClick={handlePopupClose}
                                        />
                                        <CustomButton
                                            name="Save"
                                            btnType="primary"
                                            type="submit"
                                            disabled={spinner}
                                            loading={spinner}
                                        />
                                    </div>
                                </div>

                                {
                                    showConfirmationPopup && (
                                        <DeletePopup
                                            show={showConfirmationPopup}
                                            cancelClick={() => setShowConfirmationPopup(false)}
                                            acceptClick={() => {
                                                setFieldValue('status', EventRegistrantStatus.CONFIRMED);
                                                setApprovalStatus(EventRegistrantStatus.CONFIRMED);
                                                setShowConfirmationPopup(false);
                                            }}
                                            modalTitle="Confirmation"
                                            modalContent={formattedConfirmationText}
                                            acceptBtn="Proceed"
                                            rejectBtn="Cancel"
                                        />
                                    )
                                }
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div >
    )
};

export default RegistrantStatus;