import React, { useContext } from "react";
import CreateEventTargetList from "./Components/CreateEventTargetList";
import { EventTargetListContext } from "../../../../contexts/EventTargetList/EventTargetListContext";
import TargetListByPastEventsPage from "./Pages/TargetListByPastEventsPage";
import { EventTargetListSourceType } from "../../enum/event-target-list.enum";
import TargetListByAudiencePage from "./Pages/TargetListByAudiencePage";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { useSelector } from "react-redux";
import { EventTargetList, IEventTargetListDispatch, IEventTargetListReduxData } from "../../interfaces/event-target-list-interface";
import { creatTargetListByEventId, updateEventTargetList } from "../../../../scripts/apis/eventTargetList";
import { useDispatch } from "react-redux";
import { resetTargetList } from "../../../../redux/events/eventTargetList/targetList";

const CreateEventTargetListPage : React.FC = (): JSX.Element =>
{
    const {eventId, listId, existingTargetList, showCreate, setShowCreate } = useContext(EventTargetListContext);
    const eventTargetListReduxData = useSelector(
        (state: IEventTargetListDispatch): IEventTargetListReduxData => {
            return state.eventTargetList.value;
        }
    );
    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDrawerClose = (event: any): void =>
    {
        navigate(`/events/${eventId}/target-list`);
    }

    const source = eventTargetListReduxData?.source[0];
    const listName = eventTargetListReduxData?.name;

    const renderSourceJsx = (sourceType: EventTargetListSourceType | undefined) => {
        switch (sourceType) 
        {
            case EventTargetListSourceType.PAST_EVENT:
                return <TargetListByPastEventsPage />;
            case EventTargetListSourceType.APOLLO:
                return <div> Apollo Component</div>;
            case EventTargetListSourceType.HUBSPOT:
                return <div> HubSpot Component</div>;
            case EventTargetListSourceType.AUDIENCE:
                return <TargetListByAudiencePage />;
            default:
                return <CreateEventTargetList />;
        }
    };

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
        event.preventDefault();
        const eventId = eventTargetListReduxData.eventId;
    
        const mergedAudienceIds = Array.from(new Set([
            ...(existingTargetList?.audienceIds ?? []),
            ...(eventTargetListReduxData?.audienceIds ?? [])
        ]));
    
        const mergedTargetListAudienceIds = Array.from(new Set([
            ...(existingTargetList?.targetListAudienceIds ?? []),
            ...(eventTargetListReduxData?.targetListAudienceIds ?? [])
        ]));

        const mergedSources = Array.from(new Set([
            EventTargetListSourceType.AUDIENCE,
            ...(existingTargetList?.source ?? [])
        ]));
    
        const eventTargetList: EventTargetList = {
            name: eventTargetListReduxData.name,
            description: eventTargetListReduxData.description,
            type: eventTargetListReduxData.type,
            source: mergedSources,
            audienceIds: mergedAudienceIds,
            targetListAudienceIds: mergedTargetListAudienceIds,
        };
    
        try {
            if (listId) 
            {
                await updateEventTargetList(eventId, listId, eventTargetList);
                navigate(`/events/${eventId}/target-list/${listId}`);
            } 
            else 
            {
                const result = await creatTargetListByEventId(eventId, eventTargetList, csrfTokenData);
                navigate(`/events/${eventId}/target-list/${result?.id}`);
            }

            dispatch(resetTargetList());
        } catch (error) {
            console.error("Error submitting event target list:", error);
            throw error;
        }
    };


    return (
        <>
            {showCreate ? (
                <CreateEventTargetList />
            ) : (
                <div id="createTargetListPageWrapper">
                    <div className="header-container">
                        <CustomButton name={"Cancel"} btnType={"secondary"} onClick={handleDrawerClose} />
                        {/* In fututre if we add save button use the below button */}
                        {/* <CustomButton name={ 'Save' } btnType={ 'secondary' } style={ { border: 'unset' } } onClick={ handleDrawerClose } />  */}
                        <div className={`header-text ${listName ? "header-text-with-title" : ""}`}>
                            {listName ? listName : "List Name"}{" "}
                        </div>
                        {source === EventTargetListSourceType.AUDIENCE && (
                            <CustomButton 
                                name={'Finish'} 
                                btnType={"primary"} 
                                onClick={handleSubmit} 
                                disabled={eventTargetListReduxData?.audienceIds?.length === 0}
                            />
                        )}
                    </div>
                    {renderSourceJsx(source)}
                </div>
            )}
        </>
    );
};

export default CreateEventTargetListPage;
