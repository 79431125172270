import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserOrgRoles, UserViewType } from '../pages/Settings/enum/users.enum';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import Menu from './Menu';
import { buildInitialsForPicture } from '../scripts/helpers';
import { getUsersOrganizations, switchOrganization } from '../scripts/apis/organisation';
import { CurrentUserOrgInfo } from '../interfaces/settings/organization_interface';
import { useSelector } from 'react-redux';
import { IBillingInfo } from '../redux/billing/billing-redux_interface';
import { EventPhase } from '../pages/Events/enum';
import { logoutWithWorkOS } from '../scripts/apis/authentication';
import { LocalStorage } from '../scripts/LocalStorage';
import DeletePopup from './DeletePopup';
import { EventModulesGroup, ISidebarNavItems, ISidebarNavItemsWithDropdown, ISideNavBarProps } from '../interfaces/sideNavbar/side-nav-bar_interface';
import CustomTooltip from './Tooltip';

const drawerWidth = 240;

const SideNavBar: React.FC<ISideNavBarProps> = (props): React.JSX.Element => {

    const { selectedComponent, mainContent, showDrawer = true, subModule } = props;

    const navigate = useNavigate();

    const [openDropdowns, setOpenDropdowns] = useState<Set<string>>(new Set());

    // if you want multiple dropdowns uncomment this code
    // const toggleDropdown = (dropdownId: string) => {
    //     const newOpenDropdowns = new Set(openDropdowns);

    //     if (newOpenDropdowns.has(dropdownId)) {
    //         newOpenDropdowns.delete(dropdownId); // If the dropdown is open, close it
    //     } else {
    //         newOpenDropdowns.add(dropdownId); // If the dropdown is closed, open it
    //     }
    //     setOpenDropdowns(newOpenDropdowns);
    // };

    const toggleDropdown = (dropdownId: string) => {
        const newOpenDropdowns = new Set<string>();
    
        if (!openDropdowns.has(dropdownId)) {
            newOpenDropdowns.add(dropdownId); // Open the new dropdown
        }
        setOpenDropdowns(newOpenDropdowns);
    };

    const { 
        eventId
    } = useParams();

    const {
        userDetails,
        orgDetails
    } = useContext(UserContext);

    const csrfTokenData = useSelector((state: any) => {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    const [open, setOpen] = useState<boolean>(selectedComponent === 'events-module' || true);
    const [allOrgs, setAllOrgs] = useState<CurrentUserOrgInfo[]>([]);
    const [showLogoutPopup, setShowLogoutPopup] = useState<boolean>(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogout = async (): Promise<void> => 
    {
        try 
        {
            const loggedOut = await logoutWithWorkOS()
            if(loggedOut)
            {
                await (window as any).plugSDK.shutdown();
                // await logoutWithWorkOS();
                LocalStorage.removeAll();
                navigate('/login');
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const globalSidebarItems: ISidebarNavItems[] =
    [
        {
            title: 'Dashboard',
            icon: 'clapperboard' as IconName,
            selected: selectedComponent === 'dashboard',
            navigation: () => navigate('/dashboard')
        },
        {
            title: 'Events',
            icon: 'calendar' as IconName,
            selected: selectedComponent === 'events',
            navigation: () => navigate(userDetails && userDetails?.viewType === UserViewType.MINIMAL ? '/events?eventPhase=2' : '/events')
        },
        {
            title: 'Audience',
            icon: 'user-group' as IconName,
            selected: selectedComponent === 'audience',
            navigation: () => navigate('/audience')
        },
        {
            title: 'Speakers',
            icon: 'microphone' as IconName,
            selected: selectedComponent === 'speakers',
            navigation: () => navigate('/speakers')
        },
        ...(orgBillingInfo?.billingModules?.sponsor ? [{
            title: 'Sponsors',
            icon: 'dollar-sign' as IconName,
            selected: selectedComponent === 'sponsors',
            navigation: () => navigate('/sponsors')
        }] : []),
        ...(orgBillingInfo?.billingModules?.vendor ? [{
            title: 'Vendors',
            icon: 'hexagon' as IconName,
            selected: selectedComponent === 'vendors',
            navigation: () => navigate('/vendors')
        }] : []),
        {
            title: 'Registrations',
            icon: 'file' as IconName,
            selected: selectedComponent === 'registrations',
            navigation: () => navigate('/registrations')
        },
        {
            title: 'Budget',
            icon: 'chart-pie-simple' as IconName,
            selected: selectedComponent === 'budget',
            navigation: () => navigate('/budget')
        },
        {
            title: 'Analytics',
            icon: 'chart-simple-horizontal' as IconName,
            selected: selectedComponent === 'analytics',
            navigation: () => navigate('/analytics/audience')
        }
    ];

    const settingsModuleSidebarItems: ISidebarNavItems[] =
    [
        {
            title: 'Profile',
            selected: subModule === 'general',
            navigation: () => navigate('/settings/general')
        },
        ...(userDetails?.role === UserOrgRoles.SUPERADMIN ? [{
            title: 'Organisation',
            selected: subModule === 'organization',
            navigation: () => navigate('/settings/organization')
        }] : []),
        {
            title: 'Members',
            selected: subModule === 'users',
            navigation: () => navigate('/settings/users')
        },
        ...(orgBillingInfo?.billingModules?.integrations ? [{
            title: 'Integrations',
            selected: subModule === 'integrations',
            navigation: () => navigate('/settings/integrations/all')
        }] : []),
        {
            title: 'ICP',
            selected: subModule === 'icp',
            navigation: () => navigate('/settings/icp')
        },
        {
            title: 'Buyer Persona',
            selected: subModule === 'buyerpersona',
            navigation: () => navigate('/settings/buyer-persona')
        },
        {
            title: 'Properties',
            selected: subModule === 'customField',
            navigation: () => navigate('/settings/custom-field')
        },

    ];

    const eventModulesSidebarItems: (ISidebarNavItemsWithDropdown[] ) =
    [
        {
            title: 'Setup',
            // icon: 'grid-round-2',
            isDropdown: true,
            dropdownItems: [
                {
                    title: 'Overview',
                    selected: subModule === 'overview',
                    icon: 'grid-round-2',
                    navigation: () => navigate(`/events/${eventId}/overview`)
                },
                {
                    title: 'Registrations',
                    selected: subModule === 'registrations',
                    icon: 'ticket-simple',
                    navigation: () => navigate(`/events/${eventId}/registrations`)
                },
                {
                    title: 'Landing Page',
                    selected: subModule === 'landing-page',
                    icon: 'globe-stand',
                    navigation: () => navigate(`/events/${eventId}/landing-page`)
                }
            ]
        },
        {
            title: 'Marketing',
            // icon: 'megaphone',
            isDropdown: true,
            dropdownItems: [
                {
                    title: 'Campaign',
                    selected: subModule === 'marketing',
                    icon: 'megaphone',
                    navigation: () => navigate(`/events/${eventId}/marketing`)
                },
                // {
                //     title: 'Sequence',
                //     selected: subModule === 'sequence',
                //     icon: 'code-fork',
                //     navigation: () => navigate(`/events/${eventId}/sequence`)
                // },
                // {
                //     title: 'Social Share',
                //     selected: subModule === 'social-share',
                //     icon: 'thumbs-up',
                //     navigation: () => navigate(`/events/${eventId}/registrations`)
                // },
                {
                    title: 'Share Links',
                    selected: subModule === 'share-links',
                    icon: 'link',
                    navigation: () => navigate(`/events/${eventId}/share-links`)
                },
                {
                    title: 'Target List',
                    selected: subModule === 'targetList',
                    icon: 'bullseye-arrow',
                    navigation: () => navigate(`/events/${eventId}/target-list`)
                },
                ...(orgBillingInfo?.billingModules?.checkInApp ? [{
                    title: 'Notifications',
                    selected: subModule === 'notifications',
                    icon: 'bell-on' as IconName,
                    navigation: () => navigate(`/events/${eventId}/check-in/notifications`)
                }] : []),
                {
                    title: 'Photo Sharing',
                    selected: subModule === 'photo-sharing',
                    icon: 'image',
                    navigation: () => navigate(`/events/${eventId}/photo-sharing`)
                }
            ]
        },
        {
            title: 'Event',
            // icon: 'grid-round-2',
            isDropdown: true,
            dropdownItems: [
                {
                    title: 'Guests',
                    selected: subModule === 'registrants',
                    icon: 'square-user',
                    navigation: () => navigate(`/events/${eventId}/people/registrants`)
                },
                {
                    title: 'Agenda',
                    selected: subModule === 'agenda',
                    icon: 'calendar',
                    navigation: () => navigate(`/events/${eventId}/agenda`)
                },
                {
                    title: 'Speakers',
                    selected: subModule === 'speakers',
                    icon: 'microphone',
                    navigation: () => navigate(`/events/${eventId}/people/speakers`)
                },
                {
                    title: 'Sponsors',
                    selected: subModule === 'sponsors',
                    icon: 'circle-dollar',
                    navigation: () => navigate(`/events/${eventId}/people/sponsors`)
                },
                {
                    title: 'Vendors',
                    selected: subModule === 'vendors',
                    icon: 'diamond',
                    navigation: () => navigate(`/events/${eventId}/people/vendors`)
                },

            ]
        },
        {
            title: 'Onsite',
            // icon: 'grid-round-2',
            isDropdown: true,
            dropdownItems: [
                // {
                //     title: 'Floor Plans',
                //     selected: subModule === 'floor-plans',
                //     icon: 'tent-double-peak',
                //     navigation: () => navigate(`/events/${eventId}/overview`)
                // },
                // {
                //     title: 'CheckIn Area',
                //     selected: subModule === 'checkin',
                //     icon: 'table-picnic',
                //     navigation: () => navigate(`/events/${eventId}/check-in`)
                // },
                ...(orgBillingInfo?.billingModules?.checkInApp ? [{
                    title: 'Devices',
                    selected: subModule === 'devices',
                    icon: 'mobile' as IconName,
                    navigation: () => navigate(`/events/${eventId}/check-in/devices`)
                }] : []),
                // need to add these components
                // {
                //     title: 'Badges',
                //     selected: subModule === 'badges',
                //     icon: 'id-badge',
                //     navigation: () => navigate(`/events/${eventId}/badges`)
                // },
                // {
                //     title: 'Signatures',
                //     selected: subModule === 'signatures',
                //     icon: 'signature',
                //     navigation: () => navigate(`/events/${eventId}/signatures`)
                // },
                // {
                //     title: 'Digital signage',
                //     selected: subModule === 'digital-signage',
                //     icon: 'tv',
                //     navigation: () => navigate(`/events/${eventId}/digital-signage`)
                // }
            ]
        },
        {
            title: 'Budget',
            selected: subModule === 'budget',
            // icon: 'chart-pie-simple',
            navigation: () => navigate(`/events/${eventId}/budget`)
        },

        {
            title: 'Analytics',
            selected: subModule === 'analytics',
            // icon: 'chart-simple-horizontal',
            navigation: () => navigate(`/events/${eventId}/analytics/revenue`)
        },
        {
            title: 'Settings',
            // icon: 'gear-complex',
            selected: subModule === 'settings',
            navigation: () => navigate(`/events/${eventId}/settings`)
        }
    ];

    // no more footer in the current design so commenting it as of now
    // const eventsModuleFooterItems: ISidebarNavItems[] =
    // [
    //     {
    //         title: 'Settings',
    //         icon: 'gear-complex',
    //         selected: subModule === 'settings',
    //         navigation: () => navigate(`/events/${eventId}/settings`)
    //     }
    // ];

    const MenuButton = (): React.JSX.Element => {
        return (
            <>
                {
                    open ?
                        <div className="account-menu-button-open">
                            <div className="org-image-name">
                                {
                                    orgDetails?.additionalInfo && orgDetails?.additionalInfo?.image ?
                                        <img
                                            src={orgDetails?.additionalInfo?.image}
                                            alt="org-logo"
                                            className="org-logo"
                                        />
                                        :
                                        <div className="empty-org-logo">
                                            {orgDetails?.name?.charAt(0)}
                                        </div>
                                }
                                <h3 className="org-name">{orgDetails?.name}</h3>
                            </div>
                            <FontAwesomeIcon icon={['fal', 'chevron-down']} />
                        </div> :
                        <div className="account-menu-button-collapsed">
                            {
                                orgDetails?.additionalInfo && orgDetails?.additionalInfo?.image ?
                                    <img
                                        src={orgDetails?.additionalInfo?.image}
                                        alt="org-logo"
                                        className="org-logo"
                                    />
                                    :
                                    <div className="empty-org-logo">
                                        {orgDetails?.name?.charAt(0)}
                                    </div>
                            }
                        </div>
                }
            </>
        )
    };

    const SwitchOrgComponent = (): React.JSX.Element => {
        const handleOrgChange = async (orgId: string): Promise<void> => {
            if (orgDetails?.id === orgId) return;
            try {
                const isOrganizationSwitched: Boolean = await switchOrganization(orgId, csrfTokenData);
                if (isOrganizationSwitched) {
                    navigate('/dashboard');
                    window.location.reload();
                }
            }
            catch (error) {
                console.log(error);
            }
        };

        return (
            <div className="organisations-container">
                {
                    allOrgs?.map((item) => {
                        return (
                            <div key={item.id} className="org-menu-item">
                                <div className={`item ${item?.id === orgDetails?.id && 'active'}`} onClick={() => handleOrgChange(item.id)}>
                                    <div className="org-logo-name">
                                        {
                                            item?.additionalInfo && item?.additionalInfo?.image ?
                                                <img
                                                    src={item?.additionalInfo?.image}
                                                    alt="org-img"
                                                    className="org-img"
                                                />
                                                :
                                                <div className="empty-org-img">
                                                    {item?.orgName?.charAt(0)}
                                                </div>
                                        }
                                        <h3 className="org-name">{item?.orgName}</h3>
                                    </div>
                                    {
                                        item?.id === orgDetails?.id && <FontAwesomeIcon className="check-icon" icon={['fal', 'check']} />
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    };

    const AccountMenu = (): React.JSX.Element => {
        return (
            <div className="account-menu">
                <div className="user-details" onClick={() => navigate('/settings/general')}>
                    {
                        userDetails?.additionalInfo && userDetails?.additionalInfo?.image ?
                            <img
                                src={userDetails?.additionalInfo?.image}
                                alt="user-profile-image"
                                className="user-profile-image"
                            />
                            :
                            <div className="empty-user-profile-image">
                                {buildInitialsForPicture(userDetails?.email, userDetails?.firstName, userDetails?.lastName)}
                            </div>
                    }
                    <div className="user-name-email-container">
                        <h3 className="name">{`${userDetails?.firstName} ${userDetails?.lastName || ''}`}</h3>
                        <p className="email">{userDetails?.email}</p>
                    </div>
                </div>
                <div className="divider" />
                <div className="account-menu-item-container" onClick={() => navigate('/settings/users')}>
                    <div className="account-menu-item">
                        <div className="item">
                            <FontAwesomeIcon className="menu-item-icon" icon={['fal', 'user-plus']} />
                            <h3 className="menu-item-text">{'Invite Members'}</h3>
                        </div>
                    </div>
                </div>
                {userDetails?.role === UserOrgRoles.SUPERADMIN && orgBillingInfo?.billingModules?.integrations &&
                    <div className="account-menu-item-container" onClick={() => navigate('/settings/integrations/all')}>
                        <div className="account-menu-item">
                            <div className="item">
                                <FontAwesomeIcon className="menu-item-icon" icon={['fal', 'gear-complex']} />
                                <h3 className="menu-item-text">{'Settings'}</h3>
                            </div>
                        </div>
                    </div>
                }
                <div className="divider" />

                <div className="organisation-title-container">
                    <h3 className="title">{'Orgs'}</h3>
                </div>

                <SwitchOrgComponent />

                <div className="divider" />

                <div className="account-menu-item-container">
                    <div className="account-menu-item" onClick={() => setShowLogoutPopup(true)}>
                        <div className="item">
                            <FontAwesomeIcon className="menu-item-icon" icon={['fal', 'sign-out']} />
                            <h3 className="menu-item-text">{'Logout'}</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const GlobalSidebarContent = (): React.JSX.Element => {
        return (
            <>
                <div className="primary-side-nav-bar">
                    <div className="side-nav-content">
                        <div className="nav-header" style={{ borderBottom: open ? '1px solid #EAECF0' : '', padding: open ? '10px 20px' : '' }}>
                            <Menu menuButton={<MenuButton />}>
                                <AccountMenu />
                            </Menu>
                        </div>

                        <nav className={`nav-items-container ${!open && 'collapsed'}`}>
                            {
                                globalSidebarItems?.map((item, index) => {
                                    return (
                                        <CustomTooltip title={!open && item?.title} key={index} placement="right">
                                            <button className={`nav-button ${item.selected ? 'selected' : ''}`} onClick={item.navigation}>
                                                <FontAwesomeIcon className="nav-icon" icon={['fal', item.icon as IconName]} />
                                                {open && <h3 className="nav-title">{item.title}</h3>}
                                            </button>
                                        </CustomTooltip>
                                    )
                                })
                            }
                        </nav>
                    </div>
                    <div className="side-nav-footer">
                        <button className={`toggle-button`} onClick={() => open ? handleDrawerClose() : handleDrawerOpen()}>
                            <FontAwesomeIcon icon={['fal', 'sidebar-flip']} />
                        </button>
                    </div>
                </div>
            </>
        )
    };

    const SettingsModuleSidebarContent = (): React.JSX.Element => {
        return (
            <div className={`primary-side-nav-bar ${open ? 'expanded' : 'collapsed'}`} style={{ width: open ? `${drawerWidth}px` : '64px' }}>
                <div className="side-nav-content">
                    <div className="nav-header" style={{ borderBottom: open ? '1px solid #EAECF0' : '', padding: '10px 20px' }}>
                        <Menu menuButton={<MenuButton />}>
                            <AccountMenu />
                        </Menu>
                    </div>
                    
                    <button className="nav-back-btn" onClick={() => navigate('/dashboard')}>
                        <div className="back-button-content">
                            <FontAwesomeIcon className="back-icon" icon={['fal', 'arrow-left']} />
                            <h3 className="button-text">{'Back to Workspace'}</h3>
                        </div>
                    </button>

                    <nav className={`nav-items-container ${!open && 'collapsed'}`}>
                        {
                            settingsModuleSidebarItems?.map((item) => {
                                return (
                                    <button className={`nav-button ${item.selected ? 'selected' : ''}`} key={item.title} onClick={item.navigation}>
                                        {open && <h3 className="nav-title">{item.title}</h3>}
                                    </button>
                                )
                            })
                        }
                    </nav>
                </div>
            </div>
        )
    };

    const EventsModuleSidebarContent = (): React.JSX.Element =>
    {
        return (
            <div className={`primary-side-nav-bar ${open ? 'expanded' : 'collapsed'}`} style={{ width: open ? `${drawerWidth}px` : '64px' }}>
                <div className="side-nav-content">
                    <div className="nav-header" style={{ borderBottom: open ? '1px solid #EAECF0' : '', padding: '10px 20px' }}>
                        <Menu menuButton={<MenuButton />}>
                            <AccountMenu />
                        </Menu>
                    </div>

                    <button className="nav-back-btn" onClick={() => navigate(userDetails?.viewType === UserViewType.MINIMAL ? `/events?eventPhase=${EventPhase.UPCOMING}` : `/events`)}>
                        <div className="back-button-content">
                            <FontAwesomeIcon className="back-icon" icon={['fal', 'chevron-left']} />
                            <h3 className="button-text">{'Back to Events'}</h3>
                        </div>
                    </button>

                    <div className="events-side-nav-content-wrapper">
                        <nav className={`nav-items-container ${!open && 'collapsed'}`}>
                            {
                                eventModulesSidebarItems?.map((item) => {
                                    if (item.isDropdown) {
                                        return (
                                            <div key={item.title} className="nav-dropdown">
                                                <button
                                                    className={`nav-button nav-dropdown-toggle ${item.selected ? 'selected' : ''}`}
                                                    key={item.title}
                                                    onClick={() => toggleDropdown(item.title)}
                                                >
                                                    {/* <FontAwesomeIcon className="nav-icon" icon={['fal', item.icon as IconName]} /> */}
                                                    {open && <h3 className="nav-title">{item.title}</h3>}

                                                    <FontAwesomeIcon
                                                        className={`dropdown-icon ${openDropdowns.has(item.title) ? 'rotate' : ''}`}
                                                        icon={['fal', 'chevron-right']}
                                                    />
                                                </button>
                                                
                                                {/* Check if the dropdown is open */}
                                                {openDropdowns.has(item.title) && open && (
                                                    <div className="dropdown-content">
                                                        {item.dropdownItems?.map((dropdownItem) => (
                                                            <button
                                                                key={dropdownItem.title}
                                                                className={`nav-button ${dropdownItem.selected ? 'selected' : ''}`}
                                                                onClick={dropdownItem.navigation}
                                                            >
                                                                <FontAwesomeIcon className="nav-icon" icon={['fal', dropdownItem.icon as IconName]} />
                                                                {open && <h3 className="nav-title dropdown-options-text">{dropdownItem.title}</h3>}
                                                            </button>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }

                                    return (
                                        <button className={`nav-button ${item.selected ? 'selected' : ''}`} key={item.title} onClick={item.navigation}>
                                            {/* <FontAwesomeIcon className="nav-icon" icon={['fal', item.icon as IconName]} /> */}
                                            {open && <h3 className="nav-title">{item.title}</h3>}
                                        </button>
                                    );
                                })
                            }
                        </nav>

                        {/* <div className="events-side-nav-footer">
                            {
                                eventsModuleFooterItems?.map((item, index) =>
                                {
                                    return (
                                        <button className={`toggle-button ${item.selected ? 'selected' : ''}`} onClick={item.navigation} key={index}>
                                            <FontAwesomeIcon icon={['fal', item.icon as IconName]} />
                                            {open && <h3 className="nav-title">{'Settings'}</h3>}
                                        </button>
                                    )
                                })
                            }
                        </div> */}

                    </div>
                </div>
            </div>
        );
    };

    const renderSidebarContent = (): React.JSX.Element => {
        return (
            <>
                {
                    (selectedComponent === 'settings') ? <SettingsModuleSidebarContent /> : 
                    (selectedComponent === 'events-module') ? <EventsModuleSidebarContent /> : 
                    <GlobalSidebarContent />
                }

                {
                    showLogoutPopup &&
                    <DeletePopup 
                        acceptBtn='Logout' 
                        acceptClick={() => handleLogout()} 
                        cancelClick={() => { 
                            setShowLogoutPopup(false);
                        }} 
                        modalContent={`Are you sure you want to logout ?`}
                        modalTitle='Logout'
                        show={showLogoutPopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='sign-out'
                    />
                }
            </>
        )
    };

    const fetchAllAvailableOrganisationsForUser = async (): Promise<void> => {
        try {
            const allOrganisations = await getUsersOrganizations();
            if (allOrganisations) {
                setAllOrgs(allOrganisations);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchAllAvailableOrganisationsForUser();
    }, []);

    useEffect(() =>
    {
        if(selectedComponent === 'events-module' || selectedComponent === 'settings') 
        {
            setOpen(true);
        }
    }, [selectedComponent]);

    // devrev initialization
    useEffect(() => 
    {
        (window as any).plugSDK.init({
            app_id: import.meta.env.VITE_DEVREV_APP_ID,
            spacing: {
            // side: "50px",
            // bottom: "50px"
            }
        });

        (window as any).plugSDK.onEvent((payload: any) => 
        {
            if (payload.type === 'ON_PLUG_WIDGET_READY') 
            {
                (window as any).plugSDK.initSearchAgent();
            }
        });
    }, []);

    useEffect(() => 
    {
        const newOpenDropdowns = new Set(openDropdowns);
    
        const dropdownMapping : EventModulesGroup= 
        {
            'overview': 'Setup',
            'registrations': 'Setup',
            'landing-page': 'Setup',
            'marketing': 'Marketing',
            'share-links': 'Marketing',
            'targetList': 'Marketing',
            'notifications': 'Marketing',
            'photo-sharing': 'Marketing',
            'registrants': 'Event',
            'agenda': 'Event',
            'speakers': 'Event',
            'sponsors': 'Event',
            'vendors': 'Event',
            'devices': 'Onsite'
        };
    
        if (subModule && dropdownMapping[subModule]) 
        {
            newOpenDropdowns.add(dropdownMapping[subModule]);
        }
    
        setOpenDropdowns(newOpenDropdowns);
    }, [subModule]);

    return (
        <div id="sideNavBar">
            {/* Drawer */}
            {showDrawer && 
            <div
                style={{
                    width: open ? `${drawerWidth}px` : `${64}px`,
                }}
                className={`side-bar-container ${import.meta.env.VITE_NODE_ENV === 'demo' ? 'demo' : ''}`}
            >
                {renderSidebarContent()}
            </div>}

            {/* Main Content */}
            <main
                style={{
                    marginLeft: showDrawer ? open ? `${drawerWidth}px` : '64px' : '',
                    transition: '0.3s ease',
                    flexGrow: 1,
                    width: showDrawer ? open ? 'calc(100% - 240px)' : 'calc(100% - 64px)' : '100%',
                    height: '100%',
                }}
            >
                {mainContent}
            </main>

            {
                showLogoutPopup &&
                <DeletePopup 
                    acceptBtn='Logout' 
                    acceptClick={() => handleLogout()} 
                    cancelClick={() => { 
                        setShowLogoutPopup(false);
                    }} 
                    modalContent={`Are you sure you want to logout ?`}
                    modalTitle='Logout'
                    show={showLogoutPopup}
                    rejectBtn='Cancel'
                    modalHeaderIcon='sign-out'
                />
            }
        </div>
    );
};

export default SideNavBar;
