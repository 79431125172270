import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Box, Grid } from '@mui/material';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { CONTENT } from '../../scripts/i18n';
import _ from 'lodash';
import './styles.scss';
import Sponsor from '../../components/Sponsors/Sponsor';
import { resetSponsors } from '../../redux/sponsors/Sponsors';
// eslint-disable-next-line import/named
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TableEmptyComponent from '../../common/TableEmptyComponent';
import DataImport from '../../common/DataImport';
import { SponsorsPageContext } from '../../contexts/SponsorsPageContext';
import { getSponsorActivityLogs, getSponsorById } from '../../scripts/apis/sponsors';
import SponsorsGraph from './SponsorsGraph';
import { ActionsDropdown } from '../Events/EventBudget/EventBudgetDataGrid';
import sponsorEmptyImg from '../../assets/icons/empty-state/sponsorEmpty.svg';
import { createColumnHelper } from '@tanstack/react-table';
import { ISponsor } from './interfaces';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import { generateSponsorsColumnsForTanstackTable } from './sponsorColumn.helper';
import { CustomButton } from '../../common/FormComponents/Buttons';
import DeletePopup from '../../common/DeletePopup';
import HeaderBar from '../../common/Headerbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddSponsor from '../Events/EventDetails/People/EventSponsors/AddSponsor';
import toast from 'react-hot-toast';
import CardComponent from '../../components/Events/Registrations/CardComponent';
import { useSelector } from 'react-redux';
import { IEventReduxValues, IEventsDispatch } from '../../components/Events/interfaces/create-events_interface';
import { EventStatus } from '../Events/enum';
import EventsCardHeaderComponent from '../../components/Events/EventComponents/EventsCardHeaderComponent';
import TanstackToolbarWithServerFilter from '../../common/TanstackTable/TanstackToolbarWithServerFilter';
import { MinimalViewComponent } from '../../common/MinimalView/MinimalViewComponent';
import { UserContext } from '../../contexts/UserContext';
import { UserViewType } from '../Settings/enum/users.enum';
import CustomSpinner from '../../common/CustomSpinner';
import ColumnVisibilityDropdown from '../../common/TanstackTable/ColumnVisibilityDropdown';
import CustomTooltip from '../../common/Tooltip';

import mailIcon from '../../assets/icons/envelope-light.svg';
import twitterIcon from '../../assets/icons/twitter-icon.svg';
import linkedInIcon from '../../assets/icons/linkedin-solid.svg';
import { TableActions } from '../../common/TableActions';
import Breadcrumbs from '../../common/Breadcrumbs';
import TablePopupDetails from '../../common/TablePopup/TablePopupDetails';
import TablePopup from '../../common/TablePopup/TablePopup';
import { buildInitialsForPicture } from '../../scripts/helpers';
import SponsorAbout from '../../components/Sponsors/SponsorAbout';
import ActivityLogComponent from '../../common/ActivityLog/ActivityLogComponent';
import { sponsorsActivityLog } from '../../scripts/activityLogGenerator/activitylog-generator';
import { ActivityLogResponse } from '../../interfaces/activity-log_interface';
import { EnrichmentComponent } from '../Audience/ViewAudienceInfo';
import EventsHeaderBar from '../../common/EventsHeaderBar';

const SponsorsPage: React.FC<{ eventId?: string | number }> = (props): React.JSX.Element => {
    const { eventId } = props;
    const { sponsorId } = useParams();
    const [activityLogs, setActivityLogs] = useState<ActivityLogResponse[]>([]);
    

    const {
        userDetails
    } = useContext(UserContext);

    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => {
        return state.events.value;
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [chartVisibility, setChartVisibility] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [accordionExpansion, setAccordionExpansion] = useState<string | boolean>('enrichment');

    const {
        isEmpty,
        sponsorDataCount,
        sponsors,
        deleteSponsorsFromTable,
        setRefresh,
        pageSize,
        updateCurrentPage,
        updatePageSize,
        showSpinner,
        currentPage,
        isChartReady,
        sponsorsIcpData,
        sponsorFundingData,
        sponsorRevenueData,
        trendData,
        rows,
        selectedSponsor,
        setSelectedSponsor,
        showDeletePopup,
        setShowDeletePopup,
        isTableView,
        setIsTableView,
        cardView,
        orgLink,
        setCurrentUrl,
        dynamicColumns
    } = useContext(SponsorsPageContext);

    const leftNodes = 
    [
        eventReduxData?.status === EventStatus.PUBLISHED && [
            <CustomTooltip title={'Sponsor Portal Link'}>
                <div>
                    <CustomButton 
                        name=''
                        startIcon={<FontAwesomeIcon icon={['fal', 'copy']} />}
                        onClick={() => {
                            navigator.clipboard.writeText(
                                `${APP_CONSTANTS.APP_DOMAIN}/${orgLink}/${eventReduxData?.link}/portal`
                            );
                            toast.success('Link copied');
                        }}
                        btnType='tertiary'
                    />
                </div>
            </CustomTooltip>,
        ],
        <CustomTooltip title={'Table View'}>
            <div>
                <CustomButton
                    name=''
                    startIcon={<FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />}
                    btnType='tertiary'
                    onClick={() => navigate('/events/' + eventId + '/people/sponsors')}
                />
            </div>
        </CustomTooltip>,
    ];
    

    const columnHelper = createColumnHelper<ISponsor>();

    const generateInitialColumns = () => [
        columnHelper.accessor('logo', {
            cell: ({ row }) => {
                const sponsorLogoImg = row?.original?.logo && row?.original?.logo !== null ? row?.original?.logo : '';
                const emptyImgSrc = row.original?.name.charAt(0).toUpperCase();
                return sponsorLogoImg !== '' ? <Avatar className="sponsor-logo" src={sponsorLogoImg}></Avatar> : <Avatar className="empty-logo-img"><p>{emptyImgSrc}</p></Avatar>;
            },
            header: 'Logo',
            id: 'logo',
            size: 40,
            enableSorting: false,
        }),
        columnHelper.accessor('name', {
            cell: ({ getValue }) => <p className="bolded-cellContent">{getValue()}</p>,
            header: 'Name',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name',
        }),
        columnHelper.accessor('pocName', {
            cell: ({ row, getValue }) => (
                <div>
                    <p className={userDetails?.viewType === UserViewType.NORMAL ? "cellContent" : "bolded-cellContent"}>{getValue()}</p>
                    {userDetails?.viewType === UserViewType.MINIMAL && <p className="cellContent">{row.original.pocEmail}</p>}
                </div>
            ),
            header: userDetails?.viewType === UserViewType.NORMAL ? 'POC Name' : 'POC Details',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'pocName',
        }),
        ...(userDetails?.viewType === UserViewType.NORMAL ? [columnHelper.accessor('pocEmail', {
            cell: ({ getValue }) => <p className='cellContent'>{getValue()}</p>,
            header: 'POC Email',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'pocEmail',
        })] : []),
        columnHelper.accessor('websiteLink', {
            cell: ({ getValue }) => <p className='cellContent'>{getValue()}</p>,
            header: 'Website',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'websiteLink',
        }),
        columnHelper.accessor('actions' as any, {
            cell: ({ row }) => {
                const editClick = (): void => {
                    if (eventId) {
                        editEventSponsor(row.original, undefined, true, false);
                    }
                    else {
                        handleViewEdit(row.original);
                    }
                };
                const deleteClick = (): void => {
                    setSelectedSponsor(row.original);
                    setShowDeletePopup(true);
                };
                return (
                    <TableActions 
                        actions={[
                            {
                                title: 'Edit',
                                onClick: editClick
                            },
                            {
                                title: 'Delete',
                                onClick: deleteClick
                            }
                        ]}
                    />
                );
            },
            header: '',
            id: 'actions',
            size: 40,
            enableSorting: false,
        }),
    ];

    const sponsorColumns = [...generateInitialColumns(), ...generateSponsorsColumnsForTanstackTable()].flat();
    const [allColumns, setAllColumns] = useState(sponsorColumns);

    const filterColumns = [
        {
            header: 'Name',
            id: 'name',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'POC Name',
            id: 'pocName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Email',
            id: 'pocEmail',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Website Link',
            id: 'websiteLink',
            meta: {
                type: 'string'
            }
        },
    ];

    const handlePopupClose = (): void =>
    {
        setSelectedSponsor(null);
        setDrawerOpen(false);
    };

    const handleMinimalViewRowClick = async (row: ISponsor): Promise<void> =>
    {
        setDrawerOpen(true);
        setSelectedSponsor(row);
        await fetchSponsorActivityLogs(row);
    };

    const fetchSponsorActivityLogs = async (row: ISponsor): Promise<void> =>
    {
        try 
        {
            if(row)
            {
                const activityLogs = await getSponsorActivityLogs(row.id);
                if(activityLogs)
                {
                    setActivityLogs(activityLogs);
                }   
            }
            
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const handleAccoridon = (propertyId: string) => 
    {
        return (event: React.SyntheticEvent, newExpanded: boolean): void => 
        {
            setAccordionExpansion(newExpanded ? propertyId : false);
        };
    };

    const tablePopupTabData = 
    [
        {
            title: 'About',
            data: <SponsorAbout sponsorData={selectedSponsor} setRefresh={setRefresh} handlePopupClose={handlePopupClose} />
        },
        {
            title: 'Details',
            data: (selectedSponsor?.clearbitData ? (
                <EnrichmentComponent fromRegistrant={false} accordionExpansion={accordionExpansion} handleAccoridon={handleAccoridon} clearbitData={selectedSponsor?.clearbitData} />
            ) : (
                <div className='log-empty'>No enrichment data</div>
            ))
        },
        {
            title: 'Activity',
            data: <ActivityLogComponent activityLog={sponsorsActivityLog(activityLogs, selectedSponsor)} />
        }
    ];

    const openDrawer = (): void => {
        dispatch(resetSponsors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SPONSORS_PAGE.SIDE_DRAWER.HEADING.CREATE,
            hideCloseButton: true,
            component: < Sponsor setRefresh={setRefresh} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const createSponsor = (): void => {
        dispatch(resetSponsors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Create Sponsor',
            event: 'create-sponsor',
            hideCloseButton: true,
            component: <Sponsor data-id={eventId} addEventSponsor={true} eventSponsor={true} setRefresh={setRefresh} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const addSponsor = (componentHeading?: string, select?: boolean): void => {
        dispatch(resetSponsors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: componentHeading || 'Select Sponsor',
            event: 'add-sponsor',
            hideCloseButton: true,
            // component: <AddSponsor isTableView={userDetails?.viewType === UserViewType.NORMAL && isTableView} select={select} data-id={eventId} setRefresh={setRefresh} />,
            component: <AddSponsor isTableView={false} select={select} data-id={eventId} setRefresh={setRefresh} />,

        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const handleViewEdit = (sponsorInfo: ISponsor, routeFromId?: boolean): void => {
        dispatch(resetSponsors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SPONSORS_PAGE.SIDE_DRAWER.HEADING.EDIT,
            hideCloseButton: true,
            component: <Sponsor sponsor-data={sponsorInfo} is-editable={true} setRefresh={setRefresh} routeFromId={routeFromId} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const editEventSponsor = (sponsorInfo: ISponsor, routeFromEventSponsor?: boolean, select?: boolean, editTier?: boolean): void => {
        dispatch(resetSponsors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Edit Sponsor Tier',
            hideCloseButton: true,
            component: <AddSponsor isTableView={isTableView} select={select} editTier={editTier} sponsor-data={sponsorInfo} data-id={eventId} setRefresh={setRefresh} routeFromEventSponsor={routeFromEventSponsor} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const isDemoInstance = import.meta.env.VITE_NODE_ENV === 'demo';
    const trendDataForMinimalView = [
        {
            title: 'Sponsors',
            value: `${Number(sponsorDataCount) !== 0 ? sponsorDataCount : '-'}`
        },
        {
            title: 'Customers',
            value: isDemoInstance && trendData?.customer ? `${trendData?.customer}` : '-',
        },
        {
            title: 'Prospects',
            value: isDemoInstance && trendData?.prospect ? `${trendData?.prospect}` : '-',
        },
        {
            title: 'Prospects in ICP',
            value: isDemoInstance && trendData?.icp ? `${trendData?.icp}` : '-',
        }
    ]

    const renderHeader = () => {
        const headerClassName = userDetails?.viewType === UserViewType.NORMAL ? 'event-sponsors-header' : 'event-sponsors-header-minimal';
    
        return (
            <EventsHeaderBar
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        Sponsors
                        {eventId && eventReduxData?.status === EventStatus.PUBLISHED && (
                            <CustomTooltip title="Sponsor Portal Link">
                                 <div>
                                    <CustomButton
                                        name=""
                                        startIcon={<FontAwesomeIcon icon={['fal', 'copy']} />}
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                `${APP_CONSTANTS.APP_DOMAIN}/${orgLink}/${eventReduxData?.link}/portal`
                                            );
                                            toast.success('Link copied');
                                        }}
                                        btnType="tertiary"
                                    />
                                </div>
                            </CustomTooltip>
                        )}
                    </div>
                }
                buttons={[
                    <CustomButton
                        key={1}
                        name={chartVisibility ? 'Close Charts' : 'Open Charts'}
                        onClick={() => {
                            setChartVisibility(!chartVisibility);
                        }}
                        btnType="secondary"
                    />,
                    isTableView ? (
                        <CustomTooltip key={2} title='Grid View'>
                            <div>
                                <CustomButton 
                                    startIcon={<FontAwesomeIcon icon={['fal', 'grid']} />} 
                                    name='' 
                                    btnType='tertiary_outline' 
                                    onClick={() => setIsTableView && setIsTableView(false)} 
                                />
                            </div>
                        </CustomTooltip>
                    ) : (
                        <CustomTooltip key={2} title='Table View'>
                            <div>
                                <CustomButton 
                                    startIcon={<FontAwesomeIcon icon={['fal', 'square-list']} />} 
                                    name='' 
                                    btnType='tertiary_outline' 
                                    onClick={() => setIsTableView && setIsTableView(true)} 
                                />
                            </div>
                        </CustomTooltip>
                    )
                ]}
            />
        );
    };

    const renderMinimalCardViewForSponsorsPage = (): React.JSX.Element => {

        const title = eventId ? renderHeader() : 'Sponsors';
        const description = eventId ? '' : 'Manage your Sponsors and their account permissions here.';
        const titlebarStyle = eventId && { padding: '0', borderBottom: '1px solid #F2F4F7' } as React.CSSProperties;

        return (
            <div id={eventId ? "eventSponsorsPageMinimalView" : "sponsorsPageMinimalView"} style={{ padding: '0 0 16px 0' }}>
                <MinimalViewComponent
                    title={title}
                    description={description}
                    titleBarStyle={titlebarStyle}
                    titleBarActions={eventId ? [] : [<CustomButton
                        key={1}
                        name={chartVisibility ? 'Close Charts' : 'Open Charts'}
                        btnType='secondary'
                        onClick={() => setChartVisibility(!chartVisibility)}
                    />]}
                    // titleBarActions={[
                    //     eventReduxData?.status === EventStatus.PUBLISHED ? (
                    //         <CustomTooltip key={0} title={'Sponsor Portal Link'}>
                    //             <FontAwesomeIcon
                    //                 cursor={'pointer'}
                    //                 onClick={() => {
                    //                     navigator.clipboard.writeText(`${APP_CONSTANTS.APP_DOMAIN}/${orgLink}/${eventReduxData?.link}/portal`);
                    //                     toast.success('Link copied');
                    //                 }}
                    //                 icon={['fal', 'copy']}
                    //             />
                    //         </CustomTooltip>
                    //     ) : null,
                    //     <CustomButton
                    //         key={1}
                    //         name={chartVisibility ? 'Close Charts' : 'Open Charts'}
                    //         btnType='secondary'
                    //         onClick={() => setChartVisibility(!chartVisibility)}
                    //     />,
                    // ]?.filter(Boolean)}
                    showCharts={chartVisibility}
                    trendData={trendDataForMinimalView}
                    tabData={[
                        {
                            tabName: 'All',
                            count: sponsorDataCount,
                            navigation: () => { },
                            selectedTab: true
                        }
                    ]}
                    actions={[
                        !isEmpty && <TanstackToolbarWithServerFilter
                            // key={1}
                            columns={sponsorColumns}
                            filterColumns={filterColumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}

                        />,
                        <CustomTooltip key={3} title="Import CSV">
                            {
                                eventId ? dynamicColumns && dynamicColumns?.length > 0 ?
                                    <DataImport dynamicColumns={dynamicColumns} licenseKey={import.meta.env.VITE_SPONSOR_SHEET_ID} eventId={eventId} iconButton={true} />
                                    :
                                    <DataImport licenseKey={import.meta.env.VITE_SPONSOR_SHEET_ID} iconButton={true} />
                                    : 
                                    <DataImport licenseKey={import.meta.env.VITE_SPONSOR_SHEET_ID} iconButton={true} />}
                        </CustomTooltip>,
                        <CustomButton
                            key={2}
                            name=''
                            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType='primary'
                            onClick={() => {
                                if (eventId) {
                                    addSponsor('Select or Create Sponsor', true)
                                }
                                else {
                                    openDrawer()
                                }
                            }}
                        />
                    ]}
                    component=
                    {
                        <Grid container spacing={2}>
                        {rows?.map((sponsor: ISponsor, index: number) => {

                            const profilePicture = sponsor?.logo ? sponsor?.logo : '';
                            const sponsorWebsite = sponsor?.websiteLink ? sponsor?.websiteLink : '';
                            const nameSplitted = sponsor?.name.split(' ');
                            let firstNameInitial: string;
                            let lastNameInitial: string;
                            let emptyImagesrc: string;
                            let sponsorSocialsArr: { icon: string, link: string }[] = [];

                            if (nameSplitted.length >= 2) {
                                firstNameInitial = nameSplitted[0][0];
                                const lastName = nameSplitted.slice(1).join(' ');
                                lastNameInitial = lastName[0];
                                emptyImagesrc = firstNameInitial + lastNameInitial;
                            }
                            else {
                                emptyImagesrc = sponsor?.name.charAt(0);
                            }

                            sponsorSocialsArr?.unshift({
                                icon: mailIcon,
                                link: sponsor?.pocEmail
                            });

                            if (sponsor?.social?.twitter) {
                                sponsorSocialsArr.push({
                                    icon: twitterIcon,
                                    link: sponsor?.social?.twitter
                                });
                            }
                            if (sponsor?.social?.linkedIn) {
                                sponsorSocialsArr.push({
                                    icon: linkedInIcon,
                                    link: sponsor?.social?.linkedIn
                                });
                            }


                            // 628,131:     const fetchOrgLogo = props?.org?.social && props?.org?.social?.website && props?.org?.social?.website !== null ? `https://www.google.com/s2/favicons?sz=64&domain_url=${props.org?.social?.website}` : null;
                            // const sponsorImage = (<div>{profilePicture !== '' ? <Avatar sx={{ border: '1px solid var(--Component-colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10))', borderRadius: '8px' }} className="sponsor-avatar" src={profilePicture}></Avatar> : <Avatar sx={{ border: '1px solid var(--Component-colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10))', borderRadius: '8px', height: '40px !important', width: '40px !important' }} className="empty-logo-img" src={emptyImagesrc}><p>{emptyImagesrc.toUpperCase()}</p></Avatar>}</div>);
                            const sponsorImage = (<div>{sponsorWebsite ? <Avatar sx={{ borderRadius: '8px' }} className="sponsor-card-avatar" src={`https://www.google.com/s2/favicons?sz=64&domain_url=${sponsorWebsite}`}></Avatar> : <Avatar sx={{ border: '1px solid var(--Component-colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10))', borderRadius: '8px', height: '40px !important', width: '40px !important' }} className="empty-logo-img" src={emptyImagesrc}><p>{emptyImagesrc.toUpperCase()}</p></Avatar>}</div>);
                            const sponsorName = (<div className="sponsor-name-container"><p>{sponsor?.name}</p><FontAwesomeIcon icon={['fal', 'arrow-up-right']} onClick={(event) => { event?.stopPropagation(); window.open(sponsor?.websiteLink, '_blank'); }} /></div>);
                            const sponsorPocEmail = (<div className="sponsor-poc-container"><p className="name">{sponsor?.pocName}</p><FontAwesomeIcon icon={['fal', 'envelope']} /></div>);

                            const sponsorSocials = (<div className="sponsor-socials-icons">{sponsorSocialsArr.map((social, index) => {
                                return <Box component={'img'} key={index} src={social.icon} height={'1rem'} width={'1rem'} onClick={(event) => {
                                    if (social.link !== sponsor?.pocEmail) {
                                        window.open(social.link, '_blank');
                                        event.stopPropagation();
                                    }
                                    else {
                                        navigator.clipboard.writeText(`${sponsor?.pocEmail}`);
                                        toast.success('Email copied to clipboard');
                                        event.stopPropagation();
                                    }
                                }} />
                            })}</div>);

                            const footerContent = (
                                <div className="sponsor-social-contents">

                                    <p>{sponsor?.pocName}</p>

                                    {sponsorSocials}
                                </div>
                            );

                            return (
                                <Grid key={index} item xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <CardComponent
                                        header={sponsorImage}
                                        cardMinHeight='144px'
                                        contentHeading={sponsorName}
                                        contentHeadingBold
                                        gapNotRequired
                                        footerLeft={footerContent}
                                        onClick={() => { editEventSponsor(sponsor, undefined, true, true) }}
                                    />
                                </Grid>
                            )
                        })}

                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <CardComponent
                                emptyText='+ Add Sponsor'
                                onClick={() => addSponsor('Select or Create Sponsor')}
                                emptyCardHeight='125px'
                                cardMinHeight='157px'

                            />
                        </Grid>
                    </Grid>
                    }
                    componentType='table'
                />
            </div>
        )
    };

    const renderMinimalViewForSponsorsPage = (): React.JSX.Element => {

        const title = eventId ? renderHeader() : 'Sponsors';
        const description = eventId ? '' : 'Manage your Sponsors and their account permissions here.';
        const titlebarStyle = eventId && { padding: '0', borderBottom: !chartVisibility && '1px solid #F2F4F7' } as React.CSSProperties;

        return (
            <div id={eventId ? "eventSponsorsPageMinimalView" : "sponsorsPageMinimalView"} style={{ padding: '0 0 16px 0' }}>
                <MinimalViewComponent
                    title={title}
                    description={description}
                    titleBarStyle={titlebarStyle}
                    titleBarActions={eventId ? [] : [<CustomButton
                        key={1}
                        name={chartVisibility ? 'Close Charts' : 'Open Charts'}
                        btnType='secondary'
                        onClick={() => setChartVisibility(!chartVisibility)}
                    />]}
                    // titleBarActions={[
                    //     eventReduxData?.status === EventStatus.PUBLISHED ? (
                    //         <CustomTooltip key={0} title={'Sponsor Portal Link'}>
                    //             <FontAwesomeIcon
                    //                 cursor={'pointer'}
                    //                 onClick={() => {
                    //                     navigator.clipboard.writeText(`${APP_CONSTANTS.APP_DOMAIN}/${orgLink}/${eventReduxData?.link}/portal`);
                    //                     toast.success('Link copied');
                    //                 }}
                    //                 icon={['fal', 'copy']}
                    //             />
                    //         </CustomTooltip>
                    //     ) : null,
                    //     <CustomButton
                    //         key={1}
                    //         name={chartVisibility ? 'Close Charts' : 'Open Charts'}
                    //         btnType='secondary'
                    //         onClick={() => setChartVisibility(!chartVisibility)}
                    //     />,
                    // ]?.filter(Boolean)}
                    showCharts={chartVisibility}
                    trendData={trendDataForMinimalView}
                    tabData={[
                        {
                            tabName: 'All',
                            count: sponsorDataCount,
                            navigation: () => { },
                            selectedTab: true
                        }
                    ]}
                    actions={[
                        !isEmpty && <TanstackToolbarWithServerFilter
                            // key={1}
                            columns={sponsorColumns}
                            filterColumns={filterColumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}

                        />,
                        !isEmpty && <ColumnVisibilityDropdown
                            // key={2}
                            columns={sponsorColumns}
                            setColumns={setAllColumns}
                            showIconButton={true}
                        />,
                        <CustomTooltip key={3} title="Import CSV">
                            {
                                eventId ? dynamicColumns && dynamicColumns?.length > 0 ?
                                    <DataImport dynamicColumns={dynamicColumns} licenseKey={import.meta.env.VITE_SPONSOR_SHEET_ID} eventId={eventId} iconButton={true} />
                                    :
                                    <DataImport licenseKey={import.meta.env.VITE_SPONSOR_SHEET_ID} iconButton={true} />
                                    : 
                                    <DataImport licenseKey={import.meta.env.VITE_SPONSOR_SHEET_ID} iconButton={true} />}
                        </CustomTooltip>,
                        <CustomButton
                            key={2}
                            name=''
                            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType='primary'
                            onClick={() => {
                                if (eventId) {
                                    addSponsor('Select or Create Sponsor', true)
                                }
                                else {
                                    openDrawer()
                                }
                            }}
                        />
                    ]}
                    component=
                    {
                        isEmpty ?
                            <TableEmptyComponent
                                emptyImg={sponsorEmptyImg}
                                openDrawer={openDrawer}
                                infoText={'No Sponsor'}
                                subInfoText={'Add your first Sponsor'}
                            />
                            :
                            <TablePopup
                                rows={sponsors}
                                columns={drawerOpen ? allColumns?.filter((item, index) => index < 3) : allColumns}
                                count={sponsorDataCount}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                updatePageSize={updatePageSize}
                                updateCurrentPage={updateCurrentPage as any}
                                // height={chartVisibility ?
                                //     eventId ? `calc(100vh - 322px)` : `calc(100vh - 238px)` :
                                //     eventId ? `calc(100vh - 215px)` : `calc(100vh - 130px)`
                                // }
                                drawerOpen={drawerOpen}
                                onRowClick={(row) => handleMinimalViewRowClick(row)}
                                selectedRow={selectedSponsor}
                                rightPinnedColumns={['actions']}
                                popupContent={
                                    <TablePopupDetails
                                        onPopupClose={handlePopupClose}
                                        title={`${selectedSponsor?.name} `}
                                        subTitle={`${selectedSponsor?.clearbitData ? selectedSponsor?.clearbitData?.company : ''}`}
                                        email={`${selectedSponsor?.pocEmail}`}
                                        imageSrc={`${selectedSponsor?.clearbitData ? selectedSponsor?.clearbitData?.companyLogo : ''}`}
                                        altImage={`${buildInitialsForPicture(selectedSponsor?.pocEmail, selectedSponsor?.name)}`}
                                        icp={selectedSponsor?.icp}
                                        buyerPersona={selectedSponsor?.buyerPersona}
                                        tabData={tablePopupTabData}
                                    />
                                }
                            />
                    }
                    componentType='table'
                />
            </div>
        )
    };

    const renderNormalViewForSponsorsPage = (): React.JSX.Element => {
        return (
            <div style={{ overflow: (isTableView && eventId) ? 'hidden' : '' }} id={eventId ? "eventSponsorsPage" : "sponsorsPage"}>
                {(isTableView || !cardView) &&
                    <>
                        {
                            eventId ? 
                            renderHeader() :
                            <HeaderBar
                                title={eventId ? <p><FontAwesomeIcon onClick={() => navigate('/events/' + eventId + '/overview')} cursor="pointer" style={{ marginRight: '8px' }} icon={['fal', 'arrow-left']} /> Sponsors</p> : <p>Sponsors</p>}
                                buttons={
                                    [
                                        <CustomButton name={chartVisibility ? 'Close Charts' : 'Open Charts'} btnType='secondary' onClick={() => setChartVisibility(!chartVisibility)} />,
                                        // <CustomButton btnType='primary' name='Add Sponsor' onClick={openDrawer} />
                                    ]
                                }
                            />
                        }
                        {chartVisibility && <SponsorsGraph trendData={trendData} sponsorsCount={sponsorDataCount} isChartReady={isChartReady} sponsorsIcpData={sponsorsIcpData} sponsorFundingData={sponsorFundingData} sponsorRevenueData={sponsorRevenueData} />}
                        <div
                            style={{
                                height: chartVisibility ? '60%' : eventId ? `${APP_CONSTANTS.EVENTS_MODULE_STANDARD_COMPONENT_HEIGHT}` : `${APP_CONSTANTS.GLOBAL_MODULES_COMPONENT_HEIGHT}`,
                                flex: 1
                            }}
                        >
                            {isEmpty ?
                                (
                                    <TableEmptyComponent
                                        emptyImg={sponsorEmptyImg}
                                        openDrawer={openDrawer}
                                        infoText={'No Sponsor'}
                                        subInfoText={'Add your first Sponsor'}
                                        buttonName={'Add Sponsor'}
                                    />
                                ) :
                                (
                                    <div className="h-100">
                                        {/* <SponsorsPageToolbar 
                                    count={sponsorDataCount} 
                                    rows={sponsors} 
                                    setRows={setRows} 
                                    columns={sponsorColumns} 
                                    setColumns={setAllColumns}
                                /> */}
                                        <TanstackToolbarWithServerFilter
                                            columns={sponsorColumns}
                                            setColumns={setAllColumns}
                                            handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                            setCurrentUrl={setCurrentUrl}
                                            tabs={[{
                                                tabName: 'All',
                                                count: sponsorDataCount,
                                                navigation: () => { },
                                                selectedTab: true
                                            }]}
                                            filterColumns={filterColumns}
                                            rightActionButtons={[
                                                <CustomTooltip title="Import CSV">
                                                <div>
                                                    {eventId ? (
                                                        dynamicColumns && dynamicColumns.length > 0 && (
                                                            <DataImport
                                                                dynamicColumns={dynamicColumns}
                                                                licenseKey={import.meta.env.VITE_SPONSOR_SHEET_ID}
                                                                eventId={eventId}
                                                                iconButton={true}
                                                            />
                                                        )
                                                    ) : (
                                                        <DataImport licenseKey={import.meta.env.VITE_SPONSOR_SHEET_ID} iconButton={true} />
                                                    )}
                                                </div>
                                            </CustomTooltip>
                                            ]}
                                            rightCornerButton={
                                                <CustomButton 
                                                    name=''
                                                    startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                                                    btnType='primary' 
                                                    onClick={() => {
                                                        if (eventId) 
                                                        {
                                                            addSponsor('Select or Create Sponsor');
                                                        }
                                                        else 
                                                        {
                                                            openDrawer();
                                                        }
                                                    }}
                                                />
                                            }
                                            // rightActionButtons={[
                                            //     <CustomTooltip key={3} title="Import CSV">
                                            //         <div>
                                            //             <DataImport iconButton={true} licenseKey={import.meta.env.VITE_SPONSOR_SHEET_ID} eventId={eventId ? eventId : null} />
                                            //         </div>
                                            //     </CustomTooltip>,
                                            // ]}
                                        />
                                        <TanstackTable
                                            data={rows}
                                            initialColumns={allColumns}
                                            pageSize={pageSize}
                                            updatePageSize={updatePageSize}
                                            updateCurrentPage={updateCurrentPage as any}
                                            currentPage={currentPage - 1}
                                            showSpinner={showSpinner}
                                            rowCount={sponsorDataCount}
                                            showNoRowsOverlay={sponsorDataCount==0}
                                            // height={chartVisibility ?
                                            //     eventId ? `calc(100% - 120px)` : `calc(100% - 120px)` :
                                            //     eventId ? `calc(100% - 184px)` : `calc(100% - 124px)`
                                            // }
                                            rightPinnedColumns={['actions']}
                                            onRowClick={(row): void => {
                                                if (eventId) {
                                                    editEventSponsor(row, undefined, true, false);
                                                }
                                                else {
                                                    handleViewEdit(row);
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                        </div>
                    </>
                }
            </div>
        )
    };

    // const renderCardViewForEventSponsors = (): React.JSX.Element => { 
    //     return (
    //         <div id="eventSponsorsPage">
    //             <div className="sponsors-container">
    //                 <EventsCardHeaderComponent
    //                     heading='Sponsors'
    //                     count={sponsorDataCount}
    //                     leftNodes={leftNodes}
    //                 />

    //                 <Grid container spacing={2}>
    //                     {rows?.map((sponsor: ISponsor, index: number) => {

    //                         const profilePicture = sponsor?.logo ? sponsor?.logo : '';
    //                         const sponsorWebsite = sponsor?.websiteLink ? sponsor?.websiteLink : '';
    //                         const nameSplitted = sponsor?.name.split(' ');
    //                         let firstNameInitial: string;
    //                         let lastNameInitial: string;
    //                         let emptyImagesrc: string;
    //                         let sponsorSocialsArr: { icon: string, link: string }[] = [];

    //                         if (nameSplitted.length >= 2) {
    //                             firstNameInitial = nameSplitted[0][0];
    //                             const lastName = nameSplitted.slice(1).join(' ');
    //                             lastNameInitial = lastName[0];
    //                             emptyImagesrc = firstNameInitial + lastNameInitial;
    //                         }
    //                         else {
    //                             emptyImagesrc = sponsor?.name.charAt(0);
    //                         }

    //                         sponsorSocialsArr?.unshift({
    //                             icon: mailIcon,
    //                             link: sponsor?.pocEmail
    //                         });

    //                         if (sponsor?.social?.twitter) {
    //                             sponsorSocialsArr.push({
    //                                 icon: twitterIcon,
    //                                 link: sponsor?.social?.twitter
    //                             });
    //                         }
    //                         if (sponsor?.social?.linkedIn) {
    //                             sponsorSocialsArr.push({
    //                                 icon: linkedInIcon,
    //                                 link: sponsor?.social?.linkedIn
    //                             });
    //                         }


    //                         // 628,131:     const fetchOrgLogo = props?.org?.social && props?.org?.social?.website && props?.org?.social?.website !== null ? `https://www.google.com/s2/favicons?sz=64&domain_url=${props.org?.social?.website}` : null;
    //                         // const sponsorImage = (<div>{profilePicture !== '' ? <Avatar sx={{ border: '1px solid var(--Component-colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10))', borderRadius: '8px' }} className="sponsor-avatar" src={profilePicture}></Avatar> : <Avatar sx={{ border: '1px solid var(--Component-colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10))', borderRadius: '8px', height: '40px !important', width: '40px !important' }} className="empty-logo-img" src={emptyImagesrc}><p>{emptyImagesrc.toUpperCase()}</p></Avatar>}</div>);
    //                         const sponsorImage = (<div>{sponsorWebsite ? <Avatar sx={{ borderRadius: '8px' }} className="sponsor-card-avatar" src={`https://www.google.com/s2/favicons?sz=64&domain_url=${sponsorWebsite}`}></Avatar> : <Avatar sx={{ border: '1px solid var(--Component-colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10))', borderRadius: '8px', height: '40px !important', width: '40px !important' }} className="empty-logo-img" src={emptyImagesrc}><p>{emptyImagesrc.toUpperCase()}</p></Avatar>}</div>);
    //                         const sponsorName = (<div className="sponsor-name-container"><p>{sponsor?.name}</p><FontAwesomeIcon icon={['fal', 'arrow-up-right']} onClick={(event) => { event?.stopPropagation(); window.open(sponsor?.websiteLink, '_blank'); }} /></div>);
    //                         const sponsorPocEmail = (<div className="sponsor-poc-container"><p className="name">{sponsor?.pocName}</p><FontAwesomeIcon icon={['fal', 'envelope']} /></div>);

    //                         const sponsorSocials = (<div className="sponsor-socials-icons">{sponsorSocialsArr.map((social, index) => {
    //                             return <Box component={'img'} key={index} src={social.icon} height={'1rem'} width={'1rem'} onClick={(event) => {
    //                                 if (social.link !== sponsor?.pocEmail) {
    //                                     window.open(social.link, '_blank');
    //                                     event.stopPropagation();
    //                                 }
    //                                 else {
    //                                     navigator.clipboard.writeText(`${sponsor?.pocEmail}`);
    //                                     toast.success('Email copied to clipboard');
    //                                     event.stopPropagation();
    //                                 }
    //                             }} />
    //                         })}</div>);

    //                         const footerContent = (
    //                             <div className="sponsor-social-contents">

    //                                 <p>{sponsor?.pocName}</p>

    //                                 {sponsorSocials}
    //                             </div>
    //                         );

    //                         return (
    //                             <Grid key={index} item xl={4} lg={4} md={6} sm={12} xs={12}>
    //                                 <CardComponent
    //                                     header={sponsorImage}
    //                                     cardMinHeight='144px'
    //                                     contentHeading={sponsorName}
    //                                     contentHeadingBold
    //                                     gapNotRequired
    //                                     footerLeft={footerContent}
    //                                     onClick={() => { editEventSponsor(sponsor, undefined, true, true) }}
    //                                 />
    //                             </Grid>
    //                         )
    //                     })}

    //                     <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
    //                         <CardComponent
    //                             emptyText='+ Add Sponsor'
    //                             onClick={() => addSponsor('Select or Create Sponsor')}
    //                             emptyCardHeight='116px'
    //                         />
    //                     </Grid>
    //                 </Grid>
    //             </div>
    //         </div>
    //     )
    // };

    useEffect((): void => {
        const fetchDataFromRoute = async (): Promise<void> => {
            if (sponsors && sponsors.length && sponsorId) {
                try {
                    const sponsorDataFromId = await getSponsorById(Number(sponsorId));
                    if (sponsorDataFromId) {
                        handleViewEdit(sponsorDataFromId, true);
                    }
                    else if (!sponsorDataFromId) {
                        toast.error('The sponsor you\'re trying to access doesn\'t exist');
                        navigate('/sponsors');
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
        };
        fetchDataFromRoute();
    }, [sponsors]);

    useEffect((): void => {
        if (!pageSize) {
            updatePageSize(25);
        }
    }, [pageSize]);

    return (

        <>

            {
                showSpinner ? <CustomSpinner width="100%" height="100lvh" />
                    : (
                        <>
                            {
                                userDetails?.viewType === UserViewType.MINIMAL && (
                                    (!isTableView && eventId) ? renderMinimalCardViewForSponsorsPage() : renderMinimalViewForSponsorsPage()
                                )
                            }

                            {
                                userDetails?.viewType === UserViewType.NORMAL && renderNormalViewForSponsorsPage()
                            }
                        </>
                    )
            }


            {
                showDeletePopup &&
                <DeletePopup
                    acceptBtn='Delete'
                    acceptClick={() => deleteSponsorsFromTable(selectedSponsor as ISponsor)}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                        setSelectedSponsor(null);
                    }}
                    modalContent={`Are you sure you want to delete ${selectedSponsor?.name}?`}
                    modalTitle='Delete Sponsor'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                    modalHeaderIcon='trash'
                />
            }
        </>
    );
};
export default SponsorsPage;

// export const generateSponsorColumns = (data, setAnchorEl, anchorEl): GridColDef[] => {

//     const excludedFields = ['id', 'status', 'orgId', 'created', 'modified', 'clearbitEnrichedId', 'hubspotContactId', 'hubspotContactData', 'clearbitData', 'clearbitData.clearbitId', 'clearbitId'];

//     const columns = [];

//     data.forEach((item: {
//         field?: string;
//         hide?: boolean;
//         headerName?: string;
//         disableColumnMenu?: boolean;
//     }): void => {
//         if (item.field === 'icp' || item.field === 'buyerPersona') {

//             columns.push({
//                 field: item.field,
//                 hide: item.hide,
//                 headerName: _.startCase(item.headerName),
//                 renderCell: (params): React.JSX.Element => {
//                     const icpChipColor = item.field === 'icp' ? 'blue' : 'pink';

//                     const handlePopoverOpen = (event, params): void => {
//                         setAnchorEl({
//                             id: params.id, field: params.field
//                         });
//                     };
//                     const handlePopoverClose = (): void => {
//                         setAnchorEl(false);
//                     };

//                     return (
//                         <Box
//                             onMouseEnter={(event): void => {
//                                 handlePopoverOpen(event, params);
//                             }}
//                             onMouseLeave={handlePopoverClose}
//                         >
//                             {anchorEl?.id === params?.id && anchorEl?.field === params?.field ? (
//                                 <Box className="popupBlock">
//                                     <Box className="tooltip-chip-cont">
//                                         {params.value.map((tag, index): React.JSX.Element => {
//                                             return (
//                                                 <MuiChip chipColor={icpChipColor} key={index} label={tag} />
//                                             );
//                                         })}
//                                     </Box>
//                                 </Box>
//                             ) : (
//                                 <Box onMouseLeave={handlePopoverClose}>
//                                     {params.value.length > 0 ? (
//                                         params.value.length > 1 ? (
//                                             <Box className="table-chip-cell">
//                                                 <MuiChip chipColor={icpChipColor} label={params.value[0]} />
//                                                 <Typography className="table-chip-count">
//                                                     +{params.value.length - 1}
//                                                 </Typography>
//                                             </Box>
//                                         ) : (
//                                             <MuiChip chipColor={icpChipColor} label={params.value[0]} />
//                                         )
//                                     ) : null}
//                                 </Box>
//                             )}
//                         </Box>
//                     );
//                 },
//                 valueGetter: (params): string => {
//                     return params.row[item.field];
//                 },
//                 disableColumnMenu: item.disableColumnMenu,
//                 flex: 1,
//                 width: 105,
//                 minWidth: 105
//             });
//         }
//         else {
//             columns.push({
//                 field: item.field,
//                 hide: item.hide,
//                 headerName: _.startCase(item.headerName),
//                 renderCell: (params): React.JSX.Element => {
//                     return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{params.value}</Typography>}><Box className="MuiDataGrid-cellContent">{params.value}</Box></Tooltip>;
//                 },
//                 valueGetter: (params): string => {
//                     if (params.row?.clearbitData) {
//                         if (item.field.split('.').length === 2) {
//                             const subKey = item.field.split('.');
//                             return params.row?.clearbitData?.[subKey[0]]?.[subKey[1]];
//                         }
//                         return params.row?.clearbitData[item.field];
//                     }
//                     else {
//                         return params.row[item.field];
//                     }
//                 },
//                 disableColumnMenu: item.disableColumnMenu,
//                 flex: 1,
//                 minWidth: 140
//             });
//         }
//     });

//     const filteredColumns = columns.filter((column): boolean => {
//         return !excludedFields.includes(column.field);
//     });
//     return filteredColumns;
// };