import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Stack, Typography, Box } from '@mui/material';
// eslint-disable-next-line import/named
import { useDispatch, useSelector } from 'react-redux';
import { addRegistrationForms } from '../../redux/registrationsForms/RegistrationForms';
import { createRegistrationForm, updateRegistrationForm } from '../../scripts/apis/registrationForm';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CONTENT } from '../../scripts/i18n';
import './styles.scss';
import { CloseIconComponent, FormControlComponent, FormLabelComponent, SelectComponent } from '../../common/FormComponents/ReusableFormComponents';
import _ from 'lodash';
import { CustomButton } from '../../common/FormComponents/Buttons';
import toast from 'react-hot-toast';
import { RegistrationFormType } from './enum/custom-field.enum';

const RegistrationFormTemplate = (props): React.JSX.Element => 
{

    const registrationFormData = props['registrationForm-data'];
    const setRefresh = props.setRefresh;

    interface registrationForms {
        registrationForms: {
            value: IRegistrationFormReduxValues
        }
    }

    interface IRegistrationFormReduxValues {
        name: string,
        description: string,
        link: string,
    }

    const registrationFormReduxData = useSelector((state: registrationForms): IRegistrationFormReduxValues => 
    {
        return state.registrationForms.value;
    });

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [spinner, setSpinner] = useState(false);
    const [descriptionLength, setDescriptionLength] = useState(0);

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);

        if (props?.routeFromId) 
        {
            navigate('/registrations');
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        description: Yup.string().test('isValidDescription', 'Maximum words Exceeded', (value: string): boolean => 
        {
            if (value && value.trim().length > 0) 
            {
                const words = value.trim().split(/\s+/);
                const wordsLength = words.length;
                setDescriptionLength(wordsLength);
                if (wordsLength > 250) 
                {
                    return false;
                }
                else 
                {
                    return true;
                }
            }
            else 
            {
                setDescriptionLength(0);
                return true;
            }
        }),
        type: Yup.string().required('Type is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            name: registrationFormData?.name ? registrationFormData?.name : '',
            description: registrationFormData?.description ? registrationFormData?.description : '',
            type: registrationFormData?.type ? registrationFormData.type : 1,
        },
        onSubmit: async (): Promise<void> => 
        {
            setSpinner(true);

            const registrationFormDetails = {
                name: formik.values.name,
                description: formik.values.description,
                type: formik.values.type,
            };

            if (registrationFormData) 
            {
                try 
                {
                    const registrationFormUpdated = await updateRegistrationForm(registrationFormData.id, registrationFormDetails);
                    if (registrationFormUpdated) 
                    {
                        setRefresh(true);
                        handleDrawerClose();
                        setSpinner(false);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setSpinner(false);
                    toast.error(_.startCase((error as Error).message.toLowerCase()))
                }

            }

            else if (!registrationFormData) 
            {
                try 
                {
                    const registrationFormUpdated = await createRegistrationForm(registrationFormDetails, csrfTokenData);
                    if (registrationFormUpdated) 
                    {
                        setRefresh(true);
                        handleDrawerClose();
                        setSpinner(false);
                        if (!(registrationFormUpdated instanceof Error)) 
                        {
                            navigate(`/registrations/${registrationFormUpdated.id}/form`);
                        }

                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setSpinner(false);
                    toast.error(_.startCase((error as Error).message.toLowerCase()))
                }

            }
        }
    });

    useEffect((): void => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, (): void => 
        {
            navigate(0);
        });

        if (registrationFormData) 
        {
            dispatch(addRegistrationForms({
                // link: registrationFormData.link,
                name: registrationFormData.name,
                description: registrationFormData.description,
            }));
        }
    }, []);

    const registrationFormTypes = [
        {
            id: RegistrationFormType.REGISTRATION_FORM, name: 'Event Registrations'
        },
        {
            id: RegistrationFormType.NPS, name: 'NPS Form'
        },
        // {
        //     id: 2, name: 'Speakers'
        // },
        // {
        //     id: 3, name: 'Sponsors'
        // },
        // {
        //     id: 4, name: 'Lead Capture'
        // },
    ];

    const registrationFormTypeOptionLabels = registrationFormTypes?.reduce((acc, curr) => {
        acc[curr.id] = curr.name;
        return acc;
    }, {});

    return (
        <Box id="createRegistrationForm">
            <CloseIconComponent onClick={handleDrawerClose} />
            {/* <Stack className="required-icon-stack" direction={'row'}><Typography className="required-icon">*</Typography><Typography className="required-field-text">{CONTENT.SIDE_DRAWER.FORM_REQUIRED_TEXT}</Typography></Stack> */}
            <Form noValidate autoComplete="off" onSubmit={(values: React.FormEvent<HTMLFormElement>): void => 
            {
                return formik.handleSubmit(values);
            }}>

                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.REGISTRATION_PAGE.FORMS.LABEL.NAME} required />
                    <FormControlComponent
                        type="text"
                        value={formik.values.name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                        {
                            formik.setFieldValue('name', event.target.value);
                            dispatch(addRegistrationForms({
                                name: event.target.value
                            }));
                        }}
                        required
                        placeholder="Enter Form Name"
                        isInvalid={formik.errors.name && formik.touched.name}
                    />
                    {formik.errors.name && formik.touched.name && <Typography className="error-msg">{formik.errors.name}</Typography>}
                </Box>

                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.REGISTRATION_PAGE.FORMS.LABEL.DESCRIPTION} />
                    <FormControlComponent
                        as="textarea"
                        rows={3}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                        {
                            formik.setFieldValue('description', event.target.value);
                            dispatch(addRegistrationForms({
                                description: event.target.value
                            }));
                        }}
                        value={formik.values.description}
                        type="text"
                        isInvalid={formik.errors.description && formik.touched.description}
                    />
                    {formik.errors.description && formik.touched.description ? <Typography className="error-msg">{formik.errors.description}</Typography> : null}
                    <Typography className="description-length">{descriptionLength}{'/250'}</Typography>
                </Box>

                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label='Type' required />
                    <SelectComponent value={formik.values.type} options={registrationFormTypes} optionLabels={registrationFormTypeOptionLabels} onChange={(event) => { formik.setFieldValue('type', event.target.value) }} defaultPlaceholder='Select a type' />
                </Box>

                <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton btnType='secondary' type='button' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton loading={spinner} type="submit" btnType='primary' name={registrationFormData ? CONTENT.REGISTRATION_PAGE.FORMS.BUTTONS.UPDATE : CONTENT.REGISTRATION_PAGE.FORMS.BUTTONS.CREATE} />
                    </Stack>
                </Box>
                
            </Form>
        </Box>
    );
};

export default RegistrationFormTemplate;