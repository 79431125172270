import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CONTENT } from '../../../scripts/i18n';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import CustomSpinner from '../../../common/CustomSpinner';
import { resetFormComponents } from '../../../redux/registrationsForms/FormComponents';
import { getRegistrationFormById } from '../../../scripts/apis/registrationForm';
import { updateEvent } from '../../../scripts/apis/events';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { AutocompleteComponent, CloseIconComponent, FormLabelComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { RegistrationForm } from '../../../pages/Registration/interfaces/registration-form_interface';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import FormPreview from '../../../pages/Registration/Preview/FormPreview';
import { CopyBlock } from "react-code-blocks";
import CustomTooltip from '../../../common/Tooltip';
import { EventAdditionalForms } from '../../../pages/Events/interfaces';

const SelectRegistrationForm: React.FC<{formName: string; regForms: RegistrationForm[]; eventId: string; registrationFormId?: string; codeSnippet: string; npsFormId?: string; additionalForms?: EventAdditionalForms;}> = ({formName, regForms, eventId, registrationFormId, codeSnippet, npsFormId, additionalForms}): React.JSX.Element => 
{
    const [formValue, setFormValue] = useState(formName);
    const [selectedForm, setSelectedForm] = useState('');
    const [registrationFormData, setRegistrationFormData] = useState<RegistrationForm | null>(null);
    const [previewComponent, setPreviewComponent] = useState<React.JSX.Element>(<></>);
    const [spinner, setSpinner] = useState(false);
    const dispatch = useDispatch();

    const formSubmit = async (event): Promise<void> => 
    {
        event.preventDefault();

        const regFormDetails = () =>
        {
            if(registrationFormId)
            {
                return {
                    registrationFormId: selectedForm,
                }
            }
            else
            {
                return {
                    additionalForms: {
                        npsFormId: selectedForm,
                        sponsorNominationFormId: additionalForms?.sponsorNominationFormId,
                        speakerNominationFormId: additionalForms?.speakerNominationFormId,
                    }
                }
            }
        };

        if (!selectedForm) 
        {
            toast.error('Please select a form');
            return;
        }

        if (selectedForm) 
        {
            try 
            {
                setSpinner(true);
                const formIdUpdated = await updateEvent(eventId, regFormDetails());
                if (formIdUpdated) 
                {
                    setSpinner(false);
                    handleDrawerClose();
                    toast.success('Event Form updated');
                    eventBus.dispatch('event-details-refreshed', {
                        refresh: true 
                    });
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }

    };

    const handleFormChange = (event, newValue): void => 
    {
        setFormValue(newValue);
        const selectedFormData = _.filter(regForms, function (form): boolean 
        {
            return newValue === form.name;
        })[0];
        if (selectedFormData) 
        {
            setSelectedForm(String(selectedFormData.id));
        }
        else 
        {
            setSelectedForm('');
        }
    };

    const fetchRegistrationFormData = async (): Promise<void> => 
    {
        try 
        {
            setPreviewComponent(<CustomSpinner height={'100%'} />);
            dispatch(resetFormComponents());
            const regFormData = await getRegistrationFormById(selectedForm);
            setRegistrationFormData(regFormData);
        }
        catch (error) 
        {
            setPreviewComponent(null);
            console.log(error);
        }
    };

    const handleIconClick = (selectedForm: any) => {
        handleDrawerClose();
        const url = `/registrations/${selectedForm}/form`;
        window.open(url, '_blank');
    };

    useEffect(():void => 
    {
        let selectedForm = registrationFormId ? registrationFormId : npsFormId;
        if(registrationFormId || npsFormId)
        {
            setSelectedForm(selectedForm as string);
        }
    },[]); 

    useEffect((): void => 
    {
        if (selectedForm !== null && selectedForm !== undefined && selectedForm !== '' && selectedForm !== 'null') 
        {
            fetchRegistrationFormData();
        }
        else 
        {
            setPreviewComponent(null);
        }
    }, [selectedForm]);

    useEffect((): void => 
    {
        if (registrationFormData) 
        {
            setPreviewComponent(
                <Box className="previeMode">
                    <FormPreview formId={selectedForm} registrationFormData={{ registrationForm: registrationFormData }} width={'100%'} maxWidth={'100%'} margin={'20px 20px 0px 20px'} />
                </Box>
            );
        }
    }, [registrationFormData]);

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    const codeBlock = (): React.JSX.Element => 
    {
        const codeSnippetScript = `<script src="${codeSnippet?.sdkUrl}"></script>\n<script>\n\tFormSDK.init({\n\t\ttoken: "${codeSnippet?.token}",\n\t\tonSuccess: (data) => console.log("Success:", data),\n\t\tonError: (error) => console.error("Error:", error),\n\t});\n</script>`;
        return <CopyBlock
        language="html"
        text={codeSnippetScript}
        codeBlock={true}
        showLineNumbers={false}
        customStyle={{fontSize: '14px', }}
      />;
    };

    const handleEmbedCodeClick = (): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
            heading: 'Form Embed Code Snippet',
            component: <div style={{padding: '20px'}}>{codeBlock()}</div>,
            componentHeight: '360px',
            componentWidth: '600px'
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
            open: true
        });
    };
    return (
        <Box id="selectRegForm">
            <CloseIconComponent onClick={handleDrawerClose} />
            <Box className="sidebar-container-spacing">
                <Box className="select-text">
                    <FormLabelComponent label="Select Form" required />
                </Box>
                <AutocompleteComponent value={formValue} defaultValue={formValue} onChange={handleFormChange} optionsArr={regForms} keyToShow="name" placeholder={CONTENT.EVENTS_MODULE.EVENT_REG_FORM.FORM_SELECT_PLACEHOLDER} />
            </Box>
            {selectedForm && previewComponent ?
                <Box className="sidebar-container-spacing">
                    <Box className='tooltip-box'>
                        <FormLabelComponent label='Form Preview' />
                        <CustomTooltip title='Edit the Registration Form'><FontAwesomeIcon className='edit-icon' cursor={'pointer'} icon={['fal', 'edit']} onClick={() => handleIconClick(selectedForm)} /></CustomTooltip>
                        <CustomTooltip title='Embed Code'><FontAwesomeIcon className='code-icon' cursor={'pointer'} icon={['fal', 'code']} onClick={() => handleEmbedCodeClick()} style={{marginLeft: 'auto'}} /></CustomTooltip>
                    </Box>
                    <Box className="show-form">
                        <Box className="reg-form-preview">{previewComponent}</Box>
                    </Box>
                </Box>
                : null}


            <Box className="submit-btn-container" width={'630px !important'}>
                <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                    <CustomButton btnType='secondary' type='button' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                    <CustomButton btnType='primary' type='submit' name='Save' disabled={spinner} loading={spinner} onClick={(event) => { formSubmit(event) }} />
                </Stack>
            </Box>
        </Box>
    );
};

export default SelectRegistrationForm;