export enum CustomFieldType
    {
    SINGLE_LINE = 1,
    MULTI_LINE = 2,
    CHECKBOXES = 3,
    DROPDOWN = 4,
    DATE = 5,
    NUMBER = 6,
    HEADER = 7,
    PARAGRAPH = 8,
    RADIO = 9,
    IMAGE = 10,
    GDPR_COMMUNICATION_PROCESSING = 11,
    GDPR_LEGITIMATE_INTEREST = 12,
    GDPR_FORM_SUBMIT_AS_CONSENT = 13,
    RATING = 14,
}

export enum CustomFieldLocations
    {
    EVENT = 1,
    SPONSOR = 2,
    SPEAKER = 3,
    VENDOR = 4,
    REGISTRATION_FORM = 5,
}

export enum CustomFieldOptionSubType
    {
    COUNTRY = 1,
    TIMEZONE = 2,
}

export enum CustomFieldMandatory
    {
    MANDATORY = 1,
    NOT_MANDATORY = 2,
}

export enum CustomFieldDisabled
    {
    NOT_DISABLED = 1,
    DISABLED = 2,
}

export enum CustomFieldStatus 
    {
    ACTIVE = 1,
    INACTIVE = 2,
    DELETED = 3
}

export enum CustomFieldHubspotPropertyOverride
    {
    YES = 1,
    NO = 2
}

export enum CustomFieldOrder
    {
    ASCENDING = 1,
    DESCENDING = 2
}

export enum CustomFieldRatingType
    {
    NONE = 1,
    STAR = 2,
    NUMBER = 3,
    EMOJI = 4
}

export enum RegistrationFormType
{
    REGISTRATION_FORM = 1,
    SPEAKERS = 2,
    SPONSORS = 3,
    LEAD_CAPTURE = 4,
    NPS = 5,
}