import React, { useContext, useState } from "react";
import { CouponsContext } from "../../../../contexts/EventCoupons/EventCouponsContext";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import eventBus from "../../../../scripts/event-bus";
import APP_CONSTANTS from "../../../../scripts/constants";
import AddCoupon from "../../../../components/Events/Tickets/AddCoupon";
import TableEmptyComponent from "../../../../common/TableEmptyComponent";
import { EventCoupon } from "../../interfaces/event-coupon_interface";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import { ActionsDropdown } from "../../EventBudget/EventBudgetDataGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CopyCouponComponent from "../../../../components/Events/Tickets/CopyCouponComponent";
import { Grid } from "@mui/material";
import CardComponent from "../../../../components/Events/Registrations/CardComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../../../../components/Events/interfaces/create-events_interface";
import { Currency, EventStatus } from "../../enum";
import TanstackTable from "../../../../common/TanstackTable/TanstackTable";
import { createColumnHelper } from "@tanstack/react-table";
import toast from "react-hot-toast";
import DeletePopup from "../../../../common/DeletePopup";
import EventsCardHeaderComponent from "../../../../components/Events/EventComponents/EventsCardHeaderComponent";
import TanstackToolbarWithServerFilter from "../../../../common/TanstackTable/TanstackToolbarWithServerFilter";
import { UserContext } from "../../../../contexts/UserContext";
import { UserViewType } from "../../../Settings/enum/users.enum";
import { MinimalViewComponent } from "../../../../common/MinimalView/MinimalViewComponent";
import ColumnVisibilityDropdown from "../../../../common/TanstackTable/ColumnVisibilityDropdown";
import getSymbolCurrency from "currency-symbol-map";
import CustomTooltip from "../../../../common/Tooltip";
import TicketCard from "../../../../components/Events/EventRegistrations/TicketCard";
import './styles.scss';
import moment from "moment";

const CouponsPage: React.FC = (): React.JSX.Element =>
{
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();

    const {
        userDetails
    } = useContext(UserContext);

    const { 
        eventId, 
        couponCount, 
        coupons,
        rows,
        isEmpty, 
        setRefresh, 
        showSpinner, 
        pageSize, 
        updateCurrentPage, 
        updatePageSize, 
        deleteCouponFn, 
        orgData, 
        eventLink, 
        isTableView,
        currentPage,
        selectedCoupon,
        setSelectedCoupon,
        setShowDeletePopup,
        showDeletePopup,
        setCurrentUrl 
    } = useContext(CouponsContext);

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const columnHelper = createColumnHelper<EventCoupon>();
    const couponColumns = 
    [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('couponsCount', {
            cell: (row) => {
                return (<p className="cellContent">{`${row.row.original?.couponsCount}/${row.row.original?.totalCouponsAvailable}`}</p>)
            },
            header: 'Coupon Uses',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'couponsCount'
        }),
        columnHelper.accessor('discount' as any, {
            cell: (row) => {
                const discount = row.row.original?.couponValue && row.row.original?.couponValue !== 0 ? `${getSymbolCurrency(row.row.original?.currency || Currency.INDIAN_RUPEE)}${row.row.original?.couponValue}` : `${row.row.original?.couponPercentage}%`; 
                return (<p className="cellContent">{discount}</p>)
            },
            header: 'Discount',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'discount'
        }),
        columnHelper.accessor('tickets', {
            cell: (row) => {
                const ticketNames = row.row.original?.tickets && row.row.original?.tickets?.length > 0 ? row.row.original?.tickets?.map(ticket => ticket?.name).join(', ') : '';
                return (
                    <CustomTooltip title={ticketNames}>
                        <p className="bolded-cellContent">{ticketNames}</p>
                    </CustomTooltip>
                )
            },
            header: 'Ticket Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'tickets'
        }),
        columnHelper.accessor('couponStatus' as any, {
            cell: (row) => {
                const currentTimestamp = Math.floor(new Date().getTime()/1000.0);
                let couponStatus, chipColor = '';
                if(Number(row.row.original?.couponCodeCloseDateTime) > currentTimestamp)
                {
                    couponStatus = 'Live';
                    chipColor = 'green';
                }
                else if(Number(row.row.original?.couponCodeCloseDateTime) < currentTimestamp)
                {
                    couponStatus = 'Expired';
                    chipColor = 'red';
                }
                else if(Number(row.row.original?.couponsCount) >= Number(row.row.original?.totalCouponsAvailable))
                {
                    couponStatus = 'Sold Out';
                    chipColor = 'yellow';
                }

                return (
                    <MuiChip circleIcon chipColor={chipColor as 'green' | 'red' | 'yellow'} label={couponStatus || ''} />
                )
            },
            header: 'Coupon Status',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'couponStatus'
        }),
        columnHelper.accessor('copyLink' as any, {
            cell: (row) => {
                return (
                    <>
                    {
                        eventReduxData?.status === EventStatus.PUBLISHED &&
                        <CustomTooltip title="Copy Coupon Code">
                            <FontAwesomeIcon onClick={() => {
                                navigator.clipboard.writeText(`${row.row.original?.name}`);  
                                toast.success('Coupon code copied');
                            }} cursor={'pointer'} style={{ height: '16px', width: '16px' }} icon={['fal', 'copy']} />
                        </CustomTooltip>
                    }
                    </>
                );
            },
            header: '',
            size: 40,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'copyLink'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return actions(row.row.original);
            },
            header: '',
            size: 40,
            enableSorting: false,
            enableResizing: false,
            id: 'actions'
        })
    ];
    
    const [allColumns, setAllColumns] = useState(couponColumns);

    const filterColumns = [
        {
            header: 'Name',
            id: 'name',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Coupon Start Date',
            id: 'couponCodeStartDateTime',
            meta: {
                type: 'date'
            }
        },
        {
            header: 'Coupon End Date',
            id: 'couponCodeCloseDateTime',
            meta: {
                type: 'date'
            }
        },
    ];

    const customFilterFunctions = {
        discount: (obj: EventCoupon, inputValue: string | number): boolean => {
            const discount = obj?.couponValue && obj?.couponValue !== 0 ? `₹${obj?.couponValue}` : `${obj?.couponPercentage}%`;
            return discount.toLowerCase().includes(inputValue.toString().toLowerCase());
        },
        tickets: (obj: EventCoupon, inputValue: string | number): boolean => {
            const ticketNames = obj?.tickets && obj?.tickets?.length > 0 ? obj?.tickets?.map(ticket => ticket?.name).join(', ') : '';
            return ticketNames.toLowerCase().includes(inputValue.toString().toLowerCase());
        },
        couponStatus: (obj: EventCoupon, inputValue: string | number): boolean => { 
            const currentTimestamp = Math.floor(new Date().getTime()/1000.0);
            let couponStatus, chipColor = '';
            if(Number(obj?.couponCodeCloseDateTime) > currentTimestamp)
            {
                couponStatus = 'Live';
                chipColor = 'green';
            }
            else if(Number(obj?.couponCodeCloseDateTime) < currentTimestamp)
            {
                couponStatus = 'Expired';
                chipColor = 'red';
            }
            else if(Number(obj?.couponsCount) >= Number(obj?.totalCouponsAvailable))
            {
                couponStatus = 'Sold Out';
                chipColor = 'yellow';
            }
            return couponStatus ? couponStatus?.toLowerCase().includes(inputValue.toString().toLowerCase()) : false;
        }
    };

    const actions = (row: EventCoupon): React.JSX.Element =>
    {

        const editClick = () =>
        {
            openDrawer(row);
        };

        const deleteClick = () =>
        {
            setSelectedCoupon(row);
            setShowDeletePopup(true);
        };

        const shareCouponUrl = () => 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                iconHeading: 'link',
                component: <CopyCouponComponent orgData={orgData} couponCode={row?.name || ''} eventLink={eventLink} />,
                componentWidth: '512px',
                componentHeight: '275px'
            });

            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                open: true
            });
        };

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} extraActions={eventReduxData?.status === EventStatus.PUBLISHED && [{
            name: 'Share Coupon URL',
            onClick: shareCouponUrl
        }]} />;
    };

    const rightNodes = [
        <CustomTooltip title="Table View" key={0}>
            <div>
                <CustomButton startIcon={<FontAwesomeIcon icon={['fal', 'square-list']} />} name='' btnType='tertiary_outline' onClick={() => navigate('/events/' + eventId + '/coupons')} />
            </div>
        </CustomTooltip>
    ];

    const openDrawer = (existingCouponData?: EventCoupon) =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: existingCouponData ? 'Update Coupon' : 'Create Coupon',
            hideCloseButton: true,
            component: <AddCoupon deleteCouponFn={deleteCouponFn} isTableView={isTableView} existingCouponData={existingCouponData} eventId={eventId || ''} setRefresh={setRefresh} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '500px !important'
        });
    };
    
    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const renderHeader = () =>
    {
        return (
            <div className="coupons-header">
                <div className='header-title'>
                    <div className='title-text'>
                        Coupons
                    </div>
                    <MuiChip label={couponCount || '0'} chipColor='grey' rounded />
                </div>
                {/* <CustomButton 
                    startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} 
                    name=''
                    btnType='tertiary'
                    onClick={() => navigate('/events/' + eventId + '/registrations')}
                />
                <Breadcrumbs 
                    origin={['Registrations']}
                    active='Coupons'
                /> */}
            </div>
        )
    }

    const renderNormalViewForCouponsPage = (): React.JSX.Element => {
        return (
            <div id="couponsPage" style={{ height: isTableView ? 'calc(100vh - 120px)' : '', overflow: isTableView ? 'hidden' : '' }}>
                {/* {renderHeader()} */}
                <div style={{ 
                    flex: '1',
                    height: `${APP_CONSTANTS.EVENTS_MODULE_STANDARD_COMPONENT_HEIGHT}`, 
                }}>
                    {isEmpty && coupons?.length === 0 ? (<TableEmptyComponent emptyImg={''} infoText={'No Existing Coupons'} buttonName={'Add Coupon'} />) : 
                    (
                        <div className="h-100">
                            <TanstackToolbarWithServerFilter 
                                columns={couponColumns}
                                setColumns={setAllColumns}
                                setCurrentUrl={setCurrentUrl}
                                tabs={[{
                                    tabName: 'All',
                                    count: couponCount as number,
                                    navigation: () => {},
                                    selectedTab: true
                                }]}
                                filterColumns={filterColumns}
                                handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                rightCornerButton={
                                    <CustomButton 
                                        startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                                        name='' 
                                        btnType='primary' 
                                        onClick={() => openDrawer()}
                                    />
                                }
                            />
                            <TanstackTable 
                                initialColumns={allColumns}
                                data={coupons}
                                rowCount={couponCount}
                                showNoRowsOverlay={couponCount == 0}
                                showSpinner={showSpinner}
                                pageSize={pageSize}
                                currentPage={currentPage - 1}
                                updateCurrentPage={updateCurrentPage as any}
                                updatePageSize={updatePageSize}
                                // height='calc(100% - 124px)'
                                rightPinnedColumns={['actions']}
                                onRowClick={(row) => openDrawer(row)}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    };

    const renderCardViewForCouponsPage = (): React.JSX.Element => { 

        const gridItemProps = userDetails?.viewType === UserViewType.NORMAL ? { xl: 4, lg: 4, md: 6, sm: 12, xs: 12 } : { xl: 4, lg: 4, md: 12, sm: 12, xs: 12 };

        return (
            <div id="couponsPage">
                <div className="coupons-container">

                    <EventsCardHeaderComponent heading="Coupons" count={couponCount} rightNodes={rightNodes} />

                    <Grid container spacing={2}>
                        {
                            coupons?.map((coupon: EventCoupon, index: number) => {
                                const currentTimestamp = Math.floor(new Date().getTime()/1000.0);
                                let couponStatus, statusColor = '';
                                if(Number(coupon?.couponCodeCloseDateTime) > currentTimestamp)
                                {
                                    couponStatus = 'Live';
                                    statusColor = 'live';
                                }
                                else if(Number(coupon?.couponCodeCloseDateTime) < currentTimestamp)
                                {
                                    couponStatus = 'Expired';
                                    statusColor = 'expired';
                                }
                                else if(Number(coupon?.couponsCount) >= Number(coupon?.totalCouponsAvailable))
                                {
                                    couponStatus = 'Sold Out';
                                    statusColor = 'sold-out';
                                }

                                const discount = coupon?.couponValue && coupon?.couponValue !== 0 ? `${getSymbolCurrency(coupon?.currency) || getSymbolCurrency(Currency.INDIAN_RUPEE)}${coupon?.couponValue}` : `${coupon?.couponPercentage}% OFF`;
                                const couponUses = `${coupon?.couponsCount}/${coupon?.totalCouponsAvailable}`;

                                const couponDetails = (
                                    <div className="coupon-details-container">
                                        <div className="coupon-code-tickets">
                                            {/* <button 
                                                onClick={(event) => {
                                                    event?.stopPropagation();
                                                    window.navigator.clipboard.writeText(coupon?.name);
                                                    toast.success('Coupon code copied'); 
                                                }} 
                                                className="coupon-code"
                                            >
                                                {coupon?.name}
                                            </button> */}
                                            {statusColor === 'sold-out' || statusColor === 'expired' ? 
                                            (
                                                <div className="coupon-sold-out">{coupon?.name}</div>
                                            ) : (
                                                <CustomButton
                                                    name={coupon?.name}
                                                    btnType="linkBtn"
                                                    onClick={(event) => {
                                                        event?.stopPropagation();
                                                        window.navigator.clipboard.writeText(coupon?.name);
                                                        toast.success('Coupon code copied');
                                                    }}
                                                />
                                            )}                              
                                        </div>
                                        <div className="coupon-count-status">
                                            {/* {coupon?.tickets && coupon?.tickets?.length > 1 && 
                                                <CustomTooltip title={coupon?.tickets?.map((ticket) => ticket.name).join(', ')}>
                                                    <h3 className="ticket-names">{coupon?.tickets?.map((ticket) => ticket.name).join(', ')}</h3>
                                                </CustomTooltip>
                                            } */}
                                            {coupon?.tickets && coupon?.tickets.length > 0 && (
                                                <CustomTooltip title={coupon.tickets.map((ticket) => ticket.name).join(', ')}>
                                                    <div className="ticket-names">
                                                    <MuiChip label={coupon.tickets[0].name} chipColor="grey" rounded />
                                                        {coupon.tickets.length > 1 && (
                                                            <MuiChip label={`+${coupon.tickets.length - 1}`} chipColor="grey" rounded />
                                                        )}
                                                    </div>
                                                </CustomTooltip>
                                            )}
                                            {<MuiChip 
                                                label={
                                                    moment().isAfter(moment.unix(Number(coupon.couponCodeCloseDateTime))) 
                                                        ? 'Expired' 
                                                        : `Ends ${moment.unix(Number(coupon.couponCodeCloseDateTime)).format('MMM D, YYYY, h:mm A')}`
                                                } 
                                                chipColor='grey' 
                                                rounded 
                                            />}
                                            {/* {couponStatus && <MuiChip label={couponStatus} chipColor="grey" rounded />} */}
                                        </div>
                                    </div>
                                );

                                const couponDiscount = (
                                    <div className="coupon-discount">
                                        <h3 className="discount-value">{discount}</h3>
                                        <p className="registered-count">{`${couponUses} Registered`}</p>
                                    </div>
                                )

                                return (
                                    <Grid key={index} item {...gridItemProps}>
                                        <div onClick={() => openDrawer(coupon)} className="coupon-card">
                                            <TicketCard
                                                leftSection={couponDetails}
                                                rightSection={couponDiscount}
                                            />
                                        </div>
                                    </Grid>
                                )
                            })
                        }
                        <Grid item {...gridItemProps}>
                            <CardComponent 
                                emptyText='+ Add Coupon'
                                cardMinHeight="106px"
                                emptyCardHeight="74px"
                                onClick={() => { openDrawer() }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    };

    const renderMinimalViewForCouponsPage = (): React.JSX.Element => { 
        return (
            <div id="couponsPageMinimalView">
                <MinimalViewComponent
                    title={renderHeader()}
                    titleBarActions={[ <CustomButton 
                        startIcon={<FontAwesomeIcon icon={['fal', 'grid']} />} 
                        name=''
                        btnType='tertiary_outline'
                        onClick={() => navigate('/events/' + eventId + '/registrations')}
                    />]}
                    titleBarStyle={{ padding: '0', borderBottom: '1px solid #F2F4F7' }}
                    tabData={[
                        {
                            tabName: 'All',
                            count: couponCount as number,
                            navigation: () => { },
                            selectedTab: true
                        }
                    ]}
                    actions={[
                        !isEmpty && <TanstackToolbarWithServerFilter
                            key={1}
                            columns={couponColumns}
                            filterColumns={filterColumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}
                        />,
                        !isEmpty && <ColumnVisibilityDropdown 
                            key={2}
                            columns={couponColumns}
                            setColumns={setAllColumns}
                            showIconButton={true}
                        />,
                        <CustomButton
                            key={2}
                            name=''
                            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType='primary'
                            onClick={() => openDrawer()}
                        />
                    ]}
                    component={
                        <div className="minimalView-table-container">
                            {
                                isEmpty ?
                                    <TableEmptyComponent
                                        emptyImg={''}
                                        openDrawer={openDrawer}
                                        infoText={'No Coupons'}
                                        subInfoText={'Create your first Coupon'}
                                    />
                                    :
                                    <TanstackTable
                                        data={coupons}
                                        initialColumns={allColumns}
                                        rowCount={couponCount as number}
                                        showNoRowsOverlay={couponCount == 0}
                                        pageSize={pageSize}
                                        currentPage={currentPage - 1}
                                        updatePageSize={updatePageSize}
                                        updateCurrentPage={updateCurrentPage as any}
                                        // height='calc(100vh - 300px)'
                                        showSpinner={showSpinner}
                                        rightPinnedColumns={['actions']}
                                        onRowClick={(row) => openDrawer(row)}
                                        showBottomPadding={false}
                                    />
                            }
                        </div>
                    }
                    componentType='table'
                />
            </div>
        )
    };

    return (
        <>
            {
                isTableView && userDetails?.viewType === UserViewType.NORMAL && renderNormalViewForCouponsPage()
            }

            {
                isTableView && userDetails?.viewType === UserViewType.MINIMAL && renderMinimalViewForCouponsPage()
            }

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteCouponFn(selectedCoupon as EventCoupon)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedCoupon(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedCoupon?.name}?`}
                        modalTitle='Delete Coupon'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='trash'
                    />
            }

            {!isTableView && renderCardViewForCouponsPage()}
        </>
    )
};

export default CouponsPage;