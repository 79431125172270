import { useFormik } from "formik";
import useEventOverviewData from "../../../hooks/eventOverviewData";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { updateEvent } from "../../../scripts/apis/events";
import { CalendarInvitation } from "../../../pages/Events/enum";
import toast from "react-hot-toast";
import { FormControlLabel, Stack } from "@mui/material";
import { CustomSwitch } from "../../../common/StyledComponents/Switch.styled";
import LexicalEditor from "../../../lexical";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseIconComponent, FormLabelComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { CONTENT } from "../../../scripts/i18n";
import { Event } from "../../../pages/Events/interfaces";

interface CalendarDescriptionEditProps {
    setRefreshEventData: React.Dispatch<React.SetStateAction<boolean>>;
    eventDetails: Event;
}

const CalendarDescriptionEditor: React.FC<CalendarDescriptionEditProps> = ({ eventDetails, setRefreshEventData }): React.JSX.Element =>
{   
    const { eventId } = useParams<{ eventId: string }>();

    const handleDrawerClose = ():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    const [expanded, setExpanded] = useState<boolean>(eventDetails?.calendarInvitation === CalendarInvitation.ENABLED);
    const [spinner, setSpinner] = useState(false)

    const handleSubmit = async (): Promise<void> =>
    {
        try 
        {
            const data = {
                calendarInvitation: formik.values.enableCalendarInvitation ? CalendarInvitation.ENABLED : CalendarInvitation.DISABLED,
                calendarDescription: formik.values.calendarDescription,
            }
            const eventDetailsUpdated = await updateEvent(eventId as string, data);
            if(eventDetailsUpdated)
            {
                toast.success('Calendar Invitation details updated successfully');
                setRefreshEventData(true);
            }
        } 
        catch (error) 
        {
            console.log('Error in updating event', error);
        }
        finally
        {
            setSpinner(false)
            handleDrawerClose();
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            enableCalendarInvitation: eventDetails?.calendarInvitation === CalendarInvitation.ENABLED ? true : false,
            calendarDescription: eventDetails?.calendarDescription,
        },
        onSubmit: async (): Promise<void> => 
        {
            setSpinner(true);
            handleSubmit();
        }
    });

    return (
        <div id="calendarDescriptionEdit">
            <CloseIconComponent onClick={handleDrawerClose} />
            <div className="form-container">
                <div className="approval-toggle-wrapper">
                    <div className="icon-label-cont">
                        <FontAwesomeIcon icon={["fal", "calendar"]} className="approval-icon" />
                        <FormLabelComponent label={'Calendar Invitation'} noBottomMargin />
                    </div>
                    <FormControlLabel
                        checked={formik.values.enableCalendarInvitation}
                        control={<CustomSwitch onChange={(event) => { 
                            formik.setFieldValue('enableCalendarInvitation', event.target.checked);
                            setExpanded(event.target.checked); 
                        }} />}
                        label={""}
                    />
                </div>
                <div className={`editor-container ${expanded ? 'expanded' : ''}`}>
                    {expanded && (
                        <LexicalEditor 
                            toolBarOptions={['FontColor', 'BlockFormat', 'Bold', 'Italic', 'InsertImage', 'InsertEmbed', 'InsertPageBreak', 'ElementFormat']} 
                            minHeight={'250px'} 
                            placeHolder='Calendar Description'
                            maxHeight='250px' 
                            slashCommands={['Heading 1', 'Heading 2', 'Heading 3', 'Paragraph', 'Divider', 'Bulleted List', 'Embed Youtube Video']} 
                            value={formik.values.calendarDescription || ''} 
                            handleChange={(html: string, json: any) => { 
                                formik.setFieldValue('calendarDescription', html);
                            }} 
                        />
                    )}
                </div>
            </div>
            <div className="submit-btn-container">
                <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                    <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                    <CustomButton btnType='primary' loading={spinner} disabled={spinner || !formik.dirty} name={'Save'} onClick={() => formik.handleSubmit()} />
                </Stack>
            </div>
        </div>
    )
}

export default CalendarDescriptionEditor