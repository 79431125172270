/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Box, FormControlLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import { CONTENT } from '../../../scripts/i18n';
import { LocalStorage } from '../../../scripts/LocalStorage';
// import { KeyboardArrowDown } from '@mui/icons-material';
import { updateRegistrationForm } from '../../../scripts/apis/registrationForm';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import CustomSpinner from '../../../common/CustomSpinner';
import { RegistrationFormComponentType } from './RegistrationFormContent';
import { Spinner } from 'react-bootstrap';
import { RegistrationFormCaptcha } from '../enum';
import { RegistrationForm } from '../interfaces/registration-form_interface';
import { CustomFieldType, RegistrationFormType } from '../enum/custom-field.enum';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

interface IFormComponentTypeNameProps {
    icon: [any, any];
    name: string;
    onClick: () => void;
}

interface IFormComponentSideDrawerProps {
    RegistrationFormData: RegistrationForm;
    setRefresh: (refresh: boolean) => void;
    formId: string | number;
    leadCaptureFormId?: string | number;
}

const FormComponentSideDrawer = ({ RegistrationFormData, setRefresh, formId }: IFormComponentSideDrawerProps): React.JSX.Element => 
{
    const currentpath = useLocation().pathname;

    const [gdprOptionVal, setGdprOptionVal] = useState(null);
    const [spinner, setSpinner] = useState(false);
    const [componentClicked, setComponentClicked] = useState(null);

    const isLeadCaptureForm = currentpath?.includes('portal');

    const orgName = LocalStorage.get('@Org')?.name;

    const gdprOptions = [
        {
            id: 0,
            heading: 'None',
            subHeading: '',
        },
        {
            id: 1,
            heading: CONTENT.REGISTRATION_PAGE.COMPONENTS.GDPR_OPTION_HEADING[1],
            subHeading: CONTENT.REGISTRATION_PAGE.COMPONENTS.GDPR_OPTIONS_SUBHEADING[1] + ' ' + orgName,
        },
        {
            id: 2,
            heading: CONTENT.REGISTRATION_PAGE.COMPONENTS.GDPR_OPTION_HEADING[2],
            subHeading: CONTENT.REGISTRATION_PAGE.COMPONENTS.GDPR_OPTIONS_SUBHEADING[2],
        },
        {
            id: 3,
            heading: CONTENT.REGISTRATION_PAGE.COMPONENTS.GDPR_OPTION_HEADING[3],
            subHeading: CONTENT.REGISTRATION_PAGE.COMPONENTS.GDPR_OPTIONS_SUBHEADING[3],
        },
    ];

    const handleGdprOptionClick = (optionSelected): void => 
    {
        if (optionSelected !== null) 
        {
            if (optionSelected === 1) 
            {
                setComponentClicked(RegistrationFormComponentType.GDPR_COMMUNICATION_PROCESSING);
            }
            else if (optionSelected === 2) 
            {
                setComponentClicked(RegistrationFormComponentType.GDPR_LEGITIMATE_INTEREST);
            }
            else if (optionSelected === 3) 
            {
                setComponentClicked(RegistrationFormComponentType.GDPR_FORM_SUBMIT_AS_CONSENT);
            }
        }


    };

    const gdprDropdownChange = (event): void => 
    {
        setGdprOptionVal(event.target.value);
        handleGdprOptionClick(event.target.value);
    };


    const GDPRComponent = (): React.ReactElement => 
    {
        return (
            <Select
                className="gdpr-option-select"
                displayEmpty
                value={gdprOptionVal}
                IconComponent={(props): React.JSX.Element => 
                {
                    return (
                        <FontAwesomeIcon {...props} icon={['fal', 'chevron-down']} className={`material-icons ${props.className}`} />
                    ); 
                }}
                onChange={(event): void => 
                {
                    gdprDropdownChange(event);
                }}
                renderValue={(selected): React.ReactElement => 
                {
                    if (selected) 
                    {
                        return (<Box display={'flex'} justifyContent={'start'}>
                            <Typography className="type-placeholder-selected">{CONTENT.REGISTRATION_PAGE.COMPONENTS.GDPR_OPTION_HEADING[Number(selected)]}</Typography>
                        </Box>);
                    }
                    return <Typography className="type-placeholder">{'Select a GDPR option'}</Typography>;
                }}
            >
                {gdprOptions && gdprOptions.map((option, index): React.ReactElement => 
                {
                    return (
                        <MenuItem key={index} value={option.id}
                            onKeyDown={(event): void => 
                            {
                                event.stopPropagation();
                            }}
                        >
                            <Stack direction={'column'} spacing={1}>
                                <Typography sx={{
                                    fontSize: '12px', fontWeight: 500, color: '#101828'
                                }}>{option.heading}</Typography>
                                <Typography sx={{
                                    fontSize: '10px', fontWeight: 400, color: '#475467'
                                }}>{option.subHeading}</Typography>
                            </Stack>
                        </MenuItem>
                    );
                })}
            </Select>
        );
    };

    const handleCaptcha = async (event): Promise<void> => 
    {
        const captcha = event.target.checked ? 2 : 1;

        try 
        {
            setSpinner(true);
            const captchaValueUpdated = await updateRegistrationForm(Number(formId), {
                captcha
            });
            if (captchaValueUpdated) 
            {
                toast.success(event.target.checked ? 'Captcha enabled' : 'Captcha disabled');
                setRefresh(true);
                setSpinner(false);
            }
        }
        catch (error) 
        {
            console.log(error);
            setSpinner(false);
        }
    };

    useEffect((): void => 
    {
        eventBus.dispatch('event-open-form-Component-drawer', {
            componentType: componentClicked
        });

    }, [componentClicked]);

    useEffect((): void => 
    {

        if (RegistrationFormData && RegistrationFormData?.formGDPR) 
        {
            // const gdprComponent = RegistrationFormData?.components.filter((item): any => 
            // {
            //     if (item.type === RegistrationFormComponentType.GDPR_COMMUNICATION_PROCESSING || item.type === RegistrationFormComponentType.GDPR_LEGITIMATE_INTEREST || item.type === RegistrationFormComponentType.GDPR_FORM_SUBMIT_AS_CONSENT) 
            //     {
            //         return item;
            //     }
            // });
            const gdprComponent = RegistrationFormData?.formGDPR;
            if (gdprComponent && gdprComponent?.gdpr?.length > 0) 
            {
                if (Number(gdprComponent?.type) === CustomFieldType.GDPR_COMMUNICATION_PROCESSING) 
                {
                    setGdprOptionVal(gdprOptions[1].id);
                }
                else if (Number(gdprComponent?.type) === CustomFieldType.GDPR_LEGITIMATE_INTEREST) 
                {
                    setGdprOptionVal(gdprOptions[2].id);
                }
                else if (Number(gdprComponent?.type) === CustomFieldType.GDPR_FORM_SUBMIT_AS_CONSENT) 
                {
                    setGdprOptionVal(gdprOptions[3].id);
                }
            }
        }
        else 
        {
            setGdprOptionVal(gdprOptions[0].id);
        }

    }, [RegistrationFormData]);


    return (
        <Box id="formComponentSideDrawer">
            <Box className='component-type-section'>
                <Typography className='section-title'>{'Text Input'}</Typography>
                <Box className='component-types'>
                    <FormComponentTypeNameBlock icon={['fal', 'minus']} name={'Single-line Text Box'} onClick={(): void => 
                    {
                        setComponentClicked(RegistrationFormComponentType.SINGLE_LINE);
                    }} />
                    <FormComponentTypeNameBlock icon={['fal', 'bars']} name={'Multi-line Text Box'} onClick={(): void => 
                    {
                        setComponentClicked(RegistrationFormComponentType.MULTI_LINE);
                    }} />
                </Box>

            </Box>
            <Box className='component-type-section'>
                <Typography className='section-title'>{'Date and Number'}</Typography>
                <Box className='component-types'>
                    <FormComponentTypeNameBlock icon={['fal', 'calendar']} name={'Date Picker'} onClick={(): void => 
                    {
                        setComponentClicked(RegistrationFormComponentType.DATE);
                    }} />
                    <FormComponentTypeNameBlock icon={['fal', 'input-numeric']} name={'Number Field'} onClick={(): void => 
                    {
                        setComponentClicked(RegistrationFormComponentType.NUMBER);
                    }} />
                </Box>

            </Box>
            <Box className='component-type-section'>
                <Typography className='section-title'>{'Selection Input'}</Typography>
                <Box className='component-types'>
                    <FormComponentTypeNameBlock icon={['fal', 'circle-chevron-down']} name={'Dropdown Menu'} onClick={(): void => 
                    {
                        setComponentClicked(RegistrationFormComponentType.DROPDOWN);
                    }} />
                    <FormComponentTypeNameBlock icon={['fal', 'circle-check']} name={'Checkbox'} onClick={(): void => 
                    {
                        setComponentClicked(RegistrationFormComponentType.CHECKBOXES);
                    }} />
                    <FormComponentTypeNameBlock icon={['fal', 'circle-dot']} name={'Radio Button'} onClick={(): void => 
                    {
                        setComponentClicked(RegistrationFormComponentType.RADIO);
                    }} />
                    <FormComponentTypeNameBlock icon={['fal', 'face-smile']} name={'Rating'} onClick={(): void => 
                    {
                        setComponentClicked(RegistrationFormComponentType.RATING);
                    }} />
                </Box>

            </Box>
            <Box className='component-type-section'>
                <Typography className='section-title'>{'Structural Elements'}</Typography>
                <Box className='component-types'>
                    <FormComponentTypeNameBlock icon={['fal', 'heading']} name={'Section Header'} onClick={(): void => 
                    {
                        setComponentClicked(RegistrationFormComponentType.HEADER);
                    }} />
                    <FormComponentTypeNameBlock icon={['fal', 'paragraph']} name={'Paragraph Text'} onClick={(): void => 
                    {
                        setComponentClicked(RegistrationFormComponentType.PARAGRAPH);
                    }} />
                </Box>

            </Box>

            {RegistrationFormData?.type === RegistrationFormType.REGISTRATION_FORM &&
                <>
                    <Box className='component-type-section'>
                        <Typography className='section-title'>{'Security'}</Typography>
                        <Box className='component-types'>
                            <Box className="form-component-type-captcha-block" onClick={(): void => 
                            { }}>
                                <Box className="type-icon">
                                    <FontAwesomeIcon icon={['fal', 'shield']} className="navigation-icon" />
                                </Box>
                                <Box className="type-name"> {'Captcha'} </Box>
                                <Box className='captcha-switch-block'>
                                {spinner ? <Spinner size='sm' animation="border" role="status" />: 
                                <FormControlLabel
                                checked={RegistrationFormData?.captcha === RegistrationFormCaptcha.TRUE}
                                control={
                                    <CustomSwitch
                                        sx={{
                                            m: 1
                                        }}
                                    name="required"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                                    {
                                        handleCaptcha(event);
                                    }}
                                    />
                                }
                                label={null}
                                className='captcha-switch'
                            />}

                                </Box>
                            
                            
                            </Box>
                        </Box>
                    </Box>

                    <Box className='component-type-section'>
                        <Typography className='section-title'>{'Compliance'}</Typography>
                        <Box className='gdpr-section'>
                            <GDPRComponent />
                        </Box>

                    </Box>
                </> 
            }
        </Box>

    );

};

export default FormComponentSideDrawer;

const FormComponentTypeNameBlock = ({ icon, name, onClick }: IFormComponentTypeNameProps): React.JSX.Element => 
{
    return (
        <Box className="form-component-type-name-block" onClick={onClick}>
            <Box className="type-icon">
                <FontAwesomeIcon icon={icon} className="navigation-icon" />
            </Box>
            <Box className="type-name"> {name} </Box>
        </Box>
    );
};