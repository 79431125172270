import React, { useContext } from "react";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import './styles.scss';
import { EventStageContext } from "../../../../contexts/EventStage/EventStageContext";
import _ from "lodash";
import eventBus from "../../../../scripts/event-bus";
import APP_CONSTANTS from "../../../../scripts/constants";
import AddStage from "../../../../components/Events/Agenda/AddStage";
import StageAccordian from "./StageAccordian";
import StageDateTabs from "./StageDateTabs";
import { UserContext } from "../../../../contexts/UserContext";
import { UserViewType } from "../../../Settings/enum/users.enum";
import { MinimalViewComponent } from "../../../../common/MinimalView/MinimalViewComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EventsHeaderBar from "../../../../common/EventsHeaderBar";

const StagePage = () => 
{ 
    const { daysArr, selectedDate, setSelectedDate, stageData, eventId, setRefresh } = useContext(EventStageContext);

    const {
        userDetails
    } = useContext(UserContext);

    const openStageSideDrawer = (): void =>
    {   
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Add Stage',
            component: <AddStage eventId={eventId} setRefresh={setRefresh} selectedDate={selectedDate} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    const renderNormalViewForAgenda = (): React.JSX.Element => { 
        return (
            <div id="stagePage">
                <EventsHeaderBar 
                    title={'Agenda'}
                    // buttons={[
                    //     <CustomButton key={1} btnType='secondary' name='Add Stage' type='button' onClick={openStageSideDrawer}/>
                    // ]}
                />

                <div className="tab-wrapper">
                    <div className="stage-date-tabs-container">
                        <StageDateTabs dates={daysArr} selectedDate={selectedDate} handleDateChange={setSelectedDate} />
                    </div>
                    <CustomButton key={2} name='' startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />} btnType='primary' onClick={() => openStageSideDrawer()} />
                </div>

                <StageAccordian setRefresh={setRefresh} eventId={eventId} stages={stageData} selectedDate={selectedDate} />

            </div>
        );
    };

    const renderStagesAsAccordian = (): React.JSX.Element => { 
        return (
            <div id="stageAccordianMinimal">
                <div className="tabs-actions-container">
                    <StageDateTabs dates={daysArr} selectedDate={selectedDate} handleDateChange={setSelectedDate} />
                    <CustomButton key={2} name='' startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />} btnType='primary' onClick={() => openStageSideDrawer()} />
                </div>
                <StageAccordian minimalView={true} setRefresh={setRefresh} eventId={eventId} stages={stageData} selectedDate={selectedDate} />
            </div>
        )
    };

    const renderMinimalViewForAgenda = (): React.JSX.Element => { 
        return (
            <div id="stagePageMinimalView">
                <MinimalViewComponent 
                    title="Agenda "
                    description="Manage your Stage and Sessions here."
                    component={renderStagesAsAccordian()}
                    componentType='list'
                />
            </div>
        );
    };
    
    return(
        <>
            {
                userDetails?.viewType === UserViewType.NORMAL ? renderNormalViewForAgenda() : renderMinimalViewForAgenda()
            }
        </>
    )
};  

export default StagePage;