import { RegistrationFormType } from '../../pages/Registration/enum/custom-field.enum';
import { RegistrationFormComponent } from '../../pages/Registration/interfaces';
import { RegistrationForm } from '../../pages/Registration/interfaces/registration-form_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const registrationFormsCount = async (queryParams: [string, string][] = [], typeArr?: string): Promise<number> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams({
        typeArr,
        ...filteredParams
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/registration-form/count' + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getRegistrationForms = async (pageSize: number, offset: number, type?: RegistrationFormType, typeArr?: string, queryParams: [string, any][] = []): Promise<RegistrationForm[]> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        type,
        typeArr,
        ...filteredParams
    });
    
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/registration-form?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getRegistrationFormById = async (formId: string | number): Promise<RegistrationForm> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/registration-form/' + formId;
    return triggerApi(API_URL, 'GET', '');
};

export const createRegistrationForm = async (REGISTRATION_FORM, csrfToken: string): Promise<RegistrationForm> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/registration-form';
    return triggerApi(API_URL, 'POST', REGISTRATION_FORM, csrfToken);
};

export const updateRegistrationForm = async (FORM_ID, REGISTRATION_FORM): Promise<RegistrationForm> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/registration-form/' + FORM_ID;
    return triggerApi(API_URL, 'PUT', REGISTRATION_FORM);
};

export const deleteRegistrationForm = async (FORM_ID): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/registration-form/' + FORM_ID;
    return triggerApi(API_URL, 'DELETE', '');
};

export const createRegistrationFormComponent = async (FORM_ID: number, FORM_COMPONENT, csrfToken: string): Promise<RegistrationFormComponent> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/registration-form/' + FORM_ID + '/components';
    return triggerApi(API_URL, 'POST', FORM_COMPONENT, csrfToken);
};

export const updateRegistrationFormComponent = async (FORM_ID: number, COMPONENT_ID: number, FORM_COMPONENT): Promise<RegistrationFormComponent> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/registration-form/' + FORM_ID + '/components/' + COMPONENT_ID;
    return triggerApi(API_URL, 'PUT', FORM_COMPONENT);
};

export const deleteRegistrationFormComponent = async (FORM_ID: number, COMPONENT_ID: number): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/registration-form/' + FORM_ID + '/components/' + COMPONENT_ID;
    return triggerApi(API_URL, 'DELETE', '');
};

export const checkValidFormToDelete = async (formId: number): Promise<string> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/validate-form/' + formId;
    return triggerApi(API_URL, 'GET', '');
};

export const createRegistrationFormFields = async (formId: number, customFieldData: any, csrfToken: string): Promise<RegistrationForm> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/registration-form/' + formId + '/fields';
    return triggerApi(API_URL, 'POST', customFieldData, csrfToken);
};

export const updateRegistrationFormFields = async (formId: number, fieldId: string | number, formField: any): Promise<RegistrationFormComponent> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/registration-form/' + formId + '/' + fieldId + '/fields';
    return triggerApi(API_URL, 'PUT', formField);
};