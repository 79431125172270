import { useEffect, useState } from "react";
import ActivityLogComponent from "../../../../common/ActivityLog/ActivityLogComponent";
import { ActivityLogResponse } from "../../../../interfaces/activity-log_interface";
import { EventRegistrant } from "../../../../pages/Events/interfaces/event-registrant_interface";
import { getEventRegistrantActivityLogs } from "../../../../scripts/apis/eventRegistrants";
import { eventRegistrantActivityLog } from "../../../../scripts/activityLogGenerator/activitylog-generator";

interface RegistrantActivityLogProps {
    registrantData: EventRegistrant;
}

const RegistrantActivityLog: React.FC<RegistrantActivityLogProps> = (props): React.JSX.Element =>
{
    const { registrantData } = props;

    const [activityLogs, setActivityLogs] = useState<ActivityLogResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchActivityLogs = async (): Promise<void> => 
    {
        try 
        {
            const activityLogs = await getEventRegistrantActivityLogs(registrantData?.eventId, registrantData?.id);
            if(activityLogs)
            {
                setActivityLogs(activityLogs);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchActivityLogs();
    }, [registrantData])

    return (
        <div className="pt-2">
            {
                loading ? 
                <></> :
                <>
                    {activityLogs && activityLogs?.length > 0 ?
                        <ActivityLogComponent
                            activityLog={eventRegistrantActivityLog(activityLogs)}
                        />
                        :
                        <div className="registrant-log-empty">
                            No activity log for this registrant
                        </div>
                    }
                </>
            }
        </div>
    );
};

export default RegistrantActivityLog;