import { useContext } from 'react';
import './styles.scss';
import { UserContext } from '../contexts/UserContext';
import { UserViewType } from '../pages/Settings/enum/users.enum';

const EventsHeaderBar: React.FC<{ title: string | React.JSX.Element, buttons?: React.JSX.Element[]; showBorder?: boolean; }> = (props): React.JSX.Element =>
{
    const { title, buttons, showBorder = false } = props;

    const {
        userDetails,
    } = useContext(UserContext);
    

    return (
        <div id={ userDetails?.viewType === UserViewType.NORMAL ? "eventsHeaderBar" : "eventsHeaderBarMinimal" }>
            <div className={`events-header-bar ${showBorder ? 'bordered' : ''}`}>
                <h3 className="title">
                    {title}
                </h3>
                <div className="buttons-container">
                    {
                        buttons && buttons.map((button, index) => (
                            <div key={index}>
                                {button}
                            </div>
                        )) 
                    }
                </div>
            </div>
        </div>
    )
}; 

export default EventsHeaderBar;