import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import eventBus from '../../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../../scripts/constants';
import CreateTickets from '../../../../../components/Events/Tickets/CreateTicket';
import { getEventTicketById } from '../../../../../scripts/apis/eventTickets';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { resetTicket } from '../../../../../redux/events/eventRegistrations/Tickets';
import moment from 'moment';
import { CONTENT } from '../../../../../scripts/i18n';
import TableEmptyComponent from '../../../../../common/TableEmptyComponent';
import { TicketsPageContext } from '../../../../../contexts/EventDetailsRegistration/TicketsPageContext';
import { getEventById } from '../../../../../scripts/apis/events';
import { addEvent } from '../../../../../redux/events/Events';
import { ActionsDropdown } from '../../../EventBudget/EventBudgetDataGrid';
import { EventTicket } from '../../../interfaces';
import { IEventReduxValues, IEventsDispatch } from '../../../../../components/Events/interfaces/create-events_interface';
import { NumberFormatter } from '../../../../../scripts/helpers';
import MuiChip from '../../../../../common/FormComponents/MuiChip';
import ticketsEmpty from '../../../../../assets/icons/empty-state/ticketsEmpty.svg';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardComponent from '../../../../../components/Events/Registrations/CardComponent';
import { Currency, EnableApprovalStatus, PrivateTicket, TicketType } from '../../../enum';
import { createColumnHelper } from '@tanstack/react-table';
import TanstackTable from '../../../../../common/TanstackTable/TanstackTable';
import DeletePopup from '../../../../../common/DeletePopup';
import InvoiceSettings from '../../../../../components/Events/Tickets/InvoiceSettings';
import toast from 'react-hot-toast';
import EventsCardHeaderComponent from '../../../../../components/Events/EventComponents/EventsCardHeaderComponent';
import TanstackToolbarWithServerFilter from '../../../../../common/TanstackTable/TanstackToolbarWithServerFilter';
import { UserContext } from '../../../../../contexts/UserContext';
import { UserViewType } from '../../../../Settings/enum/users.enum';
import CustomSpinner from '../../../../../common/CustomSpinner';
import { MinimalViewComponent } from '../../../../../common/MinimalView/MinimalViewComponent';
import ColumnVisibilityDropdown from '../../../../../common/TanstackTable/ColumnVisibilityDropdown';
import getSymbolCurrency from "currency-symbol-map";
import TicketCard from '../../../../../components/Events/EventRegistrations/TicketCard';
import Breadcrumbs from '../../../../../common/Breadcrumbs';

import './styles.scss';
import CustomTooltip from '../../../../../common/Tooltip';

export interface TicketComponentProps {
    eventId: number;
    tab?: string;
    subTab?: string
}

export interface TicketComponentProps {
    eventId: number;
    tab?: string;
    subTab?: string
}

export enum RegistrationTypes {
    OPEN = 1,
    INVITE_ONLY = 2,
}

const Ticket: React.FC<TicketComponentProps> = (): React.JSX.Element => 
{

    const { ticketId } = useParams();

    const {
        userDetails
    } = useContext(UserContext);

    const { 
        isEmpty, 
        ticketRows, 
        setRefresh, 
        removeTicketFromTable, 
        eventId, 
        pageSize,
        currentPage, 
        updateCurrentPage, 
        updatePageSize, 
        showSpinner, 
        eventTicketsCount, 
        presentTicketsName, 
        tickets, 
        isTableView,
        selectedTicket,
        setSelectedTicket, 
        showDeletePopup, 
        setShowDeletePopup,
        setCurrentUrl
    } = useContext(TicketsPageContext);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const isInvoiceDetailsPresent = (eventReduxData?.invoiceDetails && eventReduxData?.invoiceDetails?.invoiceNumber !== null && eventReduxData?.invoiceDetails?.invoicePrefix !== null);

    const handleEditTicket = (data: EventTicket, routeFromId?: boolean): void => 
    {
        dispatch(resetTicket());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.EVENTS_MODULE.TICKET.SIDE_DRAWER.TICKET.HEADING.EDIT,
            hideCloseButton: true,
            component: <CreateTickets isTableView={isTableView} eventId={eventId} ticketData={data} setRefresh={setRefresh} eventReduxData={eventReduxData} routeFromId={routeFromId} presentTicketsName={presentTicketsName} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '500px !important',
        });
    };

    const ticketType = (row: EventTicket): React.ReactElement =>
    {
        const ticketTypeChipLabel = row.privateTicket === 1 ? 'Private' : 'Public';
        const ticketTypeChipColor = row.privateTicket === 1 ? 'violet' : 'green';

        return (
            <Box className="ticket-type-container">
                <MuiChip circleIcon label={ticketTypeChipLabel} chipColor={ticketTypeChipColor} />
            </Box>
        );
    };

    const ticketPricing = (row: EventTicket): React.ReactElement =>
    {
        const ticketPricingTypeChipLabel = row.type === 1 ? 'Free' : 'Paid';
        const ticketPricingTypeChipColor = row.type === 1 ? 'pink' : 'orange';

        return (
            <Box className="ticket-pricing-container">
                <MuiChip circleIcon label={ticketPricingTypeChipLabel} chipColor={ticketPricingTypeChipColor} />
            </Box>
        );
    };

    const saleStartDateTime = (row: EventTicket): React.ReactElement => 
    {
        let saleStartDateTime = String(row.saleStartDateTime);
        saleStartDateTime = moment.unix(Number(saleStartDateTime)).format('MMM DD YYYY');
        return (
            <p className="cellContent">{saleStartDateTime}</p>
        );
    };

    const saleEndDateTime = (row: EventTicket): React.ReactElement => 
    {
        let saleCloseDateTime = String(row.saleCloseDateTime);
        saleCloseDateTime = moment.unix(Number(saleCloseDateTime)).format('MMM DD YYYY');
        return (
            <Typography className="cellContent">{saleCloseDateTime}</Typography>
        );
    };

    const ticketActions = (row: EventTicket): React.ReactElement => 
    {
        const editClick = (): void => 
        {
            handleEditTicket(row);
        };

        const deleteClick = (): void => 
        {
            setSelectedTicket(row);
            setShowDeletePopup(true);
        };

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    const renderInvoiceSettingsPopup = (): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
            heading: 'Invoice Settings',
            component: <InvoiceSettings eventId={eventId} isInvoiceDetailsPresent={isInvoiceDetailsPresent} />,
            componentWidth: '512px',
            componentHeight: isInvoiceDetailsPresent ? '164px' : '216px'
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
            open: true
        });
    }

    const openDrawer = (): void => 
    {
        dispatch(resetTicket());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.EVENTS_MODULE.TICKET.SIDE_DRAWER.TICKET.HEADING.CREATE,
            hideCloseButton: true,
            component: <CreateTickets isTableView={isTableView} eventId={eventId} setRefresh={setRefresh} eventReduxData={eventReduxData} presentTicketsName={presentTicketsName} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '500px !important'
        });
    };

    const fetchDataFromRoute = async (): Promise<void> => 
    {
        if (ticketId) 
        {
            try 
            {
                const ticketDataById = await getEventTicketById(eventId, Number(ticketId));
                if (ticketDataById) 
                {
                    try 
                    {
                        const eventData = await getEventById(eventId);
                        if (eventData) 
                        {
                            dispatch(addEvent({
                                eventStartDateTime: eventData.eventStartDateTime,
                                eventEndDateTime: eventData.eventEndDateTime,
                                closingDateTime: eventData.closingDateTime,
                            }));
                            handleEditTicket(ticketDataById, true);
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                        toast.error('Error fetching Event Data');
                    }
                }
                else 
                {
                    toast.error('The Ticket you\'re trying to access doesn\'t exist');
                    navigate('/events/' + eventId + '/registrations/tickets');
                }
            }
            catch (error) 
            {
                console.log(error);
                toast.error((error as Error).message);
            }
        }
    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const columnHelper = createColumnHelper<EventTicket>();
    const ticketColumns = 
    [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Ticket Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('privateTicket', {
            cell: (row) => {
                return ticketType(row.row.original);
            },
            header: 'Ticket Type',
            size: 150,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'privateTicket'
        }),
        ...(userDetails?.viewType === UserViewType.NORMAL ? [columnHelper.accessor('type', {
            cell: (row) => {
                return ticketPricing(row.row.original);
            },
            header: userDetails?.viewType === UserViewType.NORMAL ? 'Ticket Pricing' : 'Ticket Value',
            size: 150,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'type'
        })] : []),
        columnHelper.accessor('totalTicketsAvailable', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Allocated Tickets',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'totalTicketsAvailable'
        }),
        columnHelper.accessor('ticketPrice', {
            cell: (row) => {
                return (
                    <p className="cellContent">
                        {
                            userDetails?.viewType === UserViewType.NORMAL ? (row.row.original?.ticketPrice && row.row.original?.ticketPrice !== 0 && row.row.original?.ticketPrice !== '0') ? `${getSymbolCurrency(row.row.original?.currency) || getSymbolCurrency(Currency.INDIAN_RUPEE)}${NumberFormatter(Number(row.getValue()))}` : '-' :
                            row.row.original.type === TicketType.PAID ? (row.row.original?.ticketPrice && row.row.original?.ticketPrice !== 0 && row.row.original?.ticketPrice !== '0') ? `${getSymbolCurrency(row.row.original?.currency) || getSymbolCurrency(Currency.INDIAN_RUPEE)}${NumberFormatter(Number(row.getValue()))}` : '-' : 'Free'
                        }
                    </p>)
            },
            header: 'Ticket Price',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'ticketPrice'
        }),
        columnHelper.accessor('saleStartDateTime', {
            cell: (row) => {
                return saleStartDateTime(row.row.original);
            },
            header: 'Sale Start',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'saleStartDateTime'
        }),
        columnHelper.accessor('saleCloseDateTime', {
            cell: (row) => {
                return saleEndDateTime(row.row.original);
            },
            header: 'Sale End',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'saleCloseDateTime'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return ticketActions(row.row.original);
            },
            header: '',
            size: 40,
            maxSize: 40,
            enableSorting: false,
            enableResizing: false,
            id: 'actions'
        })
    ];

    const [allColumns, setAllColumns] = useState(ticketColumns);

    const filterColumns = [
        {
            header: 'Name',
            id: 'name',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Allocated Tickets',
            id: 'ticketsAvailable',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Ticket Price',
            id: 'ticketPrice',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Ticket Pricing',
            id: 'type',
            meta: {
                type: 'dropdown',
                filterOptions: [
                    {
                        name: 'Paid', value: TicketType.PAID,
                    },
                    {
                        name: 'Free', value: TicketType.FREE,
                    }
                ]
            }
        },
        {
            header: 'Ticket Type',
            id: 'privateTicket',
            meta: {
                type: 'dropdown',
                filterOptions: [
                    {
                        name: 'Private', value: PrivateTicket.PRIVATE,
                    },
                    {
                        name: 'Public', value: PrivateTicket.PUBLIC,
                    }
                ]
            }
        },
        {
            header: 'Sale Start',
            id: 'ticketStartDateTime',
            meta: {
                type: 'date'
            }
        },
        {
            header: 'Sale End',
            id: 'ticketEndDateTime',
            meta: {
                type: 'date'
            }
        }
    ];

    const customFilterFunctions = {
        privateTicket: (obj: EventTicket, inputValue: string | number): boolean => {
            const ticketType = obj.privateTicket === PrivateTicket.PRIVATE ? 'Private' : 'Public';
            return ticketType.toLowerCase().includes(String(inputValue).toLowerCase());
        },
        type: (obj: EventTicket, inputValue: string | number): boolean => {
            const ticketType = obj.type === TicketType.PAID ? 'Paid' : 'Free';
            return ticketType.toLowerCase().includes(String(inputValue).toLowerCase());
        },
        totalTicketsAvailable: (obj: EventTicket, inputValue: string | number): boolean => {
            return String(obj.totalTicketsAvailable).toLowerCase().includes(String(inputValue).toLowerCase());
        },
        saleStartDateTime: (obj: EventTicket, inputValue: string | number): boolean => { 
            const saleStartDateTime = moment.unix(Number(obj.saleStartDateTime)).format('MMM DD YYYY');
            return saleStartDateTime.toLowerCase().includes(String(inputValue).toLowerCase());
        },
        saleCloseDateTime: (obj: EventTicket, inputValue: string | number): boolean => {
            const saleCloseDateTime = moment.unix(Number(obj.saleCloseDateTime)).format('MMM DD YYYY');
            return saleCloseDateTime.toLowerCase().includes(String(inputValue).toLowerCase());
        },
    };

    const renderHeader = () =>
    {
        return (
            <div className="tickets-header">
                <div className='header-title'>
                    <div className='title-text'>
                        Tickets
                    </div>
                    <MuiChip label={eventTicketsCount || '0'} chipColor='grey' rounded />
                </div>
                {/* <CustomButton 
                    startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} 
                    name=''
                    btnType='tertiary'
                    onClick={() => navigate('/events/' + eventId + '/registrations')}
                />
                <Breadcrumbs 
                    origin={['Registrations']}
                    active='Tickets'
                /> */}
            </div>
        )
    };

    const renderNormalViewForEventTickets = (): React.JSX.Element => {
        return (
            <div id="tickets" style={{ height: isTableView ? 'calc(100vh - 120px)' : '', overflow: isTableView ? 'hidden' : '' }}>
                {/* {renderHeader()} */}
                <Box sx={{
                    flex: '1',
                    height: `${APP_CONSTANTS.EVENTS_MODULE_STANDARD_COMPONENT_HEIGHT}`, 
                }}>
                    {isEmpty ? (<TableEmptyComponent emptyImg={ticketsEmpty} openDrawer={openDrawer} infoText={'No Tickets yet'} subInfoText={'Create your first Ticket'} buttonName={'Create Ticket'} />) :
                    (
                        <div className="h-100">
                            <TanstackToolbarWithServerFilter 
                                columns={ticketColumns}
                                setColumns={setAllColumns}
                                tabs={[
                                    {
                                        tabName: 'All',
                                        count: eventTicketsCount as number,
                                        navigation: () => {},
                                        selectedTab: true
                                    }
                                ]}
                                setCurrentUrl={setCurrentUrl}
                                handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                filterColumns={filterColumns}
                                rightActionButtons={
                                    [
                                        <CustomButton startIcon={<FontAwesomeIcon icon={['fal', 'file-invoice-dollar']} />} name='' btnType='secondary' onClick={() => renderInvoiceSettingsPopup()} />,
                                    ]
                                }
                                rightCornerButton={
                                    <CustomButton startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />} name='' btnType='primary' onClick={openDrawer} />
                                }
                            />
                            <TanstackTable 
                                data={ticketRows}
                                initialColumns={allColumns}
                                rowCount={eventTicketsCount as number}
                                showNoRowsOverlay={eventTicketsCount == 0}
                                pageSize={pageSize}
                                currentPage={currentPage - 1}
                                updatePageSize={updatePageSize}
                                updateCurrentPage={updateCurrentPage as any}
                                // height='calc(100% - 128px)'
                                showSpinner={showSpinner}
                                rightPinnedColumns={['actions']}
                                onRowClick={(row) => handleEditTicket(row)}
                            />
                        </div>
                    )}
                </Box>
            </div>
        )
    };

    const renderCardViewForEventTickets = (): React.JSX.Element => { 

        const rightNodes = [
            <CustomTooltip title='Invoice Settings'>
                <div>
                    <CustomButton startIcon={<FontAwesomeIcon icon={['fal', 'file-invoice-dollar']} />} name='' btnType='tertiary_outline' onClick={() => renderInvoiceSettingsPopup()} />
                </div>
            </CustomTooltip>,
            <CustomTooltip title='Table View'>
                <div>
                    <CustomButton startIcon={<FontAwesomeIcon icon={['fal', 'square-list']} />} name='' btnType='tertiary_outline' onClick={() => navigate('/events/' + eventId + '/tickets') } />
                </div>
            </CustomTooltip>,
        ];

        const gridItemProps = userDetails?.viewType === UserViewType.NORMAL ? { xl: 4, lg: 4, md: 6, sm: 12, xs: 12 } : { xl: 4, lg: 4, md: 8, sm: 12, xs: 12 };

        return (
        <div id="tickets">
            <div className="tickets-container">

                <EventsCardHeaderComponent heading='Tickets' count={eventTicketsCount || undefined} rightNodes={rightNodes} />

                <Grid container spacing={2}>
                    {
                        tickets?.map((ticket: EventTicket, index: number) => {

                            const registeredLabel = ticket?.totalTicketsAvailable && ticket?.totalTicketsAvailable !== 0 ? `${ticket?.ticketsCount}/${(ticket?.totalTicketsAvailable)} Registered` : `${ticket?.ticketsCount} Registered`;

                            const ticketNameAndStatus = (
                                <div className="ticket-name-status-container">
                                    <h3 className="ticket-name">{ticket?.name}</h3>
                                    <div className="ticket-status">
                                        {ticket?.enableApproval === EnableApprovalStatus.ENABLE && <MuiChip label='Require Approval' chipColor='grey' rounded />}
                                        {<MuiChip 
                                            label={
                                                moment().isAfter(moment.unix(Number(ticket.saleCloseDateTime))) 
                                                    ? 'Expired' 
                                                    : `Ends ${moment.unix(Number(ticket.saleCloseDateTime)).format('MMM D, YYYY, h:mm A')}`
                                            } 
                                            chipColor='grey' 
                                            rounded 
                                        />}
                                    </div>
                                </div>
                            );

                            const ticketPriceAndType = (
                                <div className="ticket-price-type-container">
                                    {(ticket?.ticketPrice && String(ticket?.ticketPrice) !== '0') ? <h3 className="ticket-price">{`${getSymbolCurrency(ticket?.currency) || getSymbolCurrency(Currency.INDIAN_RUPEE)}${ticket?.ticketPrice}`}</h3> : <h3 className="ticket-price">Free</h3>}
                                    <p className="ticket-count">{registeredLabel}</p>
                                    {ticket?.ticketSoldOut && <p className="sold-out">Sold Out</p>}
                                </div>
                            )

                            return (
                                <Grid key={index} item {...gridItemProps}>
                                    <div className="ticket-card" onClick={() => handleEditTicket(ticket)}>
                                        <TicketCard
                                            leftSection={ticketNameAndStatus}
                                            rightSection={ticketPriceAndType}
                                        />
                                    </div>
                                </Grid>
                            )
                        })
                    }
                    <Grid item {...gridItemProps}>
                        <CardComponent 
                            emptyText='+ Add Ticket'
                            cardMinHeight="106px"
                            emptyCardHeight="74px"
                            onClick={openDrawer}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
        )
    };

    const renderMinimalViewForEventTickets = (): React.JSX.Element => { 
        return (
            <div id="ticketsMinimalView">
                <MinimalViewComponent
                    titleBarStyle={{ padding: '0', borderBottom: '1px solid #F2F4F7' }}
                    title={renderHeader()}
                    titleBarActions={[ <CustomButton 
                        startIcon={<FontAwesomeIcon icon={['fal', 'grid']} />} 
                        name=''
                        btnType='tertiary_outline'
                        onClick={() => navigate('/events/' + eventId + '/registrations')}
                    />]}
                    tabData={[
                        {
                            tabName: 'All',
                            count: eventTicketsCount as number,
                            navigation: () => { },
                            selectedTab: true
                        }
                    ]}
                    actions={[
                        !isEmpty && <TanstackToolbarWithServerFilter
                            key={1}
                            columns={ticketColumns}
                            filterColumns={filterColumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}
                        />,
                        !isEmpty && <ColumnVisibilityDropdown 
                            key={2}
                            columns={ticketColumns}
                            setColumns={setAllColumns}
                            showIconButton={true}
                        />,
                        <CustomButton
                            key={2}
                            name=''
                            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType='primary'
                            onClick={() => openDrawer()}
                        />
                    ]}
                    component={
                        <div className="minimalView-table-container">
                            {
                                isEmpty ?
                                    <TableEmptyComponent
                                        emptyImg={ticketsEmpty}
                                        openDrawer={openDrawer}
                                        infoText={'No Tickets'}
                                        subInfoText={'Create your first Ticket'}
                                    />
                                    :
                                    <TanstackTable
                                        data={ticketRows}
                                        initialColumns={allColumns}
                                        rowCount={eventTicketsCount as number}
                                        showNoRowsOverlay={eventTicketsCount == 0}
                                        pageSize={pageSize}
                                        currentPage={currentPage - 1}
                                        updatePageSize={updatePageSize}
                                        updateCurrentPage={updateCurrentPage as any}
                                        // height='calc(100vh - 300px)'
                                        showSpinner={showSpinner}
                                        rightPinnedColumns={['actions']}
                                        onRowClick={(row) => handleEditTicket(row)}
                                        showBottomPadding={false}
                                    />
                            }
                        </div>
                    }
                    componentType='table'
                />
            </div>
        )
    };

    useEffect((): void => 
    {
        if(presentTicketsName)
        {
            setAllColumns(ticketColumns);
        }
    }, [presentTicketsName]);

    useEffect((): void => 
    {
        fetchDataFromRoute();
    }, []);

    return (
        <>

            {isTableView && userDetails?.viewType === UserViewType.NORMAL && renderNormalViewForEventTickets()}

            {!isTableView && renderCardViewForEventTickets()}

            {
                showSpinner ? <CustomSpinner width="100%" height="100%" /> : userDetails?.viewType === UserViewType.MINIMAL && isTableView && renderMinimalViewForEventTickets()
            }  
            
            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => removeTicketFromTable(selectedTicket as EventTicket)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedTicket(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedTicket?.name}?`}
                        modalTitle='Delete Ticket'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='trash'
                    />
            }
        </>
    );
};

export default Ticket;